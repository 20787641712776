import React, {useEffect} from 'react';
import {render} from 'react-dom';
import {ConfigProvider} from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import './index.less';
import {HashRouter, Switch, Route} from "react-router-dom";

import Main from "./Main";
import Login from "./pages/userlogin/index";
import VendorLogin from "./pages/vendorlogin/index";

moment.locale('zh-cn');

const App = () => {

    return (
        <ConfigProvider locale={zhCN}>
            <HashRouter>
                <Switch>
                    <Route exact path="/UserLogin" component={Login}/>
                    <Route exact path="/VendorLogin" component={VendorLogin}/>
                    <Route component={Main}/>
                </Switch>
            </HashRouter>
        </ConfigProvider>
    );
};

render(<App/>, document.getElementById('root'));