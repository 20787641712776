import PaginationBar from "../../components/paginationbar";
import React, {useEffect, useState} from 'react'
import {Breadcrumb, Checkbox , Button, Col,Select , Form, Input, Modal, Pagination, Row, Space,Upload} from 'antd'
import common from "../../common"
import Table from "antd/es/table"
import axios from 'axios';
import FromPlate from "../../components/button/FormPlate";
import Exportfile from "../../components/button/Exportfile";
import Importfile from "../../components/button/Importfile";
import Display from "../../components/button/Display";
import {DeleteFilled, ToTopOutlined , LineOutlined  , EditFilled, PlusOutlined, ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import { UploadOutlined } from '@ant-design/icons';

var name="公告管理"
function Notice(props) {
    const { TextArea } = Input;
    let initSearch = {}
    let [dataList, setDataList] = useState([])
    let [noticeType, setNoticeType] = useState([])
    let [factory, setFactory] = useState([])
    let [search, setSearch] = useState(initSearch)       // 搜索内容
    let [query, setQuery] = useState(initSearch);
    const [searchForm] = Form.useForm();
    let [pagination, setPagination] = useState({
        total: 0,
        page: 1,
        limit: 10,
    })
    const {Option} = Select;
    let [createModalVisible, setCreateModalVisible] = useState(false)
    let [editModalVisible, setEditModalVisible] = useState(false)
    let [createForm] = Form.useForm();
    let [editForm] = Form.useForm();
    let [users, setUsers] = useState([]);
    const columns = [
        {
            title: '类型',
            dataIndex: 'noticeTypeName',
            width: 200
        },
        {
            title: '标题',
            dataIndex: 'title',

        },
        {
            title: '关键词',
            dataIndex: 'keyss',

        },
        {
            title: '查看次数',
            dataIndex: 'views',
        },
        {
            title: '红头',
            dataIndex: 'isRed',
            render: (text, record, index) => {
                if(record.isRed == 1){
                    return (
                        <>
                           <Button className="zhiding" onClick={() => {
                               update({isRed:2,id:record.id})
                            }}><ToTopOutlined  />设置</Button> 
                        </>
                    )
                }else{
                    return (
                        <>
                           <Button className="quxiao" onClick={() => {
                               update({isRed:1,id:record.id})
                            }}><LineOutlined  />取消</Button> 
                        </>
                    )
                }
            }

        },
        {
            title: '置顶',
            dataIndex: 'isTop',
            render: (text, record, index) => {
                if(record.isTop == 1){
                    return (
                        <>
                           <Button className="zhiding" onClick={() => {
                                update({isTop:2,id:record.id})
                            }}><ToTopOutlined  />设置</Button> 
                        </>
                    )
                }else{
                    return (
                        <>
                           <Button className="quxiao" onClick={() => {
                                update({isTop:1,id:record.id})
                            }}><LineOutlined  />取消</Button> 
                        </>
                    )
                }
            }
        },
        {
            title: '操作',
            align: "center",
            render: (text, record, index) => {
                return (
                    <>
                        <Button className="bianji" onClick={() => {
                            setEditModalVisible(true)
                            editForm.setFieldsValue({...record})

                            record.files.map((item,index) => {
                                record.files[index].url = (item.savepath + item.savename)
                            })
                            setFileList(record.files)
                        }}><EditFilled  />编辑</Button>
                        <Button  className="shanchu" onClick={() => {
                            common.confirm("确定要删除吗?", () => {
                                del({id:record.id,status:2})
                            })
                        }}><DeleteFilled  />删除</Button>
                    </>
                )
            }
        }
    ];
    
    const getDataList = () => {
        let params = {
            ...search,
            page: pagination.page,
            limit: pagination.limit,
        }
        common.ajax("GET", "/passport/wjNotice/list", params).then(data => {
            console.log(data.list);
            setDataList(data.list)
            setPagination(data.pagination)
        })
    }
    //获取公告类型
    const getNoticType = () => {
        common.ajax("GET", "/passport/wjNoticeType/list").then(data => {
            setNoticeType(data.list)
            console.log(data.list);
        })
    }
    const create = (data) => {

        let ids = [];
        fileList.map((item,key) => {
            if(item.id){
                ids.push(item.id)
            }
        })

        let params = {
            ...data,
            fileIds : ids.join(',')
        }

        if(window.localStorage.getItem("user")){
            params.username = JSON.parse(window.localStorage.getItem("user")).userName
        }

        common.loadingStart()
        common.ajax("POST", "/passport/wjNotice/create", params).then(res => {
            common.toast("添加成功")
            setCreateModalVisible(false)
            getDataList()
        }).finally(common.loadingStop)
    }
    //修改 置顶 红头文件
    const update = (data) => {
        let params = {
            ...data
        }
        common.ajax("POST", "/passport/wjNotice/update", params).then(res => {
            common.toast("修改成功")
            // setEditModalVisible(false)
            getDataList()
        }).finally(common.loadingStop)
    }

    const updateData = (data) => {
        
        let ids = [];
        fileList.map((item,key) => {
            if(item.id){
                ids.push(item.id)
            }
        })

        let params = {
            ...data,
            fileIds : ids.join(',')
        }

        if(window.localStorage.getItem("user")){
            params.username = JSON.parse(window.localStorage.getItem("user")).userName
        }

        common.ajax("POST", "/passport/wjNotice/update", params).then(res => {
            common.toast("修改成功")
            setEditModalVisible(false)
            getDataList()
        }).finally(common.loadingStop)
    }


    const del = (data) => {
        common.loadingStart()
        common.ajax("POST", "/passport/wjNotice/update", data).then(res => {
            common.toast("删除成功")
            getDataList()
        }).finally(common.loadingStop)
    }

    let [fileList, setFileList] = useState([]);

    const handleChange = (info) => {
        let fileList = [...info.fileList];
        console.log(info.fileList);
        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        // fileList = fileList.slice(-2);
    
        // 2. Read from response and show file link
        fileList = fileList.map(file => {
          if (file.response) {
            let data = file.response.data
            file.url = data.savepath + data.savename;
            file.id = data.id;
          }
          return file;
        });
    
        setFileList(fileList);

    };

    useEffect(() => {
        getDataList()
        getNoticType()
    }, [pagination.page, pagination.limit,search])
    return (
        <div className="Workshop">
            <Breadcrumb>
                <Breadcrumb.Item>公告管理</Breadcrumb.Item>
                <Breadcrumb.Item>公告列表</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{minHeight: 600}}>
                
                <Form  form={searchForm}  style={{marginTop: 15}} className="searchForm">
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="标题" name="title">
                                <Input placeholder="标题"  onChange={(e) => {
                                    setQuery({...query,title: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="关键字" name="keyss">
                                <Input placeholder="关键字"  onChange={(e) => {
                                    setQuery({...query,keyss: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="内容" name="des">
                                <Input placeholder="内容"  onChange={(e) => {
                                    setQuery({...query,des: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item>
                                <Space>
                                    <Button onClick={() => {
                                        setSearch(query)
                                    }} className='search-btn mybtn'><SearchOutlined />搜索</Button>
                                    <Button onClick={() => {
                                        let emptySearch = {
                                            title:'',
                                            keyss: '',
                                            des: '',
                                        }
                                        searchForm.setFieldsValue(emptySearch)
                                        emptySearch = {}
                                        setSearch(emptySearch)
                                        setQuery(emptySearch)
                                    }} className='clean-btn mybtn'><ReloadOutlined />清空</Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div className='table-box'>
                    <Button type="primary" onClick={() => {
                        setCreateModalVisible(true)
                        createForm.setFieldsValue({title: ""})
                    }} className='add-btn mybtn'><PlusOutlined />新增</Button>
                    {/* <FromPlate columns={columns} name={name}/> */}
                    {/* <Importfile method="POST" api="/front/workshop/create" columns={columns} name={name} /> */}
                    {/* <Exportfile method="GET" api="/front/workshop/list" query={search} dataList={dataList} columns={columns} name={name}/> */}
                    <Table rowKey={columns => columns.id} dataSource={dataList} columns={Display(columns)} pagination={false}/>
                </div>
                {pagination.total < 5 ? '' :
                    <PaginationBar>
                        <Pagination
                            style={{marginTop: 25}}
                            pageSizeOptions={['10', '20', '50', '100']}
                            onChange={(page, limit) => {
                                setPagination({...pagination, page, limit})
                            }}
                            onShowSizeChange={(page, limit) => {
                                setPagination({...pagination, page, limit})
                            }}
                            showTotal={total => `共${total}条`}
                            total={pagination.total}
                            defaultCurrent={pagination.page}
                            current={pagination.page}
                            defaultPageSize={pagination.limit}
                            showSizeChanger
                            showQuickJumper
                        /></PaginationBar>}
            </div>
            <Modal title="新增" destroyOnClose={true} footer={false} maskClosable={false} visible={createModalVisible}
                   onCancel={() => {
                        setFileList([])
                        setCreateModalVisible(false)
                   }}
            >
                <Form preserve={false}
                      labelCol={{span: 4}}
                      wrapperCol={{span: 20}}
                      form={createForm}
                      onFinish={(values) => {
                          create(values)
                      }}>
                    <Form.Item
                        label="标题"
                        name="title"
                        rules={[{required: true, message: '请输入车间名字'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="关键词"
                        name="keyss"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="公告类型"
                        name="noticeType"
                        rules={[{required: true, message: '请公告类型'}]}
                    >
                        <Select  placeholder="公告类型" >
                            {noticeType.map(item => {
                                return (<Option key={item.id} value={item.id} > {item.title}</Option>)
                            })}
                        </Select>
                    </Form.Item>
                    
                    <Form.Item
                        label="红头"
                        name="isRed"
                    >
                        <Checkbox onChange={(e) => {
                            if(e.target.checked){
                                createForm.setFieldsValue({isRed:2})
                            }else{
                                createForm.setFieldsValue({isRed:1})
                            }
                        }}></Checkbox>
                    </Form.Item>

                    <Form.Item
                        label="置顶"
                        name="isTop"
                    >
                        <Checkbox onChange={(e) => {
                            if(e.target.checked){
                                createForm.setFieldsValue({isTop:2})
                            }else{
                                createForm.setFieldsValue({isTop:1})
                            }
                        }}></Checkbox>
                    </Form.Item>

                    <Form.Item
                        label="正文"
                        name="des"
                    >
                        <TextArea showCount maxLength={1000} rows={10}/>
                    </Form.Item>
                    
                    {/* <Editor
                        value={editForm.getFieldsValue().des}
                        id={"tincyEditor"}
                        apiKey="35xwyca1ufgwgqvqqatebxz9lsj8abx1ftzvadhwrafxx50h"
                        init={{
                            language: 'zh_CN',
                            plugins: 'preview searchreplace autolink directionality visualblocks visualchars fullscreen image link template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave autoresize formatpainter',
                            toolbar: 'code undo redo restoredraft | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link anchor | alignleft aligncenter alignright alignjustify outdent indent | styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | table image media charmap emoticons hr pagebreak insertdatetime print preview | fullscreen | bdmap indent2em lineheight formatpainter axupimgs',
                            fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
                            images_upload_handler: (blobInfo, success, failure)=>{}}}
                    /> */}

                    
                    <Form.Item wrapperCol={{offset: 4, span: 20}}>
                        <Upload 
                            name='file'
                            data={file => ({
                                file:file,
                                id: window.localStorage.getItem("user") ? JSON.parse(window.localStorage.getItem("user")).id : ''
                            })}
                            fileList={fileList} 
                            // beforeUpload={ () => false }
                            action={common.getBaseApiUrl() + "/passport/uploads/file?token=" + common.getToken() + "&type=" + common.getType()}
                            onChange={(file)=>{handleChange(file)}} 
                            // beforeUpload = {}
                            multiple>
                            <Button icon={<UploadOutlined />}>上传文件</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item wrapperCol={{offset: 4, span: 20}}>
                        <Button type="primary" htmlType="submit" >
                            保存
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal title="编辑" footer={false} maskClosable={false} visible={editModalVisible} onCancel={() => {
                setEditModalVisible(false)
            }}
            >
                <Form
                    labelCol={{span: 4}}
                    wrapperCol={{span: 20}}
                    form={editForm}
                    onFinish={(values) => {
                        updateData(values)
                    }}
                >
                    <Form.Item
                        hidden={true}
                        name="id"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="标题"
                        name="title"
                        rules={[{required: true, message: '请输入车间名字'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="关键词"
                        name="keyss"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="公告类型"
                        name="noticeType"
                        rules={[{required: true, message: '请公告类型'}]}
                    >
                        <Select  placeholder="公告类型" >
                            {noticeType.map(item => {
                                return (<Option key={item.id} value={item.id} > {item.title}</Option>)
                            })}
                        </Select>
                    </Form.Item>
                    
                    {/* <Form.Item
                        label="负责人"
                        name="userId"
                        rules={[{required: true, message: '请输入负责人姓名'}]}
                    >
                        <Select  placeholder="姓名" >
                            {users.map(item => {
                                return (<Option key={item.id} value={item.id} > {item.name}</Option>)
                            })}
                        </Select>
                    </Form.Item> */}
                    <Form.Item
                        label="正文"
                        name="des"
                    >
                        <TextArea showCount maxLength={1000} rows={10}/>
                    </Form.Item>
                    
                    {/* <Editor
                        value={editForm.getFieldsValue().des}
                        id={"tincyEditor"}
                        apiKey="35xwyca1ufgwgqvqqatebxz9lsj8abx1ftzvadhwrafxx50h"
                        init={{
                            language: 'zh_CN',
                            plugins: 'preview searchreplace autolink directionality visualblocks visualchars fullscreen image link template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave autoresize formatpainter',
                            toolbar: 'code undo redo restoredraft | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link anchor | alignleft aligncenter alignright alignjustify outdent indent | styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | table image media charmap emoticons hr pagebreak insertdatetime print preview | fullscreen | bdmap indent2em lineheight formatpainter axupimgs',
                            fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
                            images_upload_handler: (blobInfo, success, failure)=>{}}}
                    /> */}

                    
                    <Form.Item wrapperCol={{offset: 4, span: 20}}>
                        <Upload 
                            name='file'
                            data={file => ({
                                file:file,
                                id: window.localStorage.getItem("user") ? JSON.parse(window.localStorage.getItem("user")).id : ''
                            })}
                            fileList={fileList} 
                            // beforeUpload={ () => false }
                            action={common.getBaseApiUrl() + "/passport/uploads/file?token=" + common.getToken() + "&type=" + common.getType()}
                            onChange={(file)=>{handleChange(file)}} 
                            // beforeUpload = {}
                            multiple>
                            <Button icon={<UploadOutlined />}>上传文件</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item wrapperCol={{offset: 4, span: 20}}>
                        <Button type="primary" htmlType="submit" >
                            保存
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}
export default Notice
