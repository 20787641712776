import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import PaginationBar from "../../components/paginationbar";
import {Button,DatePicker, Form, Input, Modal, Table, Breadcrumb, Pagination,Row, Col,Switch, Space, Select} from "antd";
import common from "../../common";
import "./index.less"
import {
    VerticalAlignBottomOutlined, EyeOutlined, FilePdfOutlined, RocketOutlined , SearchOutlined, ReloadOutlined, EditOutlined, ExportOutlined ,DownloadOutlined 
} from '@ant-design/icons';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ExportJsonExcel from 'js-export-excel';
function PoPomain(props) {
    let [cvencode, setCvencode] = useState('')
    let initSearch = {}
    let [dataList, setDataList] = useState([])
    let [search, setSearch] = useState(initSearch)       // 搜索内容
    const [searchForm] = Form.useForm();
    let [query, setQuery] = useState(initSearch);
    let [pagination, setPagination] = useState({
        total: 0,
        page: 1,
        limit: 10,
    })
    const {Option} = Select;
    //----- 弹框
    let [createModalVisible, setCreateModalVisible] = useState(false)
    let [editModalVisible, setEditModalVisible] = useState(false)
    let [createForm] = Form.useForm();
    let [editForm] = Form.useForm();
    let [Order, setOrder] = useState({});

    let cpoid = '';

    const columns = [
        // {
        //     title: '数据源',
        //     align: "center",
        //     dataIndex: 'ufdsys',
        //     width : 100
        // },
        {
            id:1,
            title: '订单号',
            dataIndex: 'cpoid',
            width : 220
        },
        {
            id:2,
            title: '订单日期',
            dataIndex: 'dpodate',
            width : 100
        },
        {
            id:3,
            title: '厂商编号',
            dataIndex: 'cvencode',
            width : 100
        },
        // {
        //     title: '识别编号',
        //     align: "center",
        //     width: 100,
        //     render: (text, record) => {
        //         return(
        //             <>
        //                 {record.vendor.cvenmnemcode}
        //             </>
        //         )
        //     }

        // },
        {
            id:4,
            title: '厂商简称',
            align: "center",
            width: 350,
            render: (text, record) => {
                return(
                    <>
                        {record.vendor.cvenabbname}
                    </>
                )
            }
        },
        {
            id:5,
            title: '制单人',
            dataIndex: 'cmaker',
            width: 100,
        },
        {
            id:5,
            title: '审核人',
            dataIndex: 'cverifier',
            width: 100,
        },
        {
            id:6,
            title: 'PDF',
            align: "center",
            width: 50,
            render: (text, record) => {
                return (
                    <FilePdfOutlined onClick={() => {
                        record.cvenname=record.vendor.cvenname
                        setOrder(record)
                        openPdf(record.cpoid)
                    }}/>
                )
            }
        },
        {
            id:7,
            title: '执行情况',
            align: "center",
            width: 120,
            render: (text, record) => {
                var k;
                if(record.cstate1==2){
                    k= <span style={{backgroundColor:"#1c84c6",boxShadow:"0px 0px 2px #888888",borderRadius:"3px",fontSize:"12px",color:"#FFF",padding:"1.5px 6px",display:"inline-block"}}>供应商已回复</span>
                } else if(record.cstate1==1){
                    k= <span style={{backgroundColor:"#f8ac59",boxShadow:"0px 0px 2px #888888",borderRadius:"3px",fontSize:"12px",color:"#FFF",padding:"1.5px 6px",display:"inline-block"}}>待供应商回复</span>
                }
                return (
                    <>
                        {k}
                    </>
                )
            }
        },
        {
            id:8,
            title: '操作',
            align: "center",
            width: 80,
            render: (text, record) => {
                return (
                    <>
                        <Button style={{backgroundColor:"#1ab394"}} className="bianji" onClick={() => {
                            getpopodetails(record.cpoid)
                            record.cvenname=record.vendor.cvenname
                            setOrder(record)
                            setEditModalVisible(true)
                        }} ><EyeOutlined />查看</Button>
                    </>
                )
            }
        }
    ];
    // 获取供应商列表
    const getOrders = () => {
        var type=window.localStorage.getItem("type")
        var cv = '';
        if(type=="2"){
            cv = JSON.parse(window.localStorage.getItem("user")).cvencode;
            setCvencode(cv)
        }
        common.loadingStart()

        let params = {
            cvencode:cv ? cv : '',
            ...search,
            page: pagination.page,
            limit: pagination.limit,
        }
        console.log(params);
        common.ajax("GET", "/passport/poPomain/list", params).then(data => {
            setDataList(data.list)
            setPagination(data.pagination)
            common.loadingStop()
        })
    }
    useEffect(() => {
        getOrders()
    }, [pagination.page, pagination.limit,search])
    var ll;
    var type=window.localStorage.getItem("type")
    if(type=="1"){
        ll=<Col span={3}> <Form.Item name="cvencode" getValueFromEvent={common.getValueFromEvent}>
            <Input placeholder="厂商编号"  onChange={(e) => {
                let value = common.replacezifu(e.target.value)
                setQuery({...query,cvencode: value})
            }}/>
        </Form.Item></Col>
    }
    let [popodetails, setPopodetails] = useState([])
    let [pdfDetails, setPdfDetails] = useState([])
    const getpopodetails = (cpoid) => {
        common.loadingStart()
        if(cpoid==undefined){
            return [];
        }
        var cvencode;
        var type=window.localStorage.getItem("type")
        if(type=="2"){
            cvencode=JSON.parse(window.localStorage.getItem("user")).cvencode;
        }else {
            cvencode= ''
        }
        let params = {
            cvencode:cvencode,
            cpoid:cpoid
        }
        common.ajax("GET", "/passport/poPodetails/list", params).then(data => {
            common.loadingStop()
            setPopodetails(data.list)

        }).catch( () => {
            common.loadingStop();
            setPopodetails([])
        })
    }

    //打开pfd
    const openPdf = (cpoid) => {
        let params = {
            cpoid:cpoid
        }
        common.ajax("GET", "/passport/poPodetails/list", params).then(data => {

            let count = 12;
            let list = data.list
            let times = count - data.list.length
            for (let i=0; i < times; i++) {
                list.push({});
            }
            setPdfDetails(list)
        })

    }
    useEffect(() => {
        console.log(pdfDetails);
        if(pdfDetails.length != 0){
            var element = document.getElementById("pdf");    // 这个dom元素是要导出pdf的div容器
            var canvas = document.createElement("canvas");
            html2canvas(element,{
                allowTaint: true,
                taintTest: false,
                scale: '1',
                dpi: '192',
                background: '#fff',
            }).then(function (canvas) {
                 let contentWidth = canvas.width
                let contentHeight = canvas.height
                let pageHeight = contentWidth / 592.28 * 841.89
                let leftHeight = contentHeight
                let position = 0
                let imgWidth = 595.28
                let imgHeight = 592.28 / contentWidth * contentHeight
                let pageData = canvas.toDataURL('image/jpeg', 1.0)
                let PDF = new jsPDF('', 'pt', 'a4')
                if (leftHeight < pageHeight) {
                    PDF.addImage(pageData, 'PNGS', 0, 0, imgWidth, imgHeight)
                } else {
                    while (leftHeight > 0) {
                        PDF.addImage(pageData, 'PNGS', 0, position, imgWidth, imgHeight)
                        leftHeight -= pageHeight
                        position -= 841.89
                        if (leftHeight > 0) {
                            PDF.addPage()
                        }
                    }
                }
                PDF.output('dataurlnewwindow');
                // var pageData = canvas.toDataURL('image/jpeg', 1.0);
                // var pdf = new jsPDF('p', 'px');
                // pdf.addImage(pageData, 'PNGS', 0, 0);
                // pdf.output('dataurlnewwindow');
            });
        }

        
    }, [pdfDetails])


    //供应商回复
    const funcitonReply = (index,item,value) => {
        let p = popodetails
        p[index][item] = value
        setPopodetails(p)
    }

    const functionHuifu = () => {
        let urlData = {
            token : common.getToken(),
            type : common.getType()
        }
       
        for(let i = 0; i < popodetails.length; i++){
            if(!popodetails[i].confirmdate){
                common.toast('请填写确认日期')
                return false;
            }
        }
       
        axios({
            headers: {'Content-Type': 'application/json'},
            method: 'POST',
            url: common.getApiUrl("/passport/poPodetails/update", urlData),
            data: popodetails,
            contentType: "application/json;charset=UTF-8",
            timeout: 20000
        }).then((response) => {
            if (response.data.code === 'SUCCESS') {
                setEditModalVisible(false)
                common.toast('修改成功')
                getOrders()
            }
        })
        // console.log(popodetails);
        // common.ajax("POST", "/passport/poPodetails/update", popodetails).then(data => {
            
        // }).finally(common.loadingStop)
    }

    const downloadFileToExcel = () => {

        let data = popodetails;

        data.map((value,index) => {
            data[index].cpoid = Order.cpoid;
            data[index].dpodate = Order.dpodate;
            data[index].cvenname = Order.cvenname;
            data[index].cvencode = Order.cvencode;
            data[index].cmaker = Order.cmaker;
            data[index].cverifier = Order.cverifier;
        })

        let dataTable = [];  //excel文件中的数据内容
        let option = {};  //option代表的就是excel文件
        dataTable  = data;  //从props中获取数据源
        option.fileName = Order.cpoid;  //excel文件名称
        option.datas = [
            {
                sheetData: dataTable,  //excel文件中的数据源
                sheetName: Order.cpoid,  //excel文件中sheet页名称
                sheetFilter: [
                    'cpoid' , 'dpodate' , 'cvenname' , 'cvencode' , 'cmaker' , 'cverifier' ,
                    'cinvcode', 'cinvname', 'cinvstd', 'cinvdefine1', 'ccomunitname','iquantity', 'iunitprice', 
                    'cdefine25' , 'darrivedate' , 'confirmdate' , 'cvenremark' , 'deliveryquestion' ,'cdefine23'
                ],  //excel文件中需显示的列数据
                sheetHeader: [
                    '订单编号' , '订单日期' , '厂商名称' , '厂商编号' , '制单' , '审核' ,
                    '图号', '名称', '规格', '版本号', '单位','数量', '未税单价', 
                    '追溯批号', '到货日期', '确认日期' , '供应商说明' , '交货要求', '备注'
                    
                ],  //excel文件中每列的表头名称
            }
        ]
        console.log(popodetails);
        let toExcel = new ExportJsonExcel(option);  //生成excel文件
        toExcel.saveExcel();  //下载excel文件

    }

    return (
        <div className="order">
            {/* style={{margin:"0",border:"0",width:0,height:0,overflow:"hidden"}} */}
            <div  style={{margin:"0",border:"0",width:0,height:0,overflow:"hidden"}} >
                <div id="pdf" style={{margin:"0",border:"0",width:780}}>
                    <div id='top'>
                        <div id='top-logo'>
                            <img src='../../../../heyu.png' width='150'/>
                        </div>
                        <div id='top-text'>
                            <div className='top-text-1' style={{fontSize:'36px',fontWeight: 'bold'}}>上海合愉电机有限公司</div>
                            <div className='top-text-2' style={{fontSize:'14px'}}>SHANGHAI JAKEL ELECTRICAL MACHINERY CO .， LTD</div>
                        </div>
                    </div>
                    
                    <table className="po_head_pdf" style={{width:"100%",margin:"auto",fontSize: 16}}>
                        <thead  style={{fontSize:36,textAlign:"center"}}>
                            <tr>
                                <th colSpan={3} className='pdf_head'>采 购 订 单</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='pdf_body'>公司名称：上海合愉电机有限公司</td>
                                <td className='pdf_body' colSpan={2}>地址：上海市宝山区萧云路1058号2号楼</td>
                            </tr>
                            <tr>
                                <td className='pdf_body'>公司电话：021-51651778-8010 </td>
                                {/* <td className='pdf_body'>传真：51651769</td> */}
                                <td className='pdf_body'>邮编：200949</td>
                            </tr>
                            <tr>
                                <td className='pdf_body'>订单日期：{Order.dpodate}</td>
                                <td className='pdf_body'></td>
                            </tr>
                        </tbody>
                    </table>
                    <table border="1" className="po_main_pdf" style={{width:"100%",margin:"auto",fontSize: 12,marginTop:"20px"}}>
                        <thead style={{fontSize:16}} className="po_head_pdf">
                            <tr className="pdf_head">
                                <td colSpan={5}>厂商编号：{Order.cvencode}</td>
                                <td colSpan={6}>厂商名称：{Order.cvenname}</td>
                            </tr>
                            <tr>
                                <td colSpan={5}>传真号码：</td>
                                <td colSpan={6}>订单编号：{Order.cpoid}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td width="16%">图 号</td>
                                <td width="8%">名 称 </td>
                                <td width="14%">规格型号</td>
                                <td width="4%" >单位</td>
                                <td width="7%">数量</td>
                                <td width="8%">未税单价</td>
                                <td width="4%" >版本</td>
                                {/* <td width="12%">追溯批号</td> */}
                                <td width="9%">到货日期</td>
                                <td width="9%">确认日期</td>
                                <td width="9%">备注</td>
                            </tr>
                            {pdfDetails.map((item,rowKey)=>{
                                return(
                                    <tr key={rowKey} style={{fontSize: 12,textAlign:"center",height:"20px"}}>
                                        <td>{item.cinvcode}</td>
                                        <td>{item.cinvname}</td>
                                        <td>{item.cinvstd}</td>
                                        <td>{item.ccomunitname}</td>
                                        <td>{item.iquantity}</td>
                                        <td>{item.iunitprice}</td>
                                        {/* <td>版本</td> */}
                                        <td>{item.cinvdefine1}</td>
                                        {/* <td>{item.cdefine25}</td> */}
                                        <td>{item.darrivedate}</td>
                                        <td>{item.confirmdate}</td>
                                        <td>{item.cdefine23}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <table className="tables">
                        <tbody>
                            <tr >
                                <td></td>
                            </tr>
                            <tr>
                                <td style={{paddingTop:"10px"}}>备注</td>
                            </tr>
                            <tr>
                                <td style={{textIndent:20,paddingTop:"10px"}}>
                                    1.采购订单请签回，敬请配合。（2天内未签回视同签回）
                                </td>
                            </tr>
                            <tr>
                                <td style={{textIndent:20,paddingTop:"10px"}}>
                                    2.交货时必附：
                                    <span>
                                        <p style={{marginLeft:40}}>(1)送货单</p>
                                        <p style={{marginLeft:40}}>(2)出厂检验报告及自检样品</p>
                                        <p style={{marginLeft:40}}>(3)材质保证书</p>
                                        <p style={{marginLeft:40}}>(4)请遵守编号0711001和1210001通知相关要求</p>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td style={{textIndent:20,paddingTop:"10px"}}>
                                    3.图纸版本请核对确认，如果贵司图纸版本不符，请立即与采购联系获得有蓝色发行章的新图纸，否则则视同贵司确认图纸版本符合
                                </td>
                            </tr>
                            <tr>
                                <td style={{textIndent:20,paddingTop:"10px"}}>
                                    4.送货单上写好订单编号;严格执行到货时间，允许提前2天到达。
                                </td>
                            </tr>
                            <tr>
                                <td style={{textIndent:20,paddingTop:"10px"}}>
                                    5.供应商对要求到货日期没有按期实现，采购的物资视为延期交付；如果按到货日期
                                        计算延长30天仍未到货，采购订单中相关内容作自动取消。
                                </td>
                            </tr>
                            <tr>
                                <td style={{textIndent:20,paddingTop:"10px"}}>
                                    6.请在出厂检验报告及材质保证书对应零件图号栏标注对应零件的版本号
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                    <table style={{marginLeft:"auto"}}>
                        <tr className='foot_table'>
                            <td className='foot_table_right'>FM-SP06-03</td>
                            <td>1.0</td>
                        </tr>
                    </table>
                    <div></div>
                    <table style={{width:"100%",margin:"auto",fontSize: 16,marginTop:"20px"}} >
                        <tr >
                            <td>制单人：  {Order.cmaker}</td>
                            <td>审核人：  {Order.cverifier}</td>
                            <td>厂商签回：</td>
                        </tr>
                    </table>
                </div>
            </div>
           
            <Breadcrumb>
                <Breadcrumb.Item>订单管理</Breadcrumb.Item>
                <Breadcrumb.Item>采购订单</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{minHeight: 360}}>
                
                <Form form={searchForm} style={{marginTop: 15}} className='searchForm'>
                    <Row gutter={24}>
                        {ll}
                        <Col span={3}>
                            <Form.Item name="cstate1">
                                <Select placeholder="回复状态" showSearch onChange={(value)=>{
                                    setQuery({...query, cstate1: value})
                                }}>
                                    <Option value="">回复状态</Option>
                                    <Option value="1"> 待供应商回复</Option>
                                    <Option value="2"> 供应商已回复</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="cpoid" getValueFromEvent={common.getValueFromEvent}>
                                <Input placeholder="订单号"  onChange={(e) => {
                                    let value = common.replacezifu(e.target.value)
                                    setQuery({...query,cpoid: value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="startTime">
                                <DatePicker onChange={(value)=>{
                                    let time = value ? moment(value).format('YYYY-MM-DD 00:00:00') : ''
                                    setQuery({...query, startTime: time})
                                }} placeholder="制单开始时间"/>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item  name="endTime">
                                <DatePicker onChange={(value)=>{
                                    let time = value ? moment(value).format('YYYY-MM-DD 00:00:00') : ''
                                    setQuery({...query, endTime: time})
                                }} placeholder="制单结束时间" />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="cmaker" getValueFromEvent={common.getValueFromEvent}>
                                <Input placeholder="制单人"  onChange={(e) => {
                                    let value = common.replacezifu(e.target.value)
                                    setQuery({...query,cmaker: value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item>
                                <Space>
                                    <Button onClick={() => {
                                        pagination.page = 1;
                                        setSearch(query)
                                    }} className='search-btn mybtn'><SearchOutlined />搜索</Button>
                                    <Button onClick={() => {
                                        let emptySearch = {
                                            cvencode: '',
                                            cstate1:'',
                                            cpoid:'',
                                            startTime:'',
                                            endTime:'',
                                            cmaker:'',
                                        }
                                        searchForm.setFieldsValue(emptySearch)
                                        emptySearch = {}
                                        setSearch(emptySearch)
                                        setQuery(emptySearch)
                                    }} className='clean-btn mybtn'><ReloadOutlined />清空</Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div className='table-box'>
                    {/* <Button type="primary" onClick={() => {
                        setCreateModalVisible(true)
                        createForm.setFieldsValue({title: ""})
                    }} className='add-btn mybtn'><PlusOutlined />新增</Button> */}
                    <Table rowKey={columns => columns.id}
                        dataSource={dataList} columns={columns} pagination={false} 
                        scroll={{ x: 1400}}
                        rowClassName={(record, idx) => {
                            if(idx % 2 === 1)
                                return 'bg-row';
                        }}/>
                </div>
                <Modal width={1300} title="订单详情" footer={false} maskClosable={false} destroyOnClose={true}  visible={editModalVisible} onCancel={() => {
                    setEditModalVisible(false)
                }}>
                    <div style={{width:"1240px",borderColor:"#ddd",fontSize:"12px",border:"1px solid #ddd",borderRadius:"5px"}}>
                        <div style={{padding:"10px 15px",backgroundColor:"#f5f5f5",borderBottom:"1px solid #ddd",height:"40px"}}>
                            
                            { cvencode == '' ? <></> :
                                Order.cstate1 == 1 ?  
                                <div style={{float:"right",marginRight:"10px"}} className='modelIcon reply' onClick = {functionHuifu}>
                                    <RocketOutlined />&nbsp;回复
                                </div> 
                                :
                                <div style={{float:"right",marginRight:"10px"}} className='modelIcon reply' onClick = {functionHuifu}>
                                    <EditOutlined />&nbsp;修改
                                </div>
                            }

                            <div style={{float:"right",marginRight:"10px"}} className='modelIcon' 
                                onClick={ () => {downloadFileToExcel()} } >
                                <DownloadOutlined />&nbsp;导出
                            </div>
                        </div>
                        <div>
                            <span className={Order.cstate1==2?"tupan":"tupan2"}><b>{Order.cstate1==2 ? "已回复" : "未回复"}</b></span>
                            <table width="1200" style={{marginLeft:"80px",marginTop:"10px"}} cellPadding="5">
                                <tr>
                                    <td>订单编号:{Order.cpoid}</td>
                                    <td align="left">订单日期:{Order.dpodate}</td>
                                </tr>
                                <tr>
                                    <td>厂商名称:{Order.cvenname}</td>
                                    <td width="350" align="left">厂商编号:{Order.cvencode}</td>
                                </tr>
                            </table>
                            <table bordercolor="#808080" border="1" style={{marginTop:"15px"}} width="90%" align="center">
                                <tbody>
                                    <tr align="center">
                                        <td>图号</td>
                                        <td>名称</td>
                                        <td>规格</td>
                                        <td>版本号</td>
                                        <td>单位</td>
                                        <td>数量</td>
                                        <td>未税单价</td>
                                        <td>追溯批号</td>
                                        <td>到货日期</td>
                                        <td>确认日期</td>
                                        <td>供应商说明</td>
                                        <td>交货要求</td>
                                        <td>备注</td>
                                    </tr>
                                {popodetails.map((item,rowKey)=>{
                                    return(
                                        <>
                                            <tr align="center" key={rowKey}>
                                                <td>{item.cinvcode}</td>
                                                <td>{item.cinvname}</td>
                                                <td>{item.cinvstd}</td>
                                                <td>{item.cinvdefine1}</td>
                                                <td>{item.ccomunitname}</td>
                                                <td>{item.iquantity}</td>
                                                <td>{item.iunitprice}</td>
                                                <td>{item.cdefine25}</td>
                                                <td>{item.darrivedate}</td>
                                                { cvencode == '' ?  
                                                    <td>{item.confirmdate}</td> : 
                                                    <td>
                                                        <DatePicker className='confirmdate' defaultValue={ item.confirmdate ? moment(item.confirmdate, 'YYYY-MM-DD') : ''} onChange={(value)=>{
                                                            let time = value ? moment(value).format('YYYY-MM-DD 00:00:00') : ''
                                                            funcitonReply(rowKey,'confirmdate', time)
                                                            // setQuery({...query, startTime: value})
                                                        }} placeholder="确认日期"/> 
                                                    </td>
                                                }
                                                { cvencode == '' ?  
                                                    <td>{item.cvenremark}</td> : 
                                                    <td>
                                                        <Form.Item className='cvenremark' name="cvenremark" >
                                                            <Input className='cvInput' defaultValue={item.cvenremark} onChange={(value) => {
                                                                let v = value.target.value ? value.target.value : ''
                                                                funcitonReply(rowKey,'cvenremark', v)
                                                            }}/>
                                                        </Form.Item>
                                                    </td>
                                                    
                                                }
                                                <td>{item.deliveryquestion}</td>
                                                <td>{item.cdefine23}</td>
                                            </tr>
                                        </>
                                    )
                                })}
                                </tbody>
                            </table>
                            <table width="1000" style={{marginLeft:"120px",marginTop:"10px"}} cellPadding="5">
                                <tr width="700">
                                    <td>制单:{Order.cmaker}</td>
                                    <td align="left">状态:审核</td>
                                </tr>
                                <tr>
                                    <td>审核:{Order.cverifier}</td>
                                    <td width="160" align="left">执行:{Order.cstate1==2?"供应商已回复":"待供应商回复"}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </Modal>
                {pagination.total < 5 ? '' :
                    <PaginationBar>
                        <Pagination
                            style={{marginTop: 25}}
                            pageSizeOptions={['10', '20', '50', '100']}
                            onChange={(page, limit) => {
                                setPagination({...pagination, page, limit})
                            }}
                            onShowSizeChange={(page, limit) => {
                                setPagination({...pagination, page, limit})
                            }}
                            showTotal={total => `共${total}条`}
                            total={pagination.total}
                            defaultCurrent={pagination.page}
                            current={pagination.page}
                            defaultPageSize={pagination.limit}
                            showSizeChanger
                            showQuickJumper
                        /></PaginationBar>}
            </div>

            

        </div>
    );
}
export default PoPomain;