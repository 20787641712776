import React from "react";
import * as Icon from "@ant-design/icons";
function MyIcon(props) {
    return (
        React.createElement(
            Icon[props.icon],
            {
                style: {fontSize: '16px'}
            }
        )
    )
};
export default MyIcon;