import React, {useEffect, useState} from 'react';
import { Card, Avatar, Button } from 'antd';
import './index.less';
import common from "../../common"
import { Document, Page, pdfjs } from 'react-pdf'
import PDF from 'react-pdf-js'
import { Pagination } from 'antd';
function Pdf(props) {

    const style = {
        color:'red',fontSize:'36px',fontWeight:'bolder'
    }

    let [user, setUser] = useState({name: ""})
    let [dataList, setDataList] = useState({files:[]}) 

    let [pagination, setPagination] = useState({
        total: 0,
        page: 1,
        limit: 5,
    })


    const getDataList = () => {
        let id = props.match.params.id
        if(id == null){
            return;
        }
        let params = {
            id : id,
            page: pagination.page,
            limit: pagination.limit,
        }
        common.ajax("GET", "/passport/wjNotice/byId", params).then(data => {

            params.views = data.views + 1
            common.ajax("POST", "/passport/wjNotice/update", params).then(data => {})

            setDataList(data)
        })
    }

    const [totalPage, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState(1);
    function onDocumentLoadSuccess(totalPage) {
        setTotalPages(totalPage);
        setCurrentPage(1);
    }
    function onChangePage(page) {
        setCurrentPage(page);
        console.log(page)
    }
    
    const handle=(url)=>{
        let w=window.open('about:blank');
        w.location.href=url
      }

    useEffect(() => {
        getDataList()
    }, [pagination.page])
    return (
        <>
            <Card style={{width:'100%',display:'inline-block'}} headStyle={dataList.isRed == 1 ? {fontSize:'36px',fontWeight:'bolder'}  : {...style}} title={dataList.title}>
                <div style={{padding:24}}>
                    <h2>{dataList.keyss}</h2>  

                    <p>发布时间：{dataList.eTime}  发布人：{dataList.username}  阅读次数：{dataList.views} </p>  
                    
                    <p style={{marginTop:25}}>相关附件</p>  
                    {
                        dataList.files.map((item,key) => {
                            return(
                                <p style={{cursor:'pointer'}} onClick={() => handle(`${item.savepath}${item.savename}`)} key={key}>{item.name}</p>
                            )
                        })
                    }        
                    {/* <PDF
                        //文件路径,
                        file='/uploads/20220318/test.pdf'
                        onDocumentComplete={onDocumentLoadSuccess}
                        page={currentPage}> 
                    </PDF> */}
                    {/* <Pagination onChange={(p)=>onChangePage(p)} total={totalPage} page={currentPage} defaultPageSize={1}/> */}

                    <Card style={{width:'100%',display:'inline-block',padding:20}}>
                    <p dangerouslySetInnerHTML={{__html: dataList.des}}></p> 
                    </Card>
                           
                </div>
            </Card>
        </>
    );
}
export default Pdf;