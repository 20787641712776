import React, {Component} from 'react'

function Display(prop) {
    let list = [];
    for (let i =0; i <=  prop.length - 1; i++) {
        if (prop[i].show != false) {
            list.push(prop[i]);
        }
    }
    return list;
}
export default Display
