import React, {useEffect,useState} from 'react';
import {Form, Input, Button, Typography } from 'antd';
import axios from 'axios';
import "./index.less"
import common from "../../common";
import {EyeInvisibleOutlined, EyeTwoTone , UserOutlined , LockOutlined} from '@ant-design/icons';
const {Title} = Typography;
function Login(props) {
    let [loginForm] = Form.useForm();
    let [data, setData] = useState({
        mobile:"",
        password:"",
    })
    let [loadicon, setLoadicon] = useState(false)
    const onFinish = (value) => {
        setLoadicon(true)
        let con = {
            ...value,
            typel:1,
            // "loginEquipment" : sub ? sub : 'windows',
            // "ip": response.data.ip ? response.data.ip : '0.0.0.0',
        }
        common.ajax("post", "/passport/token/create", con).then(res => {
            common.setToken(res.token,res.type)
            common.ajax("get", "/passport/user/whoami").then(res => {

                window.localStorage.setItem("user", JSON.stringify(res))
                common.toast("登录成功")
                props.history.push('/')
            }).finally(setLoadicon(false))
        }).catch(function (error) {
            setLoadicon(false)
        })
    };
    return (
        <div className="Login">
            <div className="login" style={{width:"1014px",height:"660px"}}>
                <div className="login-left">
                    <div className="login-left-content">
                        <div className="login-nav">供应链协同平台<span style={{fontSize:14}}>U</span></div>
                        <Form
                            wrapperCol={{span: 18}}
                            form={loginForm}
                            size="large"
                            labelAlign="right"
                            onFinish={(values) => {
                                onFinish(values)}}>
                            <Form.Item
                                name="userName" 
                                rules={[{ required: true, message: '请输入用户名' }]}>
                                <Input prefix={<UserOutlined className="site-form-item-icon"/>} className='input-username login-input' placeholder="请输入用户名" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: '请输入密码' }]}>
                                <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} className='input-password login-input' placeholder="请输入密码"  iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                            </Form.Item>
                            <Form.Item wrapperCol={{span: 14}}>
                                <Button className="login-button" type="primary" style={{marginTop:10}} loading={loadicon} htmlType="submit">
                                登录
                                </Button>
                            </Form.Item>
                        </Form>
                        {/*<div className="login-forget">忘记密码？</div>*/}
                        {/*<div className="login-pic">*/}
                        {/*    <span><img className="login-pic-span" src={alipay} alt=""/></span>*/}
                        {/*    <span><img className="login-pic-span" src={wechat} alt=""/></span>*/}
                        {/*    <span><img className="login-pic-span" src={qq} alt=""/></span>*/}
                        {/*</div>*/}
                    </div>
                        <div className="login-select">©2022上海合愉电机有限公司</div>

                </div>
                <div className="login-right">
                    <p className="login-come">SRM智能系统<br/></p>
                    <p className="login-come">合作<br/></p>
                    <p className="login-come">高效<br/></p>
                    <p className="login-come">服务<br/></p>
                </div>
            </div>
        </div>
    );
}
export default Login;