import React, {useEffect, useState} from 'react'
import {Breadcrumb,DatePicker, Space, Button, Pagination ,Select , Form, Modal , Upload , Row, Col , Input , Table} from 'antd'
import {BarChartOutlined , UploadOutlined , 
    ReloadOutlined , SearchOutlined , ImportOutlined ,VerticalAlignBottomOutlined  } from "@ant-design/icons";
import ExportJsonExcel from 'js-export-excel';
import moment from 'moment';
import common from '../../common';
import PaginationBar from "../../components/paginationbar";
import * as XLSX from 'xlsx';
import axios from 'axios';
var name="公告管理"
function Views(props) {
    const {Option} = Select;
    let [pagination, setPagination] = useState({
        total: 0,
        page: 1,
        limit: 10,
    })
    let [searchForm] = Form.useForm();
    let [list,setList] = useState([]);
    let [createData,setCreateData] = useState({
        one:[],
        two:[],
        three:[],
        four:[],
        after:'',
        table:""
    });
    
    const columns = [
        {
            title: '存货编码',
            dataIndex: 'cinvcode',
            fixed: 'left',
            width:200
        },
        {
            title: '分类',
            dataIndex: 'cinvaddcode',
            fixed: 'left',
            width:200
        },
        {
            title: '物料名称',
            dataIndex: 'cinvname',
            fixed: 'left',
            width:200
        },
        {
            title: '现存量',
            dataIndex: 'currentstock',
            width:200
        },
        {
            title: '采购在途数量',
            dataIndex: 'buyNum',
            width:200
        },
        {
            title: '总的需求数量',
            width:200,
            dataIndex: 'needNum',
        },
        {
            title: '总缺料数',
            width:200,
            render: (text, record) => {
                return(
                    <>
                        {record.needBuyNum * -1} 
                    </>
                )
            }
        },
        {
            title: '第一周需求 ' + createData.one[0],
            width:200,
            render: (text, record) => {
                return(
                    <>
                        {record.soS1 ? record.soS1 : 0} 
                    </>
                )
            }
        },
        {
            title: '第一周缺料',
            width:200,
            render: (text, record) => {
                let c = record.currentstock - record.soS1
                if(c < 0){
                    return(
                        <span style={{color:'red',fontWeight:'bolder'}}>
                            {c} 
                        </span>
                    )
                }else{
                    return(
                        <span>
                            {c} 
                        </span>
                    )
                }
            }
        },
        {
            title: '第二周需求 ' + createData.two[0],
            width:200,
            render: (text, record) => {
                return(
                    <>
                        {record.soS2 ? record.soS2 : 0} 
                    </>
                )
            }
        },
        {
            title: '第二周缺料' ,
            width:200,
            render: (text, record) => {
                let c = record.currentstock - record.soS1 -  record.soS2
                if(c < 0){
                    return(
                        <span style={{color:'red',fontWeight:'bolder'}}>
                            {c} 
                        </span>
                    )
                }else{
                    return(
                        <span>
                            {c} 
                        </span>
                    )
                }
            }
        },
        {
            title: '第三周需求 ' + createData.three[0],
            width:200,
            render: (text, record) => {
                return(
                    <>
                        {record.soS3 ? record.soS3 : 0} 
                    </>
                )
            }
        },
        {
            title: '第三周缺料' ,
            width:200,
            render: (text, record) => {
                let c = record.currentstock - record.soS1 -  record.soS2 -  record.soS3
                if(c < 0){
                    return(
                        <span style={{color:'red',fontWeight:'bolder'}}>
                            {c} 
                        </span>
                    )
                }else{
                    return(
                        <span>
                            {c} 
                        </span>
                    )
                }
            }
        },
        {
            title: '第四周需求 ' + createData.four[0],
            width:200,
            render: (text, record) => {
                return(
                    <>
                        {record.soS4 ? record.soS4 : 0} 
                    </>
                )
            }
        },
        {
            title: '第四周缺料',
            width:200,
            render: (text, record) => {
                let c = record.currentstock - record.soS1 -  record.soS2 -  record.soS3 - record.soS4
                if(c < 0){
                    return(
                        <span style={{color:'red',fontWeight:'bolder'}}>
                            {c} 
                        </span>
                    )
                }else{
                    return(
                        <span>
                            {c} 
                        </span>
                    )
                }
            }
        },
        {
            title: '四周后需求 ' + createData.after,
            width:200,
            render: (text, record) => {
                return(
                    <>
                        {record.soFs4 ? record.soFs4 : 0} 
                    </>
                )
            }
        },
        {
            title: '四周后缺料',
            width:200,
            render: (text, record) => {
                let c = record.currentstock - record.soS1 -  record.soS2 -  record.soS3 - record.soS4 - record.soFs4
                if(c < 0){
                    return(
                        <span style={{color:'red',fontWeight:'bolder'}}>
                            {c} 
                        </span>
                    )
                }else{
                    return(
                        <span>
                            {c} 
                        </span>
                    )
                }
            }
        },
        {
            title: '采购订单数量',
            dataIndex: 'poNum',
            width:200,
        },
        {
            title: '已检未入库数量',
            width:200,
            dataIndex: 'needQcArronhandNum',
        },
    ];

    const getList = (data) => {
        let params = {
            ...data,
            dataBase : createData.table,
            page: pagination.page,
            limit: pagination.limit,
        }
        
        common.ajax("GET", "/passport/mrp/list", params).then(data => {
            console.log(data);
            setList(data.list)
            setPagination(data.pagination)
        }).finally(common.loadingStop)
    }

    const findAll = (data) => {
        common.loadingStart();
        let params = {
            ...data,
            dataBase : createData.table,
        }
        
        common.ajax("GET", "/passport/mrp/findAll", params).then(data => {
            // common.downloadExl(data,"xlsx",columns,'expload')
            data.map((item,index) => {
                data[index]['needBuyNum'] = item.needBuyNum * -1
                data[index]['soS1'] = item.soS1 ? item.soS1 : 0
                data[index]['soS1q'] = item.currentstock - item.soS1
                data[index]['soS2'] = item.soS2 ? item.soS2 : 0
                data[index]['soS2q'] = item.currentstock - item.soS1 - item.soS2
                data[index]['soS3'] = item.soS3 ? item.soS3 : 0
                data[index]['soS3q'] = item.currentstock - item.soS1 - item.soS2 - item.soS3 
                data[index]['soS4'] = item.soS4 ? item.soS4 : 0
                data[index]['soS4q'] = item.currentstock - item.soS1 - item.soS2 - item.soS3 - item.soS4 
                data[index]['soFs4'] = item.soFs4 ? item.soFs4 : 0
                data[index]['soFs4q'] = item.currentstock - item.soS1 - item.soS2 - item.soS3 - item.soS4 - item.soFs4 
            })
            downloadFileToExcel(data)
        }).finally(common.loadingStop)
    }


    const downloadFileToExcel = (data) => {
        let dataTable = [];  //excel文件中的数据内容
        let option = {};  //option代表的就是excel文件
        dataTable  = data;  //从props中获取数据源
        option.fileName = '四周合并版' + moment().format('YYYYMMDD');  //excel文件名称
        option.datas = [
            {
                sheetData: dataTable,  //excel文件中的数据源
                sheetName: '四周合并版',  //excel文件中sheet页名称
                sheetFilter: [
                    'cinvcode','cinvaddcode','cinvname', 'currentstock', 'buyNum', 'needNum','needBuyNum',
                    'soS1', 'soS1q' , 'soS2' , 'soS2q' , 'soS3' , 'soS3q' , 'soS4' , 'soS4q' ,
                    'soFs4' , 'soFs4q' , 'poNum' , 'needQcArronhandNum'
                ],  //excel文件中需显示的列数据
                sheetHeader: [
                    '存货编码', '分类', '存货名称', '现存量', '采购在途数量', '总的需求数量','总缺料数',
                    '第一周需求' + createData.one[0] , '第一周缺料' , 
                    '第二周需求' + createData.two[0] , '第二周缺料' , 
                    '第三周需求' + createData.three[0] , '第三周缺料' , 
                    '第四周需求' + createData.four[0] , '第四周缺料' , 
                    '四周后需求' + createData.after , '四周后缺料' , 
                    '采购订单数量' , '已检未入库交数量'
                ],  //excel文件中每列的表头名称
            }
        ]
        let toExcel = new ExportJsonExcel(option);  //生成excel文件
        toExcel.saveExcel();  //下载excel文件
    }

    useEffect(() => {
        let indexs = window.localStorage.getItem("indexs")
        if(indexs != ""){
            let index1 = JSON.parse(indexs);
            setCreateData({...index1}) 
            console.log(index1);
        }
    }, [])

    useEffect(() => {
        if(createData.table != ""){
            getList();
        }
    }, [pagination.page, pagination.limit,createData])

    return (
        <div className="Workshop" >
            <Breadcrumb>
                <Breadcrumb.Item>MRP计算器</Breadcrumb.Item>
                <Breadcrumb.Item>四周合并版</Breadcrumb.Item>
            </Breadcrumb>
            <div >
                
            <Form form={searchForm} style={{marginTop: 15}} className='searchForm'>
                <Row gutter={24}>
                    <Col span={3}>
                        <Form.Item name="cstate">
                            <Select placeholder="是否缺料" showSearch >
                                <Option>是否缺料</Option>
                                <Option value="1">满足</Option>
                                <Option value="2">缺料</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="cinvcode">
                            <Input placeholder="物料编号"/>
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item name="cinvaddcode">
                            <Select placeholder="请选择分类">
                                <Option value="">请选择分类</Option>
                                <Option value="电机">电机</Option>
                                <Option value="线圈">线圈</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item>
                            <Space>
                                <Button onClick={() => {
                                    getList(searchForm.getFieldsValue())
                                    pagination.page = 1
                                }} className='search-btn mybtn'><SearchOutlined />搜索</Button>
                                <Button onClick={() => {
                                    let emptySearch = {
                                        cstate: '',
                                        cinvcode:'',
                                        cinvaddcode:''
                                    }
                                    searchForm.setFieldsValue(emptySearch)
                                }} className='clean-btn mybtn'><ReloadOutlined />清空</Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            </div>
            <div className='table-box'>
                    <div style={{borderBottom:'1px solid #eee',width:'100%',padding:'10px'}}>
                        <div style={{display:'inline-block',width:'50%'}}>
                            <span style={{fontWeight:'bolder'}}>MRP运算汇总结果</span>
                            <span style={{padding:'2px 4px',fontWeight:'bold',fontSize:'12px',color:'red',backgroundColor:'#F9F2F4'}}> 账套:{createData.table} 计算日期:{moment().format('YYYY-MM-DD HH:mm:ss')}</span>
                        </div>
                        <div style={{display:'inline-block',width:'50%',textAlign:'right'}}>
                            <Button style={{margin:'auto'}} onClick={()=>{
                                props.history.push('/indexs')
                                window.localStorage.setItem("indexs",'')
                            }}>
                                <ImportOutlined />返回计算器
                            </Button>
                            <Button style={{marginLeft:'10px'}} onClick={()=>{
                                findAll(searchForm.getFieldsValue())
                            }}>
                                <VerticalAlignBottomOutlined  />数据导出
                            </Button>
                            <a href="" id={"hfexpload"}></a>
                        </div>
                    </div>
                    <Table rowKey={columns => columns.id}
                        dataSource={list} columns={columns} pagination={false} 
                        scroll={{ x: 1400}}
                        rowClassName={(record, idx) => {
                            if(idx % 2 === 1)
                                return 'bg-row';
                        }}/>
                    
            </div>
            {pagination.total < 5 ? '' :
                <PaginationBar>
                    <Pagination
                        style={{marginTop: 25}}
                        pageSizeOptions={['10', '20', '50', '100']}
                        onChange={(page, limit) => {
                            setPagination({...pagination, page, limit})
                        }}
                        onShowSizeChange={(page, limit) => {
                            setPagination({...pagination, page, limit})
                        }}
                        showTotal={total => `共${total}条`}
                        total={pagination.total}
                        defaultCurrent={pagination.page}
                        current={pagination.page}
                        defaultPageSize={pagination.limit}
                        showSizeChanger
                        showQuickJumper
                    /></PaginationBar>}
        </div>
    );
}
export default Views;
