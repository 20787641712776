import axios from 'axios';
import qs from 'qs';
import {Modal, message} from 'antd';
import baseApi from "./config-local"
import XLSX from "xlsx"
import creatHistory from 'history/createHashHistory'  //返回上一页这段代码
const history = creatHistory();//返回上一页这段代码

let tokenKey = `token`
let typeKey = `type`
let common = {}
var tmpDown; //导出的二进制对象
common.removeNull=function(obj){
    Object.keys(obj).forEach(item=>{
        if(!obj[item] || obj[item]=='')  delete obj[item]
    })
    return obj;
}
common.downloadExl=function (json, type,columns,name) {
    console.log(json)
    var json= TurnChinese(json,columns)
    var tmpdata = json[0];
    json.unshift({});
    var keyMap = []; //获取keys
    //keyMap =Object.keys(json[0]);
    for (var k in tmpdata) {
        keyMap.push(k);
        json[0][k] = k;
    }
    var tmpdata = [];//用来保存转换好的json
    json.map((v, i) => keyMap.map((k, j) => Object.assign({}, {
        v: v[k],
        position: (j > 25 ? getCharCol(j) : String.fromCharCode(65 + j)) + (i + 1)
    }))).reduce((prev, next) => prev.concat(next)).forEach((v, i) => tmpdata[v.position] = {
        v: v.v
    });
    var outputPos = Object.keys(tmpdata); //设置区域,比如表格从A1到D10
    var tmpWB = {
        SheetNames: ['mySheet'], //保存的表标题
        Sheets: {
            'mySheet': Object.assign({},
                tmpdata, //内容
                {
                    '!ref': outputPos[0] + ':' + outputPos[outputPos.length - 1] //设置填充区域
                })
        }
    };
    tmpDown = new Blob([s2ab(XLSX.write(tmpWB,
        {bookType: (type == undefined ? 'xlsx':type),bookSST: false, type: 'binary'}//这里的数据是用来定义导出的格式类型
    ))], {
        type: ""
    }); //创建二进制对象写入转换好的字节流
    var href = URL.createObjectURL(tmpDown); //创建对象超链接
    document.getElementById("hf"+name).download=CurentTime()+name+".xlsx"
    document.getElementById("hf"+name).href = href; //绑定a标签
    document.getElementById("hf"+name).click(); //模拟点击实现下载
    setTimeout(function() { //延时释放
        URL.revokeObjectURL(tmpDown); //用URL.revokeObjectURL()来释放这个object URL
    }, 100);
}
function CurentTime(){
    var now = new Date();
    var year = now.getFullYear();       //年
    var month = now.getMonth() + 1;     //月
    var day = now.getDate();            //日
    var hh = now.getHours(); //时
    var mm = now.getMinutes();  //分

    var clock = year + "-";
    if(month < 10)
        clock += "0";
    clock += month + "-";
    if(day < 10)
        clock += "0";
    clock += day + " ";
    if(hh < 10)
        clock += "0";
    clock += hh + ":";
    if (mm < 10) clock += '0';
    clock += mm;
    return(clock);

}
function TurnChinese(a,k){
    var kp={}
    k.map((g)=>{
        if(!(g.dataIndex==undefined || g.dataIndex==null)){
            kp[g.dataIndex]=g.title;
        }
    })
    console.log(kp)
    var ll=[]
    if(a!=null){
        for(var i=0;i<a.length;i++){
            var c={}
            for(let key  in a[i]){
                if(kp[key]!=undefined){
                    if(a[i][key]==null){
                        a[i][key]=""
                    }
                    c[kp[key]]=a[i][key];
                }
            }
            ll.push(c)
        }
    }else {
        var c={}
        for (let i = k.length - 1; i >= 0; i--) {
           c[k[i].title]="";
        }
        ll.push(c);
    }
    return ll;
}
function s2ab(s) { //字符串转字符流
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}
// 将指定的自然数转换为26进制表示。映射关系：[0-25] -> [A-Z]。
function getCharCol(n) {
    let temCol = '',
        s = '',
        m = 0
    while (n > 0) {
        m = n % 26 + 1
        s = String.fromCharCode(m + 64) + s
        n = (n - m) / 26
    }
    return s
}
common.redirectToLogin = function () {
    let local = window.location.href
    if(local.indexOf("heyu.srm.wijay.cn") != -1){
        window.location = '/#/UserLogin'
    }else{
        window.location = '/#/VendorLogin'
    }
}

common.redirect = function (url) {
    if(url){
        window.location = url
    }else{
        history.goBack();  //返回上一页这段代码
    }
}

common.setToken = function (token,type) {
    if (token) {
        window.localStorage.setItem(tokenKey, token)
        window.localStorage.setItem(typeKey, type)
    } else {
        window.localStorage.removeItem(tokenKey)
        window.localStorage.removeItem(typeKey)
    }
}

common.getToken = function () {
    let token = window.localStorage.getItem(tokenKey)
    return token == null ? '' : token
}

common.getType = function () {
    let token = window.localStorage.getItem(typeKey)
    return token == null ? '' : token
}


common.getBaseApiUrl = function () {
    return baseApi
}

common.getApiUrl = function (api, param = {}) {
    // console.log(param)
    if (api.startsWith('http')) {
        return api
    }

    let queryArr = []
    for (let k in param) {
        queryArr.push(encodeURIComponent(k) + '=' + encodeURIComponent(param[k]))
    }

    let queryStr = ''
    if (queryArr.length > 0) {
        const mark = api.indexOf('?') >= 0 ? '&' : '?'
        queryStr = mark + queryArr.join('&')
    }

    return common.getBaseApiUrl() + api + queryStr
}

common.ajax = function (method, api, data, config = {}) {

    data = data || {}

    const isGet = method.toLowerCase() === 'get'

    const configDefault = {
        'contentType': 'application/x-www-form-urlencoded', // application/x-www-form-urlencoded、multipart/form-data、application/json
        'timeout': 60000,                  // 调用api超时时间为20秒
        'displayError': true,              // 调用api出错时，是否显示错误消息
        'useToken': true,                  // api是否需要使用token。如果需要token而本地没有token时，将重定向到登录页
        'interceptInvalidToken': true,     // api返回token无效时，是否拦截。如果拦截，将重定向到登录页
    }

    config = Object.assign(configDefault, config)

    let headers = {
        'Content-Type': config['contentType']
    }

    let urlData = {}
    if (config.useToken) {

        urlData.token = common.getToken()
        urlData.type = common.getType()
        // headers['token'] = common.getToken()
    }

    if (!isGet && config['contentType'].toLowerCase() === 'application/x-www-form-urlencoded') {
        data = qs.stringify(isGet ? null : data)
    }
    if (isGet) {
        urlData = {...data, ...urlData}
        data = {}
    }

    return new Promise((resolve, reject) => {

        axios({
            method: method,
            url: common.getApiUrl(api, urlData),
            data: data,
            headers: headers,
            timeout: config.timeout
        }).then((response) => {
            // console.log(response.data)
            if (response.data.code === 'SUCCESS') {
                resolve(response.data.data)
                return
            }

            switch (response.data.code) {
                case 'INVALID_TOKEN':
                    if (config.interceptInvalidToken) {

                        common.setToken(null)

                        if (config.displayError) {

                            common.toast('请登录')
                        }

                        common.redirectToLogin()
                    }
                    break

                default:
                    if (config.displayError) {
                        common.toast(response.data.message)
                    }
            }

            reject(response.data)

        }).catch((error) => {
            config.displayError && common.toast("" + error)
            reject({code: 'ERROR', message: '' + error, data: null})
        })

    })
}


common.alert = function (content, callback, title) {
    title = title || '提示'
    callback = callback || function () {
    }

    Modal.info({
        title: title,
        content: content,
        onOk() {
            callback()
        },
    });

}

common.toast = function (content, callback, duration) {
    duration = duration || 2 // 单位：秒

    callback = callback || function () {
    }

    message.info(content, duration);

    setTimeout(callback, duration * 1000)
}

common.loadingStart = function (message) {
    message = message || '加载中...'

    let mask = document.createElement("div")
    mask.setAttribute("id", "myloadingmask")
    mask.style.position = "absolute"
    mask.style.width = "100%"
    mask.style.height = "100%"
    mask.style.zIndex = 999
    mask.style.top = "0px"
    mask.style.left = "0px"
    document.getElementsByTagName("body")[0].appendChild(mask)

    let container = document.createElement("div")
    container.setAttribute("id", "myloading")
    container.setAttribute("class", "container")

    let loading = document.createElement("div")
    loading.setAttribute("class", "loading")
    container.appendChild(loading)

    let style = document.createElement("style")
    style.innerHTML = `
     .container {
        z-index: 10000;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .loading {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        border: 5px #aaa solid;
        border-right-color: #1890ff;
        animation: loading 1s linear infinite;
    }

    @keyframes loading {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }`

    container.appendChild(style)
    document.getElementsByTagName("body")[0].appendChild(container)

}

common.loadingStop = function () {
    let myloading = document.getElementById("myloading")
    if (myloading) {
        document.getElementsByTagName("body")[0].removeChild(myloading)
    }

    let myloadingmask = document.getElementById("myloadingmask")
    if (myloadingmask) {
        document.getElementsByTagName("body")[0].removeChild(myloadingmask)
    }
}

common.confirm = function (message, okCallback, cancelCallback, title) {
    title = title || '提示'

    okCallback = okCallback || function () {
    }
    cancelCallback = cancelCallback || function () {
    }

    Modal.confirm({
        title: title,
        content: message,
        onOk() {
            okCallback()
        },
        onCancel() {
            cancelCallback()
        },
    });
}


common.getTime = function(key){
    let times = {startTime:'',endTime:''}
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let str_month = month;
    if(str_month < 10){
        str_month = `0${month}`
    }

    if(key == 1){
        times.startTime = `${year}-${str_month}-${day} 00:00:00`;
        times.endTime = `${year}-${str_month}-${day} 23:59:59`;
    }else if(key == 2){
        let lastDay= new Date(year,month,0).getDate()
        times.startTime = `${year}-${str_month}-01 00:00:00`;
        times.endTime = `${year}-${str_month}-${lastDay} 23:59:59`;
    }else if(key == 3){
        let last_month = Math.ceil(month/3) * 3;
        let lastDay= new Date(year,last_month,0).getDate()
        let start_month = last_month - 2;
        if(last_month < 10){
            last_month = `0${last_month}`
        }else if(start_month < 10){
            start_month = `0${start_month}`
        }
        times.startTime = `${year}-${start_month}-01 00:00:00`;
        times.endTime = `${year}-${last_month}-${lastDay} 23:59:59`;
    }else if(key == 4){
        times.startTime = `${year}-01-01 00:00:00`;
        times.endTime = `${year}-12-31 23:59:59`;
    }
    return times;
}


common.sizePdf = function (size) {

    let width = ''
    let height = ''
    let td1 = ''
    let td2 = ''
    let td3 = ''
    let td4 = ''
    let font_size = ''

    if (size == '100') {// 80*50
        width = 80;
        height = 50;
        // td百分比
        td1 = 24;
        td2 = 16;
        td3 = 21;
        td4 = 39;
        font_size = '10px';
    } else if (size == '200') {// 80*60
        width = 80;
        height = 60;

        // td百分比
        td1 = 24;
        td2 = 16;
        td3 = 21;
        td4 = 39;
        font_size = '10px';
    } else if (size == '300') {// 100*50
        width = 100;
        height = 50;

        // td百分比
        td1 = 22;
        td2 = 16;
        td3 = 21;
        td4 = 41;
        font_size = '13px';
    } else if (size == '400') {// 100*60
        width = 100;
        height = 60;

        // td百分比
        td1 = 22;
        td2 = 16;
        td3 = 21;
        td4 = 41;
        font_size = '13px';
    } else if (size == '500') {// 100*70
        width = 100;
        height = 70;

        // td百分比
        td1 = 22;
        td2 = 16;
        td3 = 21;
        td4 = 41;
        font_size = '13px';
    } else if (size == '600') {// 100*80
        width = 100;
        height = 80;

        // td百分比
        td1 = 22;
        td2 = 16;
        td3 = 21;
        td4 = 41;
        font_size = '13px';
    } else if (size == '700') {// 100*100
        width = 100;
        height = 100;

        // td百分比
        td1 = 22;
        td2 = 16;
        td3 = 21;
        td4 = 41;
        font_size = '13px';
    } else if (size == '720') {// 80*80
        width = 80;
        height = 80;

        // td百分比
        td1 = 24;
        td2 = 16;
        td3 = 21;
        td4 = 39;
        font_size = '10px';
    } else if (size == '750') {// 90*45
        width = 90;
        height = 45;

        // td百分比
        td1 = 22;
        td2 = 16;
        td3 = 21;
        td4 = 41;
        font_size = '10px';
    } else if (size == '760') {// 80*55
        width = 80;
        height = 55;

        // td百分比
        td1 = 22;
        td2 = 16;
        td3 = 21;
        td4 = 41;
        font_size = '10px';
    } else if (size == '770') {// 70*40
        width = 70;
        height = 40;

        // td百分比
        td1 = 26;
        td2 = 18;
        td3 = 21;
        td4 = 37;
        font_size = '8px';
    } else if (size == '780') {// 60*50
        width = 60;
        height = 50;

        // td百分比
        td1 = 26;
        td2 = 18;
        td3 = 23;
        td4 = 37;
        font_size = '8px';
    } else if (size == '790') {// 60*40
        width = 60;
        height = 40;

        // td百分比
        td1 = 24;
        td2 = 13;
        td3 = 24;
        td4 = 40;
        font_size = '8px';
    }else if (size == '800') {// 60*40
        width = 76;
        height = 76;

        // td百分比
        td1 = 24;
        td2 = 13;
        td3 = 24;
        td4 = 40;
        font_size = '6px';
    }

    let pdf = {
        width : width,
        height : height,
        // td百分比
        td1 : td1 + "%",
        td2 : td2 + td3 + "%",
        td3 : td4 + "%",
        font_size : font_size
    }

    return pdf;

}

common.replacezifu = function (value) {
    let v = value.trim() 
    // let v = value.replace(/[^\u4e00-\u9fa5a-zA-Z0-9\w]/g,'')
    return v;
}


common.getValueFromEvent = function (e) {
    return e.target.value.replace(/(^\s*)|(\s*$)/g, '');
}

export default common

