import React from 'react';
import "./index.css"
function PaginationBar(props) {
    return (
        <div className='PaginationBar'>
            {props.children}
        </div>
    );
}
export default PaginationBar;
