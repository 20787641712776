import PaginationBar from "../../components/paginationbar";
import React, {useEffect, useState} from 'react'
import {Breadcrumb, Checkbox , Button, Col,Select , Form, Input, Modal, Pagination, Row, Space,Upload} from 'antd'
import common from "../../common"
import Table from "antd/es/table"
import axios from 'axios';
import FromPlate from "../../components/button/FormPlate";
import Exportfile from "../../components/button/Exportfile";
import Importfile from "../../components/button/Importfile";
import Display from "../../components/button/Display";
import {DeleteFilled, EyeOutlined , ToTopOutlined , LineOutlined  , EditFilled, PlusOutlined, ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import { UploadOutlined } from '@ant-design/icons';

var name="公告管理"
function Notice(props) {
    const { TextArea } = Input;
    let initSearch = {}
    let [dataList, setDataList] = useState([])
    let [noticeType, setNoticeType] = useState([])
    let [search, setSearch] = useState(initSearch)       // 搜索内容
    let [query, setQuery] = useState(initSearch);
    const [searchForm] = Form.useForm();
    let [pagination, setPagination] = useState({
        total: 0,
        page: 1,
        limit: 10,
    })
    const {Option} = Select;
    const columns = [
        {
            title: '类型',
            dataIndex: 'noticeTypeName',
            width: 200
        },
        {
            title: '标题',
            dataIndex: 'title',

        },
        {
            title: '关键词',
            dataIndex: 'keyss',

        },
        {
            title: '查看次数',
            dataIndex: 'views',
        },
        {
            title: '红头',
            dataIndex: 'isRed',
            render: (text, record, index) => {
                if(record.isRed == 1){
                    return (
                        <>
                            <div style={{color:'green',fontWeight:'bolder'}}>普通文件</div>
                        </>
                    )
                }else{
                    return (
                        <>
                           <div style={{color:'red',fontWeight:'bolder'}}>红头文件</div>
                        </>
                    )
                }
            }

        },
        {
            title: '操作',
            align: "center",
            render: (text, record, index) => {
                return (
                    <>
                        <Button className="chakan" onClick={() => {
                            window.location = `/#/pdf/${record.id}`
                        }}><EyeOutlined  />查看</Button>
                    </>
                )
            }
        }
    ];
    
    const getDataList = () => {
        let params = {
            ...search,
            page: pagination.page,
            limit: pagination.limit,
        }
        common.ajax("GET", "/passport/wjNotice/list", params).then(data => {
            console.log(data.list);
            setDataList(data.list)
            setPagination(data.pagination)
        })
    }
    // //获取公告类型
    // const getNoticType = () => {
    //     common.ajax("GET", "/passport/wjNoticeType/list").then(data => {
    //         setNoticeType(data.list)
    //         console.log(data.list);
    //     })
    // }
   
    useEffect(() => {
        getDataList()
        // getNoticType()
    }, [pagination.page, pagination.limit,search])
    return (
        <div className="Workshop">
            <Breadcrumb>
                <Breadcrumb.Item>公告管理</Breadcrumb.Item>
                <Breadcrumb.Item>公告列表</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{minHeight: 600}}>
                
                <Form  form={searchForm}  style={{marginTop: 15}} className="searchForm">
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="标题" name="title">
                                <Input placeholder="标题"  onChange={(e) => {
                                    setQuery({...query,title: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="关键字" name="keyss">
                                <Input placeholder="关键字"  onChange={(e) => {
                                    setQuery({...query,keyss: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="内容" name="des">
                                <Input placeholder="内容"  onChange={(e) => {
                                    setQuery({...query,des: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item>
                                <Space>
                                    <Button onClick={() => {
                                        setSearch(query)
                                    }} className='search-btn mybtn'><SearchOutlined />搜索</Button>
                                    <Button onClick={() => {
                                        let emptySearch = {
                                            title:'',
                                            keyss: '',
                                            des: '',
                                        }
                                        searchForm.setFieldsValue(emptySearch)
                                        emptySearch = {}
                                        setSearch(emptySearch)
                                        setQuery(emptySearch)
                                    }} className='clean-btn mybtn'><ReloadOutlined />清空</Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div className='table-box'>
                    <Table rowKey={columns => columns.id} dataSource={dataList} columns={Display(columns)} pagination={false}/>
                </div>
                {pagination.total < 5 ? '' :
                    <PaginationBar>
                        <Pagination
                            style={{marginTop: 25}}
                            pageSizeOptions={['10', '20', '50', '100']}
                            onChange={(page, limit) => {
                                setPagination({...pagination, page, limit})
                            }}
                            onShowSizeChange={(page, limit) => {
                                setPagination({...pagination, page, limit})
                            }}
                            showTotal={total => `共${total}条`}
                            total={pagination.total}
                            defaultCurrent={pagination.page}
                            current={pagination.page}
                            defaultPageSize={pagination.limit}
                            showSizeChanger
                            showQuickJumper
                        /></PaginationBar>}
            </div>
        </div>
    );
}
export default Notice
