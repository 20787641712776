import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PaginationBar from "../../components/paginationbar";
import {Button,DatePicker, Form, Input, Modal, Table, Breadcrumb, Pagination,Row, Col,Switch, Space, Select} from "antd";
import common from "../../common";
import axios from 'axios';
import {
    DeleteFilled, PrinterFilled, PlusOutlined, SearchOutlined, ReloadOutlined, 
    LockFilled, UnlockOutlined, EditFilled
} from '@ant-design/icons';
import './index.less'
function WarePrint(props) {
    let locationQuery = props.location.query

    let initSearch = { dataBase: 'UFDATA_777_2018'}
    let [dataList, setDataList] = useState([])
    let [search, setSearch] = useState(initSearch)       // 搜索内容
    const [searchForm] = Form.useForm();
    let [query, setQuery] = useState(initSearch);
    let [warehouseList,setWarehouseList] = useState([]);
    let [printList,setPrintList] = useState([]);

    let [pagination, setPagination] = useState({
        total: 0,
        page: 1,
        limit: 10,
    })
    const {Option} = Select;
    //----- 弹框
    let [createModalVisible, setCreateModalVisible] = useState(false)
    let [editModalVisible, setEditModalVisible] = useState(false)
    let [createForm] = Form.useForm();
    let [editForm] = Form.useForm();
    const columns = [
        {
            title: '操作',
            align: "center",
            width: 150,
            key: 'name',
            fixed: 'left',
            render: (text, record) => {
                if(record.daohuo==true){
                    return (
                        <>
                            <Button style={{backgroundColor:"#1ab394"}} className="bianji" onClick={() => {
                                // props.history.push('/sendsPrint/' + record.id)
                               
                                let warehouse = query.warehouse;
                                if(warehouse == undefined || warehouse == ''){
                                    common.toast('请选择仓库')
                                    return;
                                }
                                if(printList.length <= 1){
                                    setPrintList([record])
                                }
                                setEditModalVisible(true)

                            }} ><PrinterFilled />打印验收标签</Button>
                        </>
                    )
                }else{
                    return (
                        <>
                            --
                        </>
                    )
                }
                
            }
        },
        {
            title: '收货状态',
            width: 100,
            dataIndex: 'daoHuo',
            key: 'daoHuo',
            fixed: 'left',
            render: (text, record) => {
                if(record.daohuo==true){
                    return (
                        <span style={{color:'#1ab394'}}>
                            已到货
                        </span>
                    )
                }else{
                    return (
                        <span style={{color:'red'}}>
                            未到货
                        </span>
                    )
                }
                
            }
        },
        {
            title: '标签id',
            width: 100,
            dataIndex: 'id',
            key: 'id',
            fixed: 'left',
        },
        {
            title: '到货时间',
            dataIndex: 'cdefine4',
            key: '1',
            width: 130,
        },
        {
            title: '到货数量',
            dataIndex: 'quantity',
            key: '2',
            width: 130,
        },
        {
            title: '厂商编号',
            dataIndex: 'cvencode',
            key: '3',
            width: 130,
        },
        {
            title: '厂商简称',
            dataIndex: 'cvenabbname',
            key: '13',
            width: 130,
        },
        {
            title: '订单号',
            dataIndex: 'cpoid',
            key: '4',
            width: 150,
        },
        {
            title: '图号',
            dataIndex: 'cinvcode',
            key: '5',
            width: 150,
        },
        {
            title: '名称',
            dataIndex: 'cinvname',
            key: '6',
            width: 120,
        },
        {
            title: '单位',
            dataIndex: 'ccomunitname',
            key: '7',
            width: 80,
        },
        {
            title: '规格',
            dataIndex: 'cinvstd',
            key: '8' ,
            width: 150,
        },
        {
            title: '版本号',
            dataIndex: 'cinvdefine1',
            key: '9' ,
            width: 150,
        },
        {
            title: '追溯编号',
            dataIndex: 'checkOrder',
            key: '10' ,
            width: 150,
        },
        {
            title: '司机信息',
            dataIndex: 'sj',
            key: '11' ,
            width: 150,
        },
        {
            title: '物流信息',
            dataIndex: 'wl',
            key: '12' ,
            width: 150,
        },
    ];
    // 获取供应商列表

    const getData = () => {
        let params = {
            ...query,
            page: pagination.page,
            limit: pagination.limit,
        }
        common.loadingStart()
        common.ajax("GET", "/passport/sends/sendload_show", params).then(data => {
            setDataList(data.list)
            setPagination(data.pagination)
            common.loadingStop()
        }).finally(common.loadingStop)
    }
    
    const warehouse = () => {
        let data = {
            dataBase : 'UFDATA_777_2018'
        }
        common.ajax("GET", "/passport/warehouse/list", data).then((res) => {
            setWarehouseList(res)
        })
    }
    const [selectionType, setSelectionType] = useState('checkbox');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setPrintList(selectedRows)
            setSelectedRowKeys(selectedRowKeys);
        },
        // getCheckboxProps: (record) => ({
        //   disabled: record.name === 'Disabled User',
        //   // Column configuration not to be checked
        //   name: record.name,
        // }),
    };

    useEffect(() => {
        getData();
        warehouse();
        setPrintList([])
        setSelectedRowKeys([])
    }, [pagination.page, pagination.limit,search])

    const print = (check_user) => {

        let params = {
            condition : printList,
            checkUser : check_user,
            dataBase : query.dataBase,
            warehouse : query.warehouse
        }
        
        // console.log(params);
        // common.ajax("post", "/passport/printAccept/print", params).then((res) => {
        //     console.log(res);
        // })

        let urlData = {
            token : common.getToken(),
            type : common.getType()
        }
       
        axios({
            headers: {'Content-Type': 'application/json'},
            method: 'POST',
            url: common.getApiUrl("/passport/printAccept/print", urlData),
            data: params,
            contentType: "application/json;charset=UTF-8",
            timeout: 20000
        }).then((response) => {
            if (response.data.code === 'SUCCESS') {
                setEditModalVisible(false)
                setSelectedRowKeys([])
                common.toast(response.data.message)
            }
        }).catch((error) => {
            common.toast("" + error)
        })
    }

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>打印管理</Breadcrumb.Item>
                <Breadcrumb.Item>到货标签打印</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{minHeight: 360}}>
                
                <Form form={searchForm} style={{marginTop: 15}} className='searchForm'>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item name="dataBase">
                                <Select placeholder="账套" defaultValue='UFDATA_777_2018' showSearch onChange={(value)=>{
                                    setQuery({...query, dataBase: value})
                                }}>
                                    <Option value="UFDATA_777_2018">UFDATA_777_2018</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="cvencode" getValueFromEvent={common.getValueFromEvent}>
                                <Input placeholder="厂商编号"  onChange={(e) => {
                                    let value = common.replacezifu(e.target.value)
                                    setQuery({...query,cvencode: value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="cpoid" getValueFromEvent={common.getValueFromEvent}>
                                <Input placeholder="订单号"  onChange={(e) => {
                                    let value = common.replacezifu(e.target.value)
                                    setQuery({...query,cpoid: value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="cinvcode" getValueFromEvent={common.getValueFromEvent}>
                                <Input placeholder="图号"  onChange={(e) => {
                                    let value = common.replacezifu(e.target.value)
                                    setQuery({...query,cinvcode: value})
                                }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item name="warehouse">
                                <Select placeholder="仓库" showSearch onChange={(value)=>{
                                    setQuery({...query, warehouse: value})
                                }}>
                                    <Option value=''>请选择仓库</Option>
                                    {
                                        warehouseList.map((item,key) => {
                                            return (<Option key={key} value={item.cwhName}>[{item.cwhCode}]{item.cwhName}</Option>)
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item  name="startTime">
                                <DatePicker onChange={(value)=>{
                                    let time = value ? moment(value).format('YYYY-MM-DD 00:00:00') : ''
                                    setQuery({...query, startTime: time})
                                }} placeholder="制单开始时间" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item  name="endTime">
                                <DatePicker onChange={(value)=>{
                                    let time = value ? moment(value).format('YYYY-MM-DD 00:00:00') : ''
                                    setQuery({...query, endTime: time})
                                }} placeholder="制单结束时间" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item>
                                <Space>
                                    <Button onClick={() => {
                                        pagination.page = 1
                                        setSearch(query)
                                    }} className='search-btn mybtn'><SearchOutlined />搜索</Button>
                                    <Button onClick={() => {
                                        let emptySearch = {
                                            cpoid:'',
                                            cinvcode:'',
                                            startTime:'',
                                            endTime:'',
                                            warehouse : ''
                                        }
                                        let empty = { dataBase:query.dataBase }
                                        searchForm.setFieldsValue(emptySearch)
                                        setSearch(empty)
                                        setQuery(empty)
                                    }} className='clean-btn mybtn'><ReloadOutlined />清空</Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div className='table-box'>
                    {/* <Button type="primary" onClick={() => {
                        setCreateModalVisible(true)
                        createForm.setFieldsValue({title: ""})
                    }} className='add-btn mybtn'><PlusOutlined />新增</Button> */}
                    <Table rowKey={(columns,index) => index} 
                        dataSource={dataList} columns={columns} pagination={false} scroll={{ x: 1600 }}
                        rowSelection={{
                            type: selectionType,
                            ...rowSelection,
                        }}
                        rowClassName={(record, idx) => {
                            if(idx % 2 === 1)
                                return 'bg-row';
                        }}/>
                </div>
                
                {pagination.total < 5 ? '' :
                    <PaginationBar>
                        <Pagination
                            style={{marginTop: 25}}
                            pageSizeOptions={['10', '20', '50', '100']}
                            onChange={(page, limit) => {
                                setPagination({...pagination, page, limit})
                            }}
                            onShowSizeChange={(page, limit) => {
                                setPagination({...pagination, page, limit})
                            }}
                            showTotal={total => `共${total}条`}
                            total={pagination.total}
                            defaultCurrent={pagination.page}
                            current={pagination.page}
                            defaultPageSize={pagination.limit}
                            showSizeChanger
                            showQuickJumper
                        /></PaginationBar>}
            </div>
            <Modal title="检验员" footer={false} maskClosable={false} visible={editModalVisible} onCancel={() => {
                setEditModalVisible(false)
            }}
            >
                <Form
                    labelCol={{span: 4}}
                    wrapperCol={{span: 20}}
                    form={editForm}
                    onFinish={(e) => {
                        print(e.check_user)
                    }}
                >
                    <Form.Item
                        label="检验员"
                        name="check_user"
                        rules={[{required: true, message: '请填写检验员'}]}
                        getValueFromEvent={common.getValueFromEvent}
                    >
                        <Input placeholder='请填写检验员'/>
                    </Form.Item>
                    <Form.Item wrapperCol={{offset: 4, span: 20}}>
                        <Button type="primary" htmlType="submit">
                            确认
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}
export default WarePrint;