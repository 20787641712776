import React, {useEffect, useState} from 'react'
import {
    Pagination, Breadcrumb, Button, Space, Input, Form, Row, Col, Modal, Checkbox,Table,Select
} from 'antd'
import {
    DeleteFilled,
    EditFilled,
    EyeInvisibleOutlined,
    EyeTwoTone,
    InfoCircleOutlined,
    PlusOutlined, ReloadOutlined, SearchOutlined
} from '@ant-design/icons';
import "./index.less"
import common from "../../common"
import moment from 'moment'
import PaginationBar from "../../components/paginationbar";
function User(props) {
    let initSearch = {}
    const [newsList, setNewsList] = useState([])
    let [query, setQuery] = useState(initSearch);
    const [searchForm] = Form.useForm();
    let [search, setSearch] = useState({})       //搜索内容

    let [pagination, setPagination] = useState({
        total: 0,
        page: 1,
        limit: 10,
    })
    const {Option} = Select;
    const [createModalVisible, setCreateModalVisible] = useState(false)
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [createForm] = Form.useForm();
    const [editForm] = Form.useForm();
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: '用户名',
            dataIndex: 'userName',
        },
        {
            title: '姓名',
            dataIndex: 'realName',
        },
        {
            title: '手机号',
            dataIndex: 'mobile',
        },

        {
            title: '邮箱',
            dataIndex: 'emails',
        },
        // {
        //     title: '是否禁用',
        //     dataIndex: 'isShow',
        // },
        {
            title: '操作',
            align: "center",
            width: 180,
            fixed: "right",
            render: (text, record, index) => {
                return (
                    <>
                        <Button className="bianji" size={'small'} onClick={() => {
                            setEditModalVisible(true)
                            editForm.setFieldsValue(record)
                        }}><EditFilled  />编辑</Button>
                        <Button  className="shanchu" size={'small'}onClick={() => {
                            common.confirm("确定要删除吗?", () => {
                                del(record.id)
                            })
                        }}><DeleteFilled  />删除</Button>
                    </>
                )
            }
        }
    ];
    const init = () => {
        let params = {
            page: pagination.page,
            limit: pagination.limit,
            ...search
        }
        common.loadingStart()
        common.ajax("GET", "/passport/user/list", params).then(data => {
            console.log(data.list);
            setNewsList(data.list)
            setPagination(data.pagination)
            common.loadingStop()
        })
    }
    
    const create = (data) => {
        common.ajax("POST", "/passport/user/create", data).then(res => {
            common.toast("操作成功")
            setCreateModalVisible(false)
            init()
        })
    }
    const update = (data) => {
        console.log(data);
        common.ajax("POST", "/passport/user/update", data).then(res => {
            common.toast("操作成功")
            setEditModalVisible(false)
            init()
        })
    }
    const del = (id) => {
        common.ajax("POST", "/passport/user/delete", {id}).then(res => {
            common.toast("操作成功")
            init()
        })
    }
    useEffect(() => {
        init()
    }, [pagination.page, pagination.limit, search])

    
    return (
        <div className="User">
            <Breadcrumb>
                <Breadcrumb.Item>员工管理</Breadcrumb.Item>
                <Breadcrumb.Item>员工列表</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{minHeight: 600}}>
                
                <Form form={searchForm} style={{marginTop: 15}} className='searchForm'>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="用户名" name="userName" getValueFromEvent={common.getValueFromEvent}>
                                <Input placeholder="用户名"/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item>
                                <Space>
                                    <Button onClick={() => {
                                        setSearch(searchForm.getFieldsValue())
                                        pagination.page = 1
                                    }} className='search-btn mybtn'><SearchOutlined />搜索</Button>

                                    <Button onClick={() => {

                                        let emptySearch = {
                                            userName: '',
                                        }
                                        searchForm.setFieldsValue(emptySearch)
                                        setSearch(emptySearch)

                                    }} className='clean-btn mybtn'><ReloadOutlined />清空</Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div className='table-box'>
                    <Button type="primary" onClick={() => {
                        setCreateModalVisible(true)
                        createForm.setFieldsValue({
                            userName : '',
                            realName : '',
                            mobile : '',
                            emails : '', 
                            password:'123456'
                        })
                    }} className='add-btn mybtn'><PlusOutlined />新增</Button>
                    <Table rowKey={columns => columns.id} dataSource={newsList} columns={columns} pagination={false}/>
                </div>
                {pagination.total < 5 ? '' :
                    <PaginationBar>
                    <Pagination
                        style={{marginTop: 25}}
                        pageSizeOptions={['10', '20', '50', '100']}
                        onChange={(page, limit) => {
                            setPagination({...pagination, page, limit})
                        }}
                        onShowSizeChange={(page, limit) => {
                            setPagination({...pagination, page, limit})
                        }}
                        showTotal={total => `共${total}条`}
                        total={pagination.total}
                        defaultCurrent={pagination.page}
                        current={pagination.page}
                        defaultPageSize={pagination.limit}
                        showSizeChanger
                        showQuickJumper
                    /></PaginationBar>}
            </div>
            <Modal title="新增" footer={false} maskClosable={false} visible={createModalVisible} onCancel={() => {
                setCreateModalVisible(false)
            }}
            >
                <Form
                    form={createForm}
                    onFinish={(values) => {
                        create(values)
                    }}
                    labelCol={{span: 4}}
                    wrapperCol={{span: 20}}
                >
                    <Form.Item
                        label="用户名"
                        name="userName"
                        rules={[{required: true, message: '请输入用户名'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="姓名"
                        name="realName"
                        rules={[{required: true, message: '请输入姓名'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="密码"
                        name="password"
                        rules={[{ required: true, message: '请输入密码' }]}
                    >
                        <Input.Password iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                    </Form.Item>
                    <Form.Item
                        label="手机号"
                        name="mobile"
                        rules={[{ required: false, message: "" },
                        {
                            validator: async (_, mobileNumber) => {
                                if (mobileNumber != null && mobileNumber != '' && !/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(mobileNumber)) {
                                    return Promise.reject(new Error('手机号码格式不正确'));
                                }
                            }
                        },]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="邮箱"
                        name="emails"
                        rules={[{ required: false, message: "" },
                        {
                            validator: async (_, mobileNumber) => {
                                if (mobileNumber != null && mobileNumber != '' && !/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(mobileNumber)) {
                                    return Promise.reject(new Error('邮箱格式不正确'));
                                }
                            }
                        },]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item wrapperCol={{offset: 4, span: 20}}>
                        <Button type="primary" htmlType="submit">
                            保存
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal title="编辑" footer={false} maskClosable={false} visible={editModalVisible} onCancel={() => {
                setEditModalVisible(false)
            }}
            >
                <Form
                    form={editForm}
                    onFinish={(values) => {
                        console.log(values)
                        update(values)
                    }}
                    labelCol={{span: 4}}
                    wrapperCol={{span: 20}}
                >
                    <Form.Item
                        hidden={true}
                        name="id"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="用户名"
                        name="userName"
                        // rules={[{required: true, message: '请输入用户名'}]}
                    >
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item
                        label="姓名"
                        name="realName"
                        rules={[{required: true, message: '请输入姓名'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="手机号"
                        name="mobile"
                        rules={[{ required: false, message: "" },
                        {
                            validator: async (_, mobileNumber) => {
                                if (mobileNumber != null && mobileNumber != '' && !/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(mobileNumber)) {
                                    return Promise.reject(new Error('手机号码格式不正确'));
                                }
                            }
                        },]}
                    >
                        <Input />
                    </Form.Item>
                    
                    <Form.Item
                        label="邮箱"
                        name="emails"
                        rules={[{ required: false, message: "" },
                        {
                            validator: async (_, mobileNumber) => {
                                if (mobileNumber != null && mobileNumber != '' && !/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(mobileNumber)) {
                                    return Promise.reject(new Error('邮箱格式不正确'));
                                }
                            }
                        },]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item wrapperCol={{offset: 4, span: 20}}>
                        <Button type="primary" htmlType="submit">
                            保存
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}
export default User;
