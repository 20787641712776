import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PaginationBar from "../../components/paginationbar";
import {Button,DatePicker, Form, Input, Modal, Table, Breadcrumb, Pagination,Row, Col,Switch, Space, Select} from "antd";
import common from "../../common";
import {
    DeleteFilled, PrinterFilled, PlusOutlined, SearchOutlined, ReloadOutlined, 
    LockFilled, UnlockOutlined, EditFilled
} from '@ant-design/icons';
function Sends(props) {
    let locationQuery = props.location.query

    let initSearch = {}
    let [dataList, setDataList] = useState([])
    let [search, setSearch] = useState(initSearch)       // 搜索内容
    const [searchForm] = Form.useForm();
    let [query, setQuery] = useState(initSearch);
    let [pagination, setPagination] = useState({
        total: 0,
        page: 1,
        limit: 10,
    })
    const {Option} = Select;
    //----- 弹框
    let [createModalVisible, setCreateModalVisible] = useState(false)
    let [editModalVisible, setEditModalVisible] = useState(false)
    let [createForm] = Form.useForm();
    let [editForm] = Form.useForm();
    const columns = [
        {
            title: '操作',
            align: "center",
            width: 150,
            key: 'name',
            fixed: 'left',
            render: (text, record) => {
                var k;
                if(type=="1"){
                    k= <Button className="shanchu" onClick={() => {
                        common.confirm("确定要删除吗?", () => {
                            deleteData(record.id);
                        })
                    }}><DeleteFilled />删除</Button>
                }
                return (
                    <>
                        <Button style={{backgroundColor:"#1ab394"}} className="bianji" onClick={() => {
                            props.history.push('/sendsPrint/' + record.id)
                        }} ><PrinterFilled />打印</Button>
                        {k}
                    </>
                )
            }
        },
        {
            title: '标签id',
            width: 100,
            dataIndex: 'id',
            key: 'id',
            fixed: 'left',
        },
        {
            title: '发货日期',
            dataIndex: 'sdate',
            key: '1',
            width: 130,
        },
        {
            title: '发货数量',
            dataIndex: 'iquantity',
            key: '2',
            width: 130,
        },
        {
            title: '厂商编号',
            dataIndex: 'cvencode',
            key: '3',
            width: 130,
        },
        {
            title: '厂商简称',
            dataIndex: 'cvenabbname',
            key: '13',
            width: 130,
        },
        {
            title: '订单号',
            dataIndex: 'cpoid',
            key: '4',
            width: 150,
        },
        {
            title: '图号',
            dataIndex: 'cinvcode',
            key: '5',
            width: 150,
        },
        {
            title: '名称',
            dataIndex: 'cinvname',
            key: '6',
            width: 120,
        },
        {
            title: '单位',
            dataIndex: 'ccomunitname',
            key: '7',
            width: 80,
        },
        {
            title: '规格',
            dataIndex: 'cinvstd',
            key: '8' ,
            width: 150,
        },
        {
            title: '版本号',
            dataIndex: 'cinvdefine1',
            key: '9' ,
            width: 150,
        },
        {
            title: '追溯编号',
            dataIndex: 'checkOrder',
            key: '10' ,
            width: 150,
        },
        {
            title: '司机信息',
            dataIndex: 'sj',
            key: '11' ,
            width: 150,
        },
        {
            title: '物流信息',
            dataIndex: 'wl',
            key: '12' ,
            width: 150,
        },
    ];
    // 获取供应商列表

    const getOrders = () => {
        var cvencode;
        var type=window.localStorage.getItem("type")
        if(type=="2"){
            cvencode=JSON.parse(window.localStorage.getItem("user")).cvencode;
        }else {
            cvencode= ''
        }
        // common.loadingStart()
        let params = {
            cvencode:cvencode,
            ...common.removeNull(search),
            page: pagination.page,
            limit: pagination.limit,
        }

        if(locationQuery){
            let cpoid = locationQuery.cpoid
            let cinvcode = locationQuery.cinvcode
            let sid = locationQuery.sid
            params.cpoid = cpoid 
            params.cinvcode = cinvcode 
            params.sid = sid 
            searchForm.setFieldsValue({cpoid:cpoid,cinvcode:cinvcode})
        }
        common.loadingStart()
        common.ajax("GET", "/passport/sends/list", params).then(data => {
            setDataList(data.list)
            setPagination(data.pagination)
        }).finally(common.loadingStop)
    }
    const create = (data) => {
        common.loadingStart()
        common.ajax("POST", "/passport/vendor/create", data).then(() => {
            common.toast("操作成功")
            setCreateModalVisible(false)
            getOrders()
        }).finally(common.loadingStop)
    }
    
    const deleteData = (id) => {
        common.loadingStart()
        common.ajax("POST", "/passport/sends/delete", {id}).then(() => {
            common.toast("操作成功")
            getOrders()
            common.loadingStop()
        })
    }
    const update = (data) => {
        common.loadingStart()
        common.ajax("POST", "/passport/vendor/update", data).then(() => {
            common.toast("操作成功")
            setEditModalVisible(false)
            getOrders()
        }).finally(common.loadingStop)
    }
    const stop = (cvenmnemcode) => {
        common.loadingStart()
        common.ajax("POST", "/passport/vendor/stop", {cvenmnemcode}).then(() => {
            common.toast("操作成功")
            getOrders()
        }).finally(common.loadingStop)
    }
    const start = (cvenmnemcode) => {
        common.loadingStart()
        common.ajax("POST", "/passport/vendor/start", {cvenmnemcode}).then(() => {
            common.toast("操作成功")
            getOrders()
        }).finally(common.loadingStop)
    }
    useEffect(() => {
        getOrders()
    }, [pagination.page, pagination.limit,search])

    var ll;
    var type=window.localStorage.getItem("type")
    if(type=="1"){
        ll=<Col span={4}> <Form.Item name="cvencode" getValueFromEvent={common.getValueFromEvent}>
            <Input placeholder="厂商编号"  onChange={(e) => {
                let value = common.replacezifu(e.target.value)
                setQuery({...query,cvencode: value})
            }}/>
        </Form.Item></Col>
    }
    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>订单管理</Breadcrumb.Item>
                <Breadcrumb.Item>发货记录</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{minHeight: 360}}>
                
                <Form form={searchForm} style={{marginTop: 15}} className='searchForm'>
                    <Row gutter={24}>
                        {ll}
                        <Col span={4}>
                            <Form.Item name="cpoid" getValueFromEvent={common.getValueFromEvent}>
                                <Input placeholder="订单号"  onChange={(e) => {
                                    let value = common.replacezifu(e.target.value)
                                    setQuery({...query,cpoid: value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="startTime">
                                
                                <DatePicker onChange={(value)=>{
                                    let time = value ? moment(value).format('YYYY-MM-DD 00:00:00') : ''
                                    setQuery({...query, startTime: time})
                                }} placeholder="制单开始时间"/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item  name="endTime">
                                <DatePicker onChange={(value)=>{
                                    let time = value ? moment(value).format('YYYY-MM-DD 00:00:00') : ''
                                    setQuery({...query, endTime: time})
                                }} placeholder="制单结束时间" />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="cinvcode" getValueFromEvent={common.getValueFromEvent}>
                                <Input placeholder="图号"  onChange={(e) => {
                                    let value = common.replacezifu(e.target.value)
                                    setQuery({...query,cinvcode: value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item>
                                <Space>
                                    <Button onClick={() => {
                                        setSearch(query)
                                    }} className='search-btn mybtn'><SearchOutlined />搜索</Button>
                                    <Button onClick={() => {
                                        props.location.query = null
                                        let emptySearch = {
                                            cpoid:'',
                                            cinvcode:'',
                                            startTime:'',
                                            endTime:'',
                                            cvencode :''
                                        }
                                        searchForm.setFieldsValue(emptySearch)
                                        setSearch(emptySearch)
                                        setQuery(emptySearch)
                                    }} className='clean-btn mybtn'><ReloadOutlined />清空</Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div className='table-box'>
                    {/* <Button type="primary" onClick={() => {
                        setCreateModalVisible(true)
                        createForm.setFieldsValue({title: ""})
                    }} className='add-btn mybtn'><PlusOutlined />新增</Button> */}
                    <Table rowKey={(columns,index) => index} 
                        dataSource={dataList} columns={columns} pagination={false} scroll={{ x: 1600 }}
                        rowClassName={(record, idx) => {
                            if(idx % 2 === 1)
                                return 'bg-row';
                        }}/>
                </div>
                <Modal title="新增" destroyOnClose={true} footer={false} maskClosable={false} visible={createModalVisible} onCancel={() => {
                    setCreateModalVisible(false)
                }}
                >
                    <Form preserve={false}
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    form={createForm}
                    onFinish={(values) => {
                        create(values)
                    }}
                >
                    <Form.Item label="名称" name="cvenname" rules={[{required: true, message: '请填写名称'}]}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item label="ERP编号" name="cvencode" rules={[{required: true, message: '请填写ERP编号'}]}>
                         <Input disabled />
                    </Form.Item>
                    <Form.Item label="登录账号" name="cvenmnemcode" rules={[{required: true, message: '请填写登录账号'}]}>
                         <Input disabled />
                    </Form.Item>
                    <Form.Item label="密码" name="pd" rules={[{required: true, message: '请填写密码'}]}>
                         <Input/>
                    </Form.Item>
                    <Form.Item label="主邮箱" name="email" rules={[{required: true, message: '请填主邮箱'}]}>
                         <Input/>
                    </Form.Item>
                    <Form.Item label="附邮箱" name="email2" rules={[{required: false, message: '请填主邮箱'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="备用邮箱" name="email3" rules={[{required: false, message: '请填主邮箱'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="备用邮箱" name="email4" rules={[{required: false, message: '请填主邮箱'}]}>
                         <Input/>
                    </Form.Item>
                    <Form.Item wrapperCol={{offset: 8, span: 16}}>
                        <Button type="primary" htmlType="submit">
                            保存
                        </Button>
                    </Form.Item>
                </Form>
                </Modal>
                <Modal title="编辑供应商信息" footer={false} maskClosable={false} destroyOnClose={true}  visible={editModalVisible} onCancel={() => {
                    setEditModalVisible(false)
                }}
                >
                    <Form
                        labelCol={{span: 5}}
                        wrapperCol={{span: 18}}
                        form={editForm}
                        onFinish={(values) => {
                            update(values)
                        }}
                    >
                        <Form.Item label="名称" name="cvenname" rules={[{required: true, message: '请填写名称'}]}>
                            <Input disabled />
                        </Form.Item>
                        <Form.Item label="ERP编号" name="cvencode" rules={[{required: true, message: '请填写ERP编号'}]}>
                            <Input disabled />
                        </Form.Item>
                        <Form.Item label="登录账号" name="cvenmnemcode" rules={[{required: true, message: '请填写登录账号'}]}>
                            <Input disabled />
                        </Form.Item>
                        <Form.Item label="密码" name="pd" rules={[{required: true, message: '请填写密码'}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="主邮箱" name="email" rules={[{required: true, message: '请填主邮箱'}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="附邮箱" name="email2" rules={[{required: false, message: '请填主邮箱'}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="备用邮箱" name="email3" rules={[{required: false, message: '请填主邮箱'}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="备用邮箱" name="email4" rules={[{required: false, message: '请填主邮箱'}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item wrapperCol={{offset: 10, span: 14}}>
                            <Button type="primary" htmlType="submit">
                                保存
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
                {pagination.total < 5 ? '' :
                    <PaginationBar>
                        <Pagination
                            style={{marginTop: 25}}
                            pageSizeOptions={['10', '20', '50', '100']}
                            onChange={(page, limit) => {
                                setPagination({...pagination, page, limit})
                            }}
                            onShowSizeChange={(page, limit) => {
                                setPagination({...pagination, page, limit})
                            }}
                            showTotal={total => `共${total}条`}
                            total={pagination.total}
                            defaultCurrent={pagination.page}
                            current={pagination.page}
                            defaultPageSize={pagination.limit}
                            showSizeChanger
                            showQuickJumper
                        /></PaginationBar>}
            </div>
        </div>
    );
}
export default Sends;