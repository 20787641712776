import PaginationBar from "../../components/paginationbar";
import React, {useEffect, useState} from 'react'
import {Breadcrumb, Button, Col,Select , Form, Input, Modal, Pagination, Row, Space,} from 'antd'
import common from "../../common"
import Table from "antd/es/table"
import FromPlate from "../../components/button/FormPlate";
import Exportfile from "../../components/button/Exportfile";
import Importfile from "../../components/button/Importfile";
import Display from "../../components/button/Display";
import {DeleteFilled, EditFilled, PlusOutlined, ReloadOutlined, SearchOutlined} from "@ant-design/icons";
var name="公告类型列表"
function NoticeType(props) {
    let initSearch = {}
    let [dataList, setDataList] = useState([])
    let [workshopList, setWorkshopList] = useState([])
    let [factory, setFactory] = useState([])
    let [search, setSearch] = useState(initSearch)       // 搜索内容
    let [query, setQuery] = useState(initSearch);
    const [searchForm] = Form.useForm();
    let [pagination, setPagination] = useState({
        total: 0,
        page: 1,
        limit: 10,
    })
    const {Option} = Select;
    let [createModalVisible, setCreateModalVisible] = useState(false)
    let [editModalVisible, setEditModalVisible] = useState(false)
    let [createForm] = Form.useForm();
    let [editForm] = Form.useForm();
    let [users, setUsers] = useState([]);
    const columns = [
        {
            title: '类型',
            dataIndex: 'title',
            width: 200
        },
        {
            title: '描述',
            dataIndex: 'description',

        },
        {
            title: '操作',
            align: "center",
            render: (text, record, index) => {
                return (
                    <>
                        <Button className="bianji" onClick={() => {
                            setEditModalVisible(true)
                            editForm.setFieldsValue(record)
                        }}><EditFilled  />编辑</Button>
                        <Button  className="shanchu" onClick={() => {
                            common.confirm("确定要删除吗?", () => {
                                del(record.id)
                            })
                        }}><DeleteFilled  />删除</Button>
                    </>
                )
            }
        }
    ];
    // 获取车间列表
    const getWorkshops = () => {
        let params = {
            ...search,
            page: pagination.page,
            limit: pagination.limit,
        }
        common.ajax("GET", "/passport/wjNoticeType/list", params).then(data => {
            setDataList(data.list)
            setPagination(data.pagination)
        })
    }
    const create = (data) => {
        common.loadingStart()
        common.ajax("POST", "/front/workshop/create", data).then(res => {
            common.toast("添加成功")
            setCreateModalVisible(false)
            getWorkshops()
        }).finally(common.loadingStop)
    }
    const update = (data) => {
        common.loadingStart()
        common.ajax("POST", "/front/workshop/update", data).then(res => {
            common.toast("修改成功")
            setEditModalVisible(false)
            getWorkshops()
        }).finally(common.loadingStop)
    }
    const del = (id) => {
        common.loadingStart()
        common.ajax("POST", "/front/workshop/delete", {id}).then(res => {
            common.toast("删除成功")
            getWorkshops()
        }).finally(common.loadingStop)
    }
    useEffect(() => {
        getWorkshops()
    }, [pagination.page, pagination.limit,search])
    return (
        <div className="Workshop">
            <Breadcrumb>
                <Breadcrumb.Item>公告管理</Breadcrumb.Item>
                <Breadcrumb.Item>公告类型列表</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{minHeight: 600}}>
                
                <Form  form={searchForm}  style={{marginTop: 15}} className="searchForm">
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="名称" name="name">
                                <Input placeholder="名称"  onChange={(e) => {
                                    setQuery({...query,title: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item>
                                <Space>
                                    <Button onClick={() => {
                                        setSearch(query)
                                    }} className='search-btn mybtn'><SearchOutlined />搜索</Button>
                                    <Button onClick={() => {
                                        let emptySearch = {
                                            factoryId:'',
                                            name: '',
                                            userId: '',
                                        }
                                        searchForm.setFieldsValue(emptySearch)
                                        emptySearch = {}
                                        setSearch(emptySearch)
                                        setQuery(emptySearch)
                                    }} className='clean-btn mybtn'><ReloadOutlined />清空</Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div className='table-box'>
                    <Button type="primary" onClick={() => {
                        setCreateModalVisible(true)
                        createForm.setFieldsValue({title: ""})
                    }} className='add-btn mybtn'><PlusOutlined />新增</Button>
                    {/* <FromPlate columns={columns} name={name}/> */}
                    {/* <Importfile method="POST" api="/front/workshop/create" columns={columns} name={name} /> */}
                    {/* <Exportfile method="GET" api="/front/workshop/list" query={search} dataList={dataList} columns={columns} name={name}/> */}
                    <Table rowKey={columns => columns.id} dataSource={dataList} columns={Display(columns)} pagination={false}/>
                </div>
                {pagination.total < 5 ? '' :
                    <PaginationBar>
                        <Pagination
                            style={{marginTop: 25}}
                            pageSizeOptions={['10', '20', '50', '100']}
                            onChange={(page, limit) => {
                                setPagination({...pagination, page, limit})
                            }}
                            onShowSizeChange={(page, limit) => {
                                setPagination({...pagination, page, limit})
                            }}
                            showTotal={total => `共${total}条`}
                            total={pagination.total}
                            defaultCurrent={pagination.page}
                            current={pagination.page}
                            defaultPageSize={pagination.limit}
                            showSizeChanger
                            showQuickJumper
                        /></PaginationBar>}
            </div>
            <Modal title="新增" destroyOnClose={true} footer={false} maskClosable={false} visible={createModalVisible}
                   onCancel={() => {
                       setCreateModalVisible(false)
                   }}
            >
                <Form preserve={false}
                      labelCol={{span: 4}}
                      wrapperCol={{span: 20}}
                      form={createForm}
                      onFinish={(values) => {
                          create(values)
                      }}
                >
                    <Form.Item
                        label="车间名称"
                        name="name"
                        rules={[{required: true, message: '请输入车间名称'}]}
                    >
                        <Input placeholder="车间名称"/>
                    </Form.Item>

                    <Form.Item
                        label="所属工厂"
                        name="factoryId"
                        rules={[{required: true, message: '请选择工厂'}]}
                    >
                        <Select  placeholder="所属工厂" >
                            {factory.map(item => {
                                return (<Option key={item.id} value={item.id} > {item.name}</Option>)
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="负责人"
                        name="userId"
                        rules={[{required: true, message: '请选择负责人'}]}
                    >
                        <Select  placeholder="姓名" >
                            {users.map(item => {
                                return (<Option key={item.id} value={item.id} > {item.name}</Option>)
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item wrapperCol={{offset: 4, span: 20}}>
                        <Button type="primary" htmlType="submit">
                            保存
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal title="编辑" footer={false} maskClosable={false} visible={editModalVisible} onCancel={() => {
                setEditModalVisible(false)
            }}
            >
                <Form
                    labelCol={{span: 4}}
                    wrapperCol={{span: 20}}
                    form={editForm}
                    onFinish={(values) => {
                        update(values)
                    }}
                >
                    <Form.Item
                        hidden={true}
                        name="id"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="车间名字"
                        name="name"
                        rules={[{required: true, message: '请输入车间名字'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="所属工厂"
                        name="factoryId"
                        rules={[{required: true, message: '请选择工厂'}]}
                    >
                        <Select  placeholder="所属工厂" >
                            {factory.map(item => {
                                return (<Option key={item.id} value={item.id} > {item.name}</Option>)
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="负责人"
                        name="userId"
                        rules={[{required: true, message: '请输入负责人姓名'}]}
                    >
                        <Select  placeholder="姓名" >
                            {users.map(item => {
                                return (<Option key={item.id} value={item.id} > {item.name}</Option>)
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item wrapperCol={{offset: 4, span: 20}}>
                        <Button type="primary" htmlType="submit">
                            保存
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}
export default NoticeType
