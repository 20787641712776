import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import PaginationBar from "../../components/paginationbar";
import {Button,DatePicker, Form, Input, Modal, Table, Breadcrumb, Pagination,Row, Col,Switch, Space, Select} from "antd";
import common from "../../common";
import {
    EyeOutlined, FilePdfOutlined, PlusOutlined, SearchOutlined, ReloadOutlined, DeleteFilled, PrinterFilled
} from '@ant-design/icons';
function Popodetails(props) {
    let initSearch = {}
    let [dataList, setDataList] = useState([])
    let [search, setSearch] = useState(initSearch)       // 搜索内容
    const [searchForm] = Form.useForm();
    let [query, setQuery] = useState(initSearch);
    let [pagination, setPagination] = useState({
        total: 0,
        page: 1,
        limit: 10,
    })
    const {Option} = Select;
    
    //----- 弹框
    let [createModalVisible, setCreateModalVisible] = useState(false)
    let [editModalVisible, setEditModalVisible] = useState(false)
    let [createForm] = Form.useForm();
    let [editForm] = Form.useForm();

    const columns = [
        {
            title: '操作',
            align: "center",
            width: 80,
            key: 'name',
            fixed: 'left',
            render: (text, record) => {
                var k;
                if(record.sendstate==1){
                    k=<span onClick={()=>{
                        props.history.push('/popodetailsSave/' + record.sid)
                    }} style={{backgroundColor:"#1ab394",
                        boxShadow:"0px 0px 2px #888888",
                        borderRadius:"3px",
                        fontSize:"12px",
                        color:"#FFF",
                        padding:"1.5px 6px",
                        display:"inline-block",
                        cursor:"pointer"
                    }}>发货</span>
                }else {
                    k="---"
                }
                return (
                    <>
                        {k}
                    </>
                )
            }
        },
        {
            title: '发货状态',
            width: 90,
            key: 'id',
            fixed: 'left',
            render: (text, record) => {
                var k;
                if(record.sendstate==1){
                    k=<span style={{backgroundColor:"#f8ac59",boxShadow:"0px 0px 2px #888888",borderRadius:"3px",fontSize:"12px",color:"#FFF",padding:"1.5px 6px",display:"inline-block"}}>未完成</span>
                }else  if(record.sendstate==2){
                    k=<span style={{backgroundColor:"#1c84c6",boxShadow:"0px 0px 2px #888888",borderRadius:"3px",fontSize:"12px",color:"#FFF",padding:"1.5px 6px",display:"inline-block"}}>已完成</span>
                }else  if(record.sendstate==0){
                    // k=<div style={{backgroundColor:"#f8ac59",color:"#ffffff",textAlign:"center"}}>未发货</div>
                }
                return (
                    <>
                        {k}
                    </>
                )
            }
        },
        {
            title: '已送数量',
            dataIndex: 'send',
            key: '1',
            width: 100,
        },
        {
            title: '订单总数',
            dataIndex: 'iquantity',
            key: '2',
            width: 100,
        },
        {
            title: '订单累计到货',
            dataIndex: 'iarrqty',
            key: '3',
            width: 120,
        },
        {
            title: '发货记录',
            key: '4',
            width: 100,
            render: (text, record) => {

                return (
                    <>
                        <Link to={{pathname:'/sends',
                            query:{
                                cpoid : record.cpoid , 
                                cinvcode : record.cinvcode,
                                sid : record.sid}}} >
                                    <span style={{backgroundColor:"#1ab394",boxShadow:"0px 0px 2px #888888",borderRadius:"3px",fontSize:"12px",color:"#FFF",padding:"1.5px 6px",display:"inline-block"}}><EyeOutlined/>查看</span>
                                </Link>
                        
                    </>
                )
            }
        },
        {
            title: '订单日期',
            dataIndex: 'dpodate',
            key: '5',
            width: 120,
        },
        {
            title: '订单号',
            dataIndex: 'cpoid',
            key: '6',
            width: 120,
        },
        {
            title: '厂商编号',
            dataIndex: 'cvencode',
            key: '7',
            width: 100,
        },
        {
            title: '图号',
            dataIndex: 'cinvcode',
            key: '8',
            width: 100,
        },
        {
            title: '名称',
            dataIndex: 'cinvname',
            key: '9' ,
            width: 100,
        },
        {
            title: '规格',
            dataIndex: 'cinvstd',
            key: '10' ,
            width: 100,
        },
        {
            title: '版本号',
            dataIndex: 'cinvdefine1',
            key: '11' ,
            width: 80,
        },
        {
            title: '单位',
            dataIndex: 'ccomunitname',
            key: '12' ,
            width: 80,
        },
        {
            title: '数量',
            dataIndex: 'iquantity',
            key: '13' ,
            width: 80,
        },
        {
            title: '追溯批号',
            dataIndex: 'cdefine25',
            key: '14' ,
            width: 100,
        },
        {
            title: '交货日期',
            dataIndex: 'darrivedate',
            key: '15' ,
            width: 120,
        },
        {
            title: '确认日期',
            dataIndex: 'confirmdate',
            key: '16' ,
            width: 120,
        },
        {
            title: '供应商说明',
            dataIndex: 'cvenremark',
            key: '17' ,
            width: 110,
        },
        {
            title: '交货要求',
            dataIndex: 'deliveryquestion',
            // fixed: 'right',
            width: 130,
        },
    ];
    // 获取供应商列表
    const getOrders = () => {
        var cvencode= '';
        var type=window.localStorage.getItem("type")
        if(type=="2"){
            cvencode=JSON.parse(window.localStorage.getItem("user")).cvencode;
        }
        common.loadingStart()
        let params = {
            cvencode:cvencode,
            ...common.removeNull(search),
            cstate1 : [2,5],
            page: pagination.page,
            limit: pagination.limit,
        }
        common.ajax("GET", "/passport/poPodetails/list", params).then(data => {
            setDataList(data.list)
            setPagination(data.pagination)
        }).finally(common.loadingStop)
    }

    useEffect(() => {
        getOrders()
    }, [pagination.page, pagination.limit,search])
    var ll;
    var type=window.localStorage.getItem("type")
    if(type=="1"){
        ll=<Col span={3}> <Form.Item name="cvencode" getValueFromEvent={common.getValueFromEvent}>
            <Input placeholder="厂商编号"  onChange={(e) => {
                setQuery({...query,cvencode: e.target.value})
            }}/>
        </Form.Item></Col>
    }
    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>订单管理</Breadcrumb.Item>
                <Breadcrumb.Item>发货管理</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{minHeight: 360}}>
                
                <Form form={searchForm} style={{marginTop: 15}} className='searchForm'>
                    <Row gutter={24}>
                        {ll}
                        <Col span={3}>
                            <Form.Item name="sendstate">
                                <Select placeholder="发货状态" showSearch onChange={(value)=>{
                                    console.log(value)
                                    setQuery({...query, sendstate: value})
                                }}>
                                    <Option>发货状态</Option>
                                    <Option value="1">未完成</Option>
                                    <Option value="2">已完成</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="cpoid" getValueFromEvent={common.getValueFromEvent}>
                                <Input placeholder="订单号"  onChange={(e) => {
                                    let value = common.replacezifu(e.target.value)
                                    setQuery({...query,cpoid: value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name="startTime">
                                <DatePicker onChange={(value)=>{
                                    setQuery({...query, startTime: value})
                                }} placeholder="制单开始时间"/>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item  name="endTime">
                                <DatePicker onChange={(value)=>{
                                    setQuery({...query, endTime: value})
                                }} placeholder="制单结束时间" />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="cinvcode" getValueFromEvent={common.getValueFromEvent}>
                                <Input placeholder="图号"  onChange={(e) => {
                                    setQuery({...query,cinvcode: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item>
                                <Space>
                                    <Button onClick={() => {
                                        setSearch(query)
                                        pagination.page = 1
                                    }} className='search-btn mybtn'><SearchOutlined />搜索</Button>
                                    <Button onClick={() => {
                                        let emptySearch = {
                                            name: '',
                                            lineId:'',
                                            categoryId:'',
                                            gatewayId:'',
                                            cinvcode:'',
                                            startTime :'',
                                            endTime : '',
                                            cpoid:'',
                                            sendstate:''

                                        }
                                        console.log(11111);
                                        searchForm.setFieldsValue(emptySearch)
                                        emptySearch = {}
                                        setSearch(emptySearch)
                                        setQuery(emptySearch)
                                    }} className='clean-btn mybtn'><ReloadOutlined />清空</Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div className='table-box'>
                    {/* <Button type="primary" onClick={() => {
                        setCreateModalVisible(false)
                        createForm.setFieldsValue({title: ""})
                    }} className='add-btn mybtn'><PlusOutlined />新增</Button> */}
                    <Table rowKey={(columns,index) => index}
                           dataSource={dataList} columns={columns} pagination={false} scroll={{ x: 1950 }}
                           rowClassName={(record, idx) => {
                               if(idx % 2 === 1)
                                   return 'bg-row';
                           }}/>
                </div>
                <Modal title="新增" destroyOnClose={true} footer={false} maskClosable={false} visible={createModalVisible} onCancel={() => {
                    setCreateModalVisible(false)
                }}
                >
                    <Form preserve={false}
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    form={createForm}
                    onFinish={(values) => {

                    }}
                >
                    <Form.Item label="名称" name="cvenname" rules={[{required: true, message: '请填写名称'}]}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item label="ERP编号" name="cvencode" rules={[{required: true, message: '请填写ERP编号'}]}>
                         <Input disabled />
                    </Form.Item>
                    <Form.Item label="登录账号" name="cvenmnemcode" rules={[{required: true, message: '请填写登录账号'}]}>
                         <Input disabled />
                    </Form.Item>
                    <Form.Item label="密码" name="pd" rules={[{required: true, message: '请填写密码'}]}>
                         <Input/>
                    </Form.Item>
                    <Form.Item label="主邮箱" name="email" rules={[{required: true, message: '请填主邮箱'}]}>
                         <Input/>
                    </Form.Item>
                    <Form.Item label="附邮箱" name="email2" rules={[{required: false, message: '请填主邮箱'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="备用邮箱" name="email3" rules={[{required: false, message: '请填主邮箱'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="备用邮箱" name="email4" rules={[{required: false, message: '请填主邮箱'}]}>
                         <Input/>
                    </Form.Item>
                    <Form.Item wrapperCol={{offset: 8, span: 16}}>
                        <Button type="primary" htmlType="submit">
                            保存
                        </Button>
                    </Form.Item>
                </Form>
                </Modal>
                
                {pagination.total < 5 ? '' :
                    <PaginationBar>
                        <Pagination
                            style={{marginTop: 25}}
                            pageSizeOptions={['10', '20', '50', '100']}
                            onChange={(page, limit) => {
                                setPagination({...pagination, page, limit})
                            }}
                            onShowSizeChange={(page, limit) => {
                                setPagination({...pagination, page, limit})
                            }}
                            showTotal={total => `共${total}条`}
                            total={pagination.total}
                            defaultCurrent={pagination.page}
                            current={pagination.page}
                            defaultPageSize={pagination.limit}
                            showSizeChanger
                            showQuickJumper
                        /></PaginationBar>}
            </div>
        </div>
    );
}
export default Popodetails;