import React, { useEffect, useState } from 'react';
import {
    Button,
    Radio,
    Form,
    Input,
    Modal,
    Table,
    Breadcrumb,
    Pagination,
    Row,
    Col,
    Space,
    Select,
    DatePicker,
    InputNumber
} from "antd";
import common from "../../common";
import moment from "moment"
import {
    ExclamationCircleFilled
} from '@ant-design/icons';
import './index.less'
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Popodetails from '../popodetails';


function Print(props) {
    const {Option} = Select;
    let [dataList, setDataList] = useState([])
    const [editForm] = Form.useForm();
    const [sizeForm] = Form.useForm();
    let [loadicon, setLoadicon] = useState(false)
    let [qrcode, setQrcode] = useState('')
    let [pagination, setPagination] = useState({
        total: 0,
        page: 1,
        limit: 10,
    })

    let [size,setSize] = useState([])
    let [sizeSetting,setSizeSetting] = useState({size:'600'})

    let [poPodetails, setPoPodetails] = useState({});
    const getPoPodetails = (a) => {
        common.loadingStart();
        var cvencode=JSON.parse(window.localStorage.getItem("user")).cvencode;
        let params = {
            cvencode:cvencode ? cvencode : "",
            id: props.match.params.id
        }
        common.ajax("GET", "/passport/sends/list",params).then(data => {
            console.log(data.list[0]);
            var aa = data.list[0];
            setPoPodetails(aa)
            editForm.setFieldsValue({...aa,pdate: moment(aa["pdate"])});
            setLoadicon(false)


            let sdate = aa.sdate.replaceAll('-','')
            let qrcode = `<PO>${aa.cpoid}</PO><P>${aa.cinvcode}</P><QTY>${aa.iquantity}</QTY><C>${aa.codes}</C><LOT>${sdate}</LOT><B>${aa.id}</B><Z>${aa.checkOrder}</Z>`
            setQrcode(qrcode);
        }).finally(common.loadingStop())
    }

    const getSize = () => {
        common.ajax("GET", "/passport/size/list").then(data => {
            setSize(data)
        })
    }

    let [sizePdf,setSizePdf] = useState({width:100,height:80,td1:'20%',td2:'40%',td3:'40%',fontSize:10,qrcode:100});

   

    const getSizeSetting = () => {

        let cvencode = '';
        let user = JSON.parse(window.localStorage.getItem("user"));
        let type = window.localStorage.getItem("type")

        if (type == 1){
            cvencode = user.id;
        }else if(type == 2){
            cvencode = user.cvencode
        }
       
        let params = {
            cvencode : cvencode
        }

        common.ajax("GET", "/passport/sizeSetting/byCvencode",params).then(data => {
            var s = data
            if(data == null){
                s = {
                    cvencode : cvencode,
                    size : '600'
                }
            }
            setSizeSetting({...s})
            let si = common.sizePdf(s.size)
            setSizePdf(si)
        })
    }
   
    const updateSetSetting = () => {
        console.log(sizeSetting.size);
        let si = common.sizePdf(sizeSetting.size)
        setSizePdf(si)
        common.ajax("POST", "/passport/sizeSetting/update",{cvencode : sizeSetting.cvencode , size : sizeSetting.size}).then(data => {
            common.toast("保存成功")
        })
    }

    useEffect(() => {
        getPoPodetails();
        getSize();
        getSizeSetting();
    }, [pagination.page, pagination.limit])


    const download = () => {

        var element = document.getElementById("demo");    // 这个dom元素是要导出pdf的div容器
        var canvas = document.createElement("canvas");
        var context = canvas.getContext("2d");

        
        html2canvas(element,{
            // allowTaint: true,
            scale: 2 // 提升画面质量，但是会增加文件大小
        }).then(function (canvas) {
            var pageData = canvas.toDataURL('image/jpeg', 1.0);
            var pdf = new jsPDF('l', 'mm',[sizePdf.width,sizePdf.height]);
            pdf.addImage(pageData, 'JPEG', 0, 0, sizePdf.width,sizePdf.height);
            pdf.output('dataurlnewwindow');
        });
    };

    const a4Print = () => {
        let sdate = poPodetails.sdate.replaceAll('-','')
        let qrcode = `<PO>${poPodetails.cpoid}</PO><P>${poPodetails.cinvcode}</P><QTY>${poPodetails.iquantity}</QTY><C>${poPodetails.codes}</C><LOT>${sdate}</LOT><B>${poPodetails.id}</B><Z>${poPodetails.checkOrder}</Z>`
        setQrcode(qrcode);


        var element = document.getElementById("a4Demo");    // 这个dom元素是要导出pdf的div容器
        var canvas = document.createElement("canvas");
        html2canvas(element,{
            allowTaint: true,
            taintTest: false,
            scale: '1',
            dpi: '192',
            background: '#fff',
        }).then(function (canvas) {
             let contentWidth = canvas.width
            let contentHeight = canvas.height
            let pageHeight = contentWidth / 592.28 * 841.89
            let leftHeight = contentHeight
            let position = 0
            let imgWidth = 595.28
            let imgHeight = 592.28 / contentWidth * contentHeight
            let pageData = canvas.toDataURL('image/jpeg', 1.0)
            let PDF = new jsPDF('', 'pt', 'a4')
            if (leftHeight < pageHeight) {
                PDF.addImage(pageData, 'PNGS', 0, 0, imgWidth, imgHeight)
            } else {
                while (leftHeight > 0) {
                    PDF.addImage(pageData, 'PNGS', 0, position, imgWidth, imgHeight)
                    leftHeight -= pageHeight
                    position -= 841.89
                    if (leftHeight > 0) {
                        PDF.addPage()
                    }
                }
            }
            PDF.output('dataurlnewwindow');
        })
    }
    
    let width = 300
    let height = 220


    return (
        <div>
            
            <Breadcrumb>
                <Breadcrumb.Item>订单管理</Breadcrumb.Item>
                <Breadcrumb.Item><a href="/#/popodetails">发货记录</a></Breadcrumb.Item>
                <Breadcrumb.Item>打印</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-background updateModel" style={{minHeight: 360}} >

            <div style={{marginTop: 15,color: '#a94442',backgroundColor:'#f2dede',borderColor:'#ebccd1',padding: 24,borderRadius:10}}>
                <div style={{height: '40px'}}>
                    <div style={{float: 'left',lineHeight: '20px'}}>
                        纸张大小设置（单位：mm）<br/>
                        选择纸张大小后请点击保存按钮保存
                    </div>
                    <Form form={sizeForm}>
                        <Form.Item name="superiorId" style={{width: '40%',margin:'auto'}}>
                            <Select value={sizeSetting.size} style={{color: '#a94442',width: '80%'}} 
                                onChange={(v)=> 
                                    setSizeSetting({...sizeSetting,size:v})
                                }>
                                {size.map(item => {
                                    return (<Option style={{color: '#a94442'}} key={item.code} value={item.code}> {item.size}</Option>)
                                })}
                            </Select>
                            <Button type="primary" style={{marginLeft: 10}} onClick={() => updateSetSetting()} >保存</Button>
                        </Form.Item>
                        
                    </Form>
                </div>
                
            </div>

                <Form form={editForm} style={{marginTop: 15,backgroundColor:'#fff',padding: 24,borderRadius:10}}
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 15 }}
                      onFinish={(values) => {
                          if (!values.boxQuantity > 0){
                              common.toast("每箱/包数量必须大于0")
                              return
                          }
                          values.pdate = values.pdate.format('YYYY-MM-DD');
                          values.cvencode = poPodetails.cvencode
                          setPoPodetails(values)
                          download();
                      }}
                >
                <div style={{width:"40%",marginLeft:"8%",paddingLeft:"100px",display:"inline-block"}}>
                        <Form.Item
                            label="产品图号"
                            name="cinvcode"
                        >
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            label="产品规格"
                            name="cinvstd"
                        >
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            label="订单号"
                            name="cpoid"
                        >
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            label="每箱/包数量"
                            name="boxQuantity"
                        >
                            <InputNumber onChange={v => {setPoPodetails({...poPodetails,boxQuantity:v})}} style={{width:'100%'}}/>
                        </Form.Item>
                        <Form.Item
                            label="追溯批号"
                            name="checkOrder"
                        >
                            <Input onChange={v => {setPoPodetails({...poPodetails,checkOrder:v.target.value})}}/>

                        </Form.Item>
                        <Form.Item
                            label="版本号"
                            name="cinvdefine1"
                        >
                            <Input/>
                        </Form.Item>

                        {
                            poPodetails != null ?  <div></div>: 1
                        }
                        {/* style={{margin:"0",border:"0",padding:"0",height:"0",width:"0",overflow:'hidden'}} */}
                        {/* 普通打印 */}
                        <div style={{margin:"0",border:"0",padding:"0",height:"0",width:"0",overflow:'hidden'}}>
                            <table border="1" className="table" id="demo" style={{width: width,height: height,fontSize: 10}}>
                                <tr className="fs-custom ">
                                    <td width="23%" className="text-center text-style">产品名称</td>
                                    <td width="40%" colSpan="2" className="text-info text-style">{poPodetails.cinvname}</td>
                                    <td width="37%" rowSpan="4" colSpan="3" className="qrcode">
                                        <QRCode
                                            className='text-style'
                                            value={qrcode}  // value参数为生成二维码的链接
                                            size={80}  // 二维码的宽高尺寸
                                            fgColor="#000000"   // 二维码的颜色 
                                        />
                                    </td>
                                </tr>
                                <tr className="fs-custom ">
                                    <td className="text-center text-style">产品图号</td>
                                    <td colSpan="2" className="text-info text-style">{poPodetails.cinvcode}</td>
                                </tr>
                                <tr className="fs-custom ">
                                    <td className="text-center text-style">产品规格</td>
                                    <td colSpan="2" className="text-info text-style">{poPodetails.cinvstd}</td>
                                </tr>
                                <tr className="fs-custom ">
                                    <td className="text-center text-style">生产日期</td>
                                    <td colSpan="2" className="text-info text-style">{poPodetails.pdate}</td>
                                </tr>
                                <tr className="fs-custom ">
                                    <td className="text-center text-style">单 位</td>
                                    <td className="text-info text-style">{poPodetails.ccomunitname}</td>
                                    <td className="text-center text-style">订单号</td>
                                    <td className="text-info text-style">{poPodetails.cpoid}</td>
                                </tr>
                                <tr className="fs-custom">
                                    <td className="text-center text-style">每箱/包数量</td>
                                    <td className="text-info text-style">{poPodetails.boxQuantity}</td>
                                    <td className="text-center text-style">送货数量</td>
                                    <td className="text-info text-style">{poPodetails.iquantity}</td>
                                </tr>
                                <tr className="fs-custom">
                                    <td className="text-center text-style">供应商编号</td>
                                    <td className="text-info text-style">{poPodetails.cvencode}</td>
                                    <td className="text-center text-style">追溯批号</td>
                                    <td className="text-info text-style">{poPodetails.checkOrder}</td>
                                </tr>
                                <tr className="fs-custom">
                                    <td className="text-center text-style">版本号</td>
                                    <td className="text-info text-style">{poPodetails.cinvdefine1}</td>
                                    <td className="text-center text-style">变更首次</td>
                                    <td className="text-info text-style">{poPodetails.first == 1 ? '是' : '否'}&nbsp;&nbsp;&nbsp;禁止覆盖</td>
                                </tr>
                            </table>
                        </div>

                        {/* a4打印 */}
                        <div style={{margin:"0",border:"0",padding:"0",height:"0",width:"0",overflow:'hidden'}}>
                            <div id="a4Demo" style={{width:'700px',textAlign:'center',padding:10,paddingTop:20}}>
                                <div style={{display:'inline-block'}}>
                                    <table border="1" className="table" id="demo" style={{width: width,height: height,fontSize: 10}}>
                                        <tr className="fs-custom ">
                                            <td width="23%" className="text-center text-style">产品名称</td>
                                            <td width="40%" colSpan="2" className="text-info text-style">{poPodetails.cinvname}</td>
                                            <td width="37%" rowSpan="4" colSpan="3" className="qrcode">
                                                <QRCode
                                                    className='text-style'
                                                    value={qrcode}  // value参数为生成二维码的链接
                                                    size={80}  // 二维码的宽高尺寸
                                                    fgColor="#000000"   // 二维码的颜色 
                                                />
                                            </td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">产品图号</td>
                                            <td colSpan="2" className="text-info text-style">{poPodetails.cinvcode}</td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">产品规格</td>
                                            <td colSpan="2" className="text-info text-style">{poPodetails.cinvstd}</td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">生产日期</td>
                                            <td colSpan="2" className="text-info text-style">{poPodetails.pdate}</td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">单 位</td>
                                            <td className="text-info text-style">{poPodetails.ccomunitname}</td>
                                            <td className="text-center text-style">订单号</td>
                                            <td className="text-info text-style">{poPodetails.cpoid}</td>
                                        </tr>
                                        <tr className="fs-custom">
                                            <td className="text-center text-style">每箱/包数量</td>
                                            <td className="text-info text-style">{poPodetails.boxQuantity}</td>
                                            <td className="text-center text-style">送货数量</td>
                                            <td className="text-info text-style">{poPodetails.iquantity}</td>
                                        </tr>
                                        <tr className="fs-custom">
                                            <td className="text-center text-style">供应商编号</td>
                                            <td className="text-info text-style">{poPodetails.cvencode}</td>
                                            <td className="text-center text-style">追溯批号</td>
                                            <td className="text-info text-style">{poPodetails.checkOrder}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div style={{display:'inline-block',marginLeft:'15px'}}>
                                    <table border="1" className="table" id="demo" style={{width: width,height: height,fontSize: 10}}>
                                        <tr className="fs-custom ">
                                            <td width="23%" className="text-center text-style">产品名称</td>
                                            <td width="40%" colSpan="2" className="text-info text-style">{poPodetails.cinvname}</td>
                                            <td width="37%" rowSpan="4" colSpan="3" className="qrcode">
                                                <QRCode
                                                    className='text-style'
                                                    value={qrcode}  // value参数为生成二维码的链接
                                                    size={80}  // 二维码的宽高尺寸
                                                    fgColor="#000000"   // 二维码的颜色 
                                                />
                                            </td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">产品图号</td>
                                            <td colSpan="2" className="text-info text-style">{poPodetails.cinvcode}</td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">产品规格</td>
                                            <td colSpan="2" className="text-info text-style">{poPodetails.cinvstd}</td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">生产日期</td>
                                            <td colSpan="2" className="text-info text-style">{poPodetails.pdate}</td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">单 位</td>
                                            <td className="text-info text-style">{poPodetails.ccomunitname}</td>
                                            <td className="text-center text-style">订单号</td>
                                            <td className="text-info text-style">{poPodetails.cpoid}</td>
                                        </tr>
                                        <tr className="fs-custom">
                                            <td className="text-center text-style">每箱/包数量</td>
                                            <td className="text-info text-style">{poPodetails.boxQuantity}</td>
                                            <td className="text-center text-style">送货数量</td>
                                            <td className="text-info text-style">{poPodetails.iquantity}</td>
                                        </tr>
                                        <tr className="fs-custom">
                                            <td className="text-center text-style">供应商编号</td>
                                            <td className="text-info text-style">{poPodetails.cvencode}</td>
                                            <td className="text-center text-style">追溯批号</td>
                                            <td className="text-info text-style">{poPodetails.checkOrder}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div style={{display:'inline-block',marginTop:20}}>
                                    <table border="1" className="table" id="demo" style={{width: width,height: height,fontSize: 10}}>
                                        <tr className="fs-custom ">
                                            <td width="23%" className="text-center text-style">产品名称</td>
                                            <td width="40%" colSpan="2" className="text-info text-style">{poPodetails.cinvname}</td>
                                            <td width="37%" rowSpan="4" colSpan="3" className="qrcode">
                                                <QRCode
                                                    className='text-style'
                                                    value={qrcode}  // value参数为生成二维码的链接
                                                    size={80}  // 二维码的宽高尺寸
                                                    fgColor="#000000"   // 二维码的颜色 
                                                />
                                            </td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">产品图号</td>
                                            <td colSpan="2" className="text-info text-style">{poPodetails.cinvcode}</td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">产品规格</td>
                                            <td colSpan="2" className="text-info text-style">{poPodetails.cinvstd}</td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">生产日期</td>
                                            <td colSpan="2" className="text-info text-style">{poPodetails.pdate}</td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">单 位</td>
                                            <td className="text-info text-style">{poPodetails.ccomunitname}</td>
                                            <td className="text-center text-style">订单号</td>
                                            <td className="text-info text-style">{poPodetails.cpoid}</td>
                                        </tr>
                                        <tr className="fs-custom">
                                            <td className="text-center text-style">每箱/包数量</td>
                                            <td className="text-info text-style">{poPodetails.boxQuantity}</td>
                                            <td className="text-center text-style">送货数量</td>
                                            <td className="text-info text-style">{poPodetails.iquantity}</td>
                                        </tr>
                                        <tr className="fs-custom">
                                            <td className="text-center text-style">供应商编号</td>
                                            <td className="text-info text-style">{poPodetails.cvencode}</td>
                                            <td className="text-center text-style">追溯批号</td>
                                            <td className="text-info text-style">{poPodetails.checkOrder}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div style={{display:'inline-block',marginTop:20,marginLeft:'15px'}}>
                                    <table border="1" className="table" id="demo" style={{width: width,height: height,fontSize: 10}}>
                                        <tr className="fs-custom ">
                                            <td width="23%" className="text-center text-style">产品名称</td>
                                            <td width="40%" colSpan="2" className="text-info text-style">{poPodetails.cinvname}</td>
                                            <td width="37%" rowSpan="4" colSpan="3" className="qrcode">
                                                <QRCode
                                                    className='text-style'
                                                    value={qrcode}  // value参数为生成二维码的链接
                                                    size={80}  // 二维码的宽高尺寸
                                                    fgColor="#000000"   // 二维码的颜色 
                                                />
                                            </td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">产品图号</td>
                                            <td colSpan="2" className="text-info text-style">{poPodetails.cinvcode}</td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">产品规格</td>
                                            <td colSpan="2" className="text-info text-style">{poPodetails.cinvstd}</td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">生产日期</td>
                                            <td colSpan="2" className="text-info text-style">{poPodetails.pdate}</td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">单 位</td>
                                            <td className="text-info text-style">{poPodetails.ccomunitname}</td>
                                            <td className="text-center text-style">订单号</td>
                                            <td className="text-info text-style">{poPodetails.cpoid}</td>
                                        </tr>
                                        <tr className="fs-custom">
                                            <td className="text-center text-style">每箱/包数量</td>
                                            <td className="text-info text-style">{poPodetails.boxQuantity}</td>
                                            <td className="text-center text-style">送货数量</td>
                                            <td className="text-info text-style">{poPodetails.iquantity}</td>
                                        </tr>
                                        <tr className="fs-custom">
                                            <td className="text-center text-style">供应商编号</td>
                                            <td className="text-info text-style">{poPodetails.cvencode}</td>
                                            <td className="text-center text-style">追溯批号</td>
                                            <td className="text-info text-style">{poPodetails.checkOrder}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div style={{display:'inline-block',marginTop:20}}>
                                    <table border="1" className="table" id="demo" style={{width: width,height: height,fontSize: 10}}>
                                        <tr className="fs-custom ">
                                            <td width="23%" className="text-center text-style">产品名称</td>
                                            <td width="40%" colSpan="2" className="text-info text-style">{poPodetails.cinvname}</td>
                                            <td width="37%" rowSpan="4" colSpan="3" className="qrcode">
                                                <QRCode
                                                    className='text-style'
                                                    value={qrcode}  // value参数为生成二维码的链接
                                                    size={80}  // 二维码的宽高尺寸
                                                    fgColor="#000000"   // 二维码的颜色 
                                                />
                                            </td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">产品图号</td>
                                            <td colSpan="2" className="text-info text-style">{poPodetails.cinvcode}</td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">产品规格</td>
                                            <td colSpan="2" className="text-info text-style">{poPodetails.cinvstd}</td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">生产日期</td>
                                            <td colSpan="2" className="text-info text-style">{poPodetails.pdate}</td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">单 位</td>
                                            <td className="text-info text-style">{poPodetails.ccomunitname}</td>
                                            <td className="text-center text-style">订单号</td>
                                            <td className="text-info text-style">{poPodetails.cpoid}</td>
                                        </tr>
                                        <tr className="fs-custom">
                                            <td className="text-center text-style">每箱/包数量</td>
                                            <td className="text-info text-style">{poPodetails.boxQuantity}</td>
                                            <td className="text-center text-style">送货数量</td>
                                            <td className="text-info text-style">{poPodetails.iquantity}</td>
                                        </tr>
                                        <tr className="fs-custom">
                                            <td className="text-center text-style">供应商编号</td>
                                            <td className="text-info text-style">{poPodetails.cvencode}</td>
                                            <td className="text-center text-style">追溯批号</td>
                                            <td className="text-info text-style">{poPodetails.checkOrder}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div style={{display:'inline-block',marginTop:20,marginLeft:'15px'}}>
                                    <table border="1" className="table" id="demo" style={{width: width,height: height,fontSize: 10}}>
                                        <tr className="fs-custom ">
                                            <td width="23%" className="text-center text-style">产品名称</td>
                                            <td width="40%" colSpan="2" className="text-info text-style">{poPodetails.cinvname}</td>
                                            <td width="37%" rowSpan="4" colSpan="3" className="qrcode">
                                                <QRCode
                                                    className='text-style'
                                                    value={qrcode}  // value参数为生成二维码的链接
                                                    size={80}  // 二维码的宽高尺寸
                                                    fgColor="#000000"   // 二维码的颜色 
                                                />
                                            </td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">产品图号</td>
                                            <td colSpan="2" className="text-info text-style">{poPodetails.cinvcode}</td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">产品规格</td>
                                            <td colSpan="2" className="text-info text-style">{poPodetails.cinvstd}</td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">生产日期</td>
                                            <td colSpan="2" className="text-info text-style">{poPodetails.pdate}</td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">单 位</td>
                                            <td className="text-info text-style">{poPodetails.ccomunitname}</td>
                                            <td className="text-center text-style">订单号</td>
                                            <td className="text-info text-style">{poPodetails.cpoid}</td>
                                        </tr>
                                        <tr className="fs-custom">
                                            <td className="text-center text-style">每箱/包数量</td>
                                            <td className="text-info text-style">{poPodetails.boxQuantity}</td>
                                            <td className="text-center text-style">送货数量</td>
                                            <td className="text-info text-style">{poPodetails.iquantity}</td>
                                        </tr>
                                        <tr className="fs-custom">
                                            <td className="text-center text-style">供应商编号</td>
                                            <td className="text-info text-style">{poPodetails.cvencode}</td>
                                            <td className="text-center text-style">追溯批号</td>
                                            <td className="text-info text-style">{poPodetails.checkOrder}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div style={{display:'inline-block',marginTop:20}}>
                                    <table border="1" className="table" id="demo" style={{width: width,height: height,fontSize: 10}}>
                                        <tr className="fs-custom ">
                                            <td width="23%" className="text-center text-style">产品名称</td>
                                            <td width="40%" colSpan="2" className="text-info text-style">{poPodetails.cinvname}</td>
                                            <td width="37%" rowSpan="4" colSpan="3" className="qrcode">
                                                <QRCode
                                                    className='text-style'
                                                    value={qrcode}  // value参数为生成二维码的链接
                                                    size={80}  // 二维码的宽高尺寸
                                                    fgColor="#000000"   // 二维码的颜色 
                                                />
                                            </td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">产品图号</td>
                                            <td colSpan="2" className="text-info text-style">{poPodetails.cinvcode}</td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">产品规格</td>
                                            <td colSpan="2" className="text-info text-style">{poPodetails.cinvstd}</td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">生产日期</td>
                                            <td colSpan="2" className="text-info text-style">{poPodetails.pdate}</td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">单 位</td>
                                            <td className="text-info text-style">{poPodetails.ccomunitname}</td>
                                            <td className="text-center text-style">订单号</td>
                                            <td className="text-info text-style">{poPodetails.cpoid}</td>
                                        </tr>
                                        <tr className="fs-custom">
                                            <td className="text-center text-style">每箱/包数量</td>
                                            <td className="text-info text-style">{poPodetails.boxQuantity}</td>
                                            <td className="text-center text-style">送货数量</td>
                                            <td className="text-info text-style">{poPodetails.iquantity}</td>
                                        </tr>
                                        <tr className="fs-custom">
                                            <td className="text-center text-style">供应商编号</td>
                                            <td className="text-info text-style">{poPodetails.cvencode}</td>
                                            <td className="text-center text-style">追溯批号</td>
                                            <td className="text-info text-style">{poPodetails.checkOrder}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div style={{display:'inline-block',marginTop:20,marginLeft:'15px'}}>
                                    <table border="1" className="table" id="demo" style={{width: width,height: height,fontSize: 10}}>
                                        <tr className="fs-custom ">
                                            <td width="23%" className="text-center text-style">产品名称</td>
                                            <td width="40%" colSpan="2" className="text-info text-style">{poPodetails.cinvname}</td>
                                            <td width="37%" rowSpan="4" colSpan="3" className="qrcode">
                                                <QRCode
                                                    className='text-style'
                                                    value={qrcode}  // value参数为生成二维码的链接
                                                    size={80}  // 二维码的宽高尺寸
                                                    fgColor="#000000"   // 二维码的颜色 
                                                />
                                            </td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">产品图号</td>
                                            <td colSpan="2" className="text-info text-style">{poPodetails.cinvcode}</td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">产品规格</td>
                                            <td colSpan="2" className="text-info text-style">{poPodetails.cinvstd}</td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">生产日期</td>
                                            <td colSpan="2" className="text-info text-style">{poPodetails.pdate}</td>
                                        </tr>
                                        <tr className="fs-custom ">
                                            <td className="text-center text-style">单 位</td>
                                            <td className="text-info text-style">{poPodetails.ccomunitname}</td>
                                            <td className="text-center text-style">订单号</td>
                                            <td className="text-info text-style">{poPodetails.cpoid}</td>
                                        </tr>
                                        <tr className="fs-custom">
                                            <td className="text-center text-style">每箱/包数量</td>
                                            <td className="text-info text-style">{poPodetails.boxQuantity}</td>
                                            <td className="text-center text-style">送货数量</td>
                                            <td className="text-info text-style">{poPodetails.iquantity}</td>
                                        </tr>
                                        <tr className="fs-custom">
                                            <td className="text-center text-style">供应商编号</td>
                                            <td className="text-info text-style">{poPodetails.cvencode}</td>
                                            <td className="text-center text-style">追溯批号</td>
                                            <td className="text-info text-style">{poPodetails.checkOrder}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        
                        <Form.Item style={{textAlign:'center',marginTop:20}}>
                            <Button type="primary" onClick={()=>{
                                if(!poPodetails.boxQuantity){
                                    common.toast("请填写每箱/包数量")
                                }else{
                                    download()
                                }
                            }}>
                                打印
                            </Button>

                            <Button type="primary" style={{marginLeft:20}} onClick={()=>{
                                if(!poPodetails.boxQuantity){
                                    common.toast("请填写每箱/包数量")
                                }else{
                                    a4Print()
                                }
                            }}>
                                A4打印
                            </Button>
                        </Form.Item>
                </div>
                <div style={{width:"40%",marginRight:"8%",paddingRight:"100px",float:"right"}}>
                        <Form.Item
                            label="产品名称"
                            name="cinvname"
                        >
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            label="单位"
                            name="ccomunitname"
                        >
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            label="生产日期"
                            name="pdate"
                        >
                            <DatePicker onChange={(value)=>{
                            }} placeholder="生成日期" style={{width:'100%'}} disabled/>
                        </Form.Item>
                        <Form.Item
                            label="送货数量"
                            name="iquantity">
                                <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            label="周码"
                            name="week"
                        >
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            label="变更首次"
                            name="first"
                        >
                            <Radio.Group>
                                <Radio disabled={true} value={1}>是</Radio>
                                <Radio disabled={true} value={2}>否</Radio>
                            </Radio.Group>
                        </Form.Item>
                </div>
                </Form>
            </div>
        </div>
    );
}
export default Print;