import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PaginationBar from "../../components/paginationbar";
import {Button,DatePicker, Form, Input, Modal, Table, Breadcrumb, Pagination,Row, Col,Switch, Space, Select} from "antd";
import common from "../../common";
import axios from 'axios';
import {
    DeleteFilled, PrinterFilled, PlusOutlined, SearchOutlined, ReloadOutlined, 
    LockFilled, UnlockOutlined, EditFilled
} from '@ant-design/icons';
import './index.less'
import { Link } from 'react-router-dom'

function Daohuo(props) {
    let initSearch = { dataBase: 'UFDATA_777_2018'}
    let [dataList, setDataList] = useState([])
    let [search, setSearch] = useState(initSearch)       // 搜索内容
    const [searchForm] = Form.useForm();
    let [query, setQuery] = useState(initSearch);

    let [pagination, setPagination] = useState({
        total: 0,
        page: 1,
        limit: 10,
    })
    const {Option} = Select;
   
    const columns = [
        {
            title: '操作',
            align: "center",
            width: 180,
            key: 'name',
            fixed: 'left',
            render: (text, record) => {
                if(record.isPrint == 1 ){
                    return (
                        <>
                            <Link style={{backgroundColor:"#3c8dbc"}} className="bianji" target = "_blank" 
                                to={{pathname:`/printview/${record.autoId}/${record.dataBase}`}}>
                                    已打印
                            </Link>
                        </>
                    )
                }else{
                    return (
                        <>
                            <Link style={{backgroundColor:"#1ab394"}} className="bianji" target = "_blank" 
                                to={{pathname:`/printview/${record.autoId}/${record.dataBase}`}}>
                                    打印PDF
                            </Link>
                        </>
                    )
                }
            }
        },
        {
            title: '到货日期',
            width: 100,
            dataIndex: 'ddate',
            key: 'ddate',
            fixed: 'left',
        },
        {
            title: '生产日期',
            dataIndex: 'ddate',
            key: '1',
            width: 130,
        },
        {
            title: '批号',
            dataIndex: 'cbatch',
            key: '2',
            width: 130,
        },
        {
            title: '物料',
            dataIndex: 'cinvcode',
            key: '3',
            width: 130,
        },
        {
            title: '采购到货单号',
            dataIndex: 'ccode',
            key: '13',
            width: 130,
        },
        {
            title: '验收单号',
            dataIndex: 'cdefine24',
            key: '4',
            width: 150,
        },
        {
            title: '到货数量',
            dataIndex: 'iquantity',
            key: '5',
            width: 150,
        },
        {
            title: '厂家',
            dataIndex: 'cvenname',
            key: '6',
            width: 120,
        },
        {
            title: '厂商编号',
            dataIndex: 'cvencode',
            key: '7',
            width: 80,
        },
    ];
    // 获取供应商列表

    const getData = () => {
        let params = {
            ...query,
            page: pagination.page,
            limit: pagination.limit,
        }
        common.loadingStart()
        common.ajax("GET", "/passport/puArrivalvouchs/list", params).then(data => {
            setDataList(data.list)
            setPagination(data.pagination)
            common.loadingStop()
        }).finally(common.loadingStop)
    }
    
    useEffect(() => {
        getData();
    }, [pagination.page, pagination.limit,search])

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>打印管理</Breadcrumb.Item>
                <Breadcrumb.Item>验收单打印</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{minHeight: 360}}>
                
                <Form form={searchForm} style={{marginTop: 15}} className='searchForm'>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item name="dataBase">
                                <Select placeholder="账套" defaultValue='UFDATA_777_2018' showSearch onChange={(value)=>{
                                    setQuery({...query, dataBase: value})
                                }}>
                                    <Option value="UFDATA_777_2018">UFDATA_777_2018</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="cinvcode" getValueFromEvent={common.getValueFromEvent}>
                                <Input placeholder="物料"  onChange={(e) => {
                                    let value = common.replacezifu(e.target.value)
                                    setQuery({...query,cinvcode: value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="cdefine24" getValueFromEvent={common.getValueFromEvent}>
                                <Input placeholder="验收单号"  onChange={(e) => {
                                    let value = common.replacezifu(e.target.value)
                                    setQuery({...query,cdefine24: value})
                                }}/>
                            </Form.Item>
                        </Col>
                        
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item name="cvenname" getValueFromEvent={common.getValueFromEvent}>
                                <Input placeholder="厂家名称"  onChange={(e) => {
                                    let value  = common.replacezifu(e.target.value)
                                    setQuery({...query,cvenname: value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="ccode" getValueFromEvent={common.getValueFromEvent}>
                                <Input placeholder="到货单号"  onChange={(e) => {
                                    let value = common.replacezifu(e.target.value)
                                    setQuery({...query,ccode: value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item>
                                <Space>
                                    <Button onClick={() => {
                                        pagination.page = 1
                                        setSearch(query)
                                    }} className='search-btn mybtn'><SearchOutlined />搜索</Button>
                                    <Button onClick={() => {
                                        let emptySearch = {
                                            cdefine24:'',
                                            cvenname:'',
                                            ccode : '' ,
                                            cinvcode : ''
                                        }
                                        let empty = { dataBase:query.dataBase }
                                        searchForm.setFieldsValue(emptySearch)
                                        setSearch(empty)
                                        setQuery(empty)
                                    }} className='clean-btn mybtn'><ReloadOutlined />清空</Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div className='table-box'>
                    {/* <Button type="primary" onClick={() => {
                        setCreateModalVisible(true)
                        createForm.setFieldsValue({title: ""})
                    }} className='add-btn mybtn'><PlusOutlined />新增</Button> */}
                    <Table rowKey={(columns,index) => index} 
                        dataSource={dataList} columns={columns} pagination={false} scroll={{ x: 1600 }}
                        rowClassName={(record, idx) => {
                            if(idx % 2 === 1)
                                return 'bg-row';
                        }}/>
                </div>
                
                {pagination.total < 5 ? '' :
                    <PaginationBar>
                        <Pagination
                            style={{marginTop: 25}}
                            pageSizeOptions={['10', '20', '50', '100']}
                            onChange={(page, limit) => {
                                setPagination({...pagination, page, limit})
                            }}
                            onShowSizeChange={(page, limit) => {
                                setPagination({...pagination, page, limit})
                            }}
                            showTotal={total => `共${total}条`}
                            total={pagination.total}
                            defaultCurrent={pagination.page}
                            current={pagination.page}
                            defaultPageSize={pagination.limit}
                            showSizeChanger
                            showQuickJumper
                        /></PaginationBar>}
            </div>
        </div>
    );
}
export default Daohuo;