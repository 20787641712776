import React, {useState, useEffect} from 'react';
import {PageHeader, Layout, Menu, Button, Modal, Form, Input,Avatar} from 'antd';
import {UserOutlined, LaptopOutlined, NotificationOutlined,
    RiseOutlined,EyeInvisibleOutlined, EyeTwoTone,MenuUnfoldOutlined,
    MenuFoldOutlined,LogoutOutlined,KeyOutlined,QuestionCircleFilled , CalculatorFilled ,PrinterOutlined  } from '@ant-design/icons';
import {Switch,BrowserRouter as Router ,Link, Route} from "react-router-dom";
import MyIcon from "./components/icon/icon";
import "./Main.less"
import common from "./common";
import SendsPrint from "./pages/sends/print";
import Vendor from "./pages/vendor";
import PoPomain from "./pages/popomain";
import Sends from "./pages/sends";
import Popodetails from "./pages/popodetails";
import Notice from "./pages/notice";
import NoticeType from "./pages/noticeType";
import PopodetailsSave from "./pages/popodetails/save";
import Home from './pages/home';
import Pdf from './pages/home/pdf';
import NoticeView from './pages/notice/view';
import Indexs from './pages/mrp/indexs';
import Indexm from './pages/mrp/indexm';
import MrpViews from './pages/mrp/views';
import MrpViewsm from './pages/mrp/viewsm';
import User from './pages/user';
import WarePrint from "./pages/warePrint";
import Daohuo from "./pages/daohuo";
import PrintView from "./pages/daohuo/printView"
import Role from "./pages/role"

const {SubMenu} = Menu;
const {Header, Content, Footer, Sider} = Layout;

// var kk={"Vendor":Vendor}
function Main(props) {
    let [editModalVisible, setEditModalVisible] = useState(false)
    let [show, setShow] = useState(false)
    let [editForm] = Form.useForm();
    let [collapsed, setCollapsed] = useState(false)
    let [user, setUser] = useState({
        name: ""
    })
    let [keys,setKeys] = useState('')
    let from = (e) => {
        let url = window.location.hash.split("/")[1]
        if(e.key == url){
            window.location.reload()
        }
        props.history.push('/' + e.key)
    }
    let [menu, setMenu] = useState([]);
    //获取网关
    const getMenu = (e) => {
        common.ajax("GET", "/passport/menu/list",e).then(data => {
            console.log(data);
            setMenu(data)
        })
    }
    useEffect(() => {

        let split = window.location.hash.split('/');
        setKeys(split[split.length-1])
        if (window.localStorage.getItem("user")) {
            var user=JSON.parse(window.localStorage.getItem("user"));
            if(user.userName==undefined){
                user.name=user.cvenabbname;
            }else {
                user.name=user.userName;
            }
            setUser(user)
            getMenu({"starts":1});
        } else {
            if( window.location.href.indexOf("heyu.srm.wijay.cn") != -1){
                // window.location = '/#/UserLogin'
                props.history.push('/UserLogin')
            }else{
                // window.location = '/#/VendorLogin'
                props.history.push('/VendorLogin')
            }
        }
        //openNotification()
    }, [keys])

    function logOut() {
        common.ajax("post", "/passport/token/delete").then(res => {
            common.toast("退出成功")

            if(window.localStorage.getItem("type")==1){
                props.history.push('/UserLogin')
            }else if(window.localStorage.getItem("type")==2){
                props.history.push('/VendorLogin')
            }

        })

    }

    const update = (data) => {
        console.log(data);
        common.loadingStart()
        common.ajax("POST", "/passport/user/updatePassword", data).then(res => {
            common.toast("修改成功")
            setEditModalVisible(false)
            // logOut()
        }).finally(common.loadingStop)
    }

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
      };

    return (
        <div className="Main">
            <Layout style={{minHeight: '100vh'}}>
                <Sider collapsible={false} collapsed={collapsed} onCollapse={(collapse) => {
                    setCollapsed(collapse)
                }}>
                    <div className="logoTop" style={{backgroundColor: collapsed ? "rgba(15,41,80,1)" : "#367fa9"}} onClick={()=> props.history.push('/') }>
                        { collapsed ? <Avatar style={{backgroundColor:"#367fa9"}} size={50}>S</Avatar>  : "SRM"}
                    </div>
                    
                    <Modal
                        footer={false} maskClosable={false} destroyOnClose={true}
                        visible={editModalVisible}
                        title="修改密码"
                        onCancel={() => {
                            setEditModalVisible(false)
                        }}
                    >
                        <Form
                            labelCol={{span: 8}}
                            wrapperCol={{span: 16}}
                            form={editForm}
                            onFinish={(values) => {
                                update(values)
                            }}>
                        
                            <Form.Item
                                label="原密码"
                                name="oldPwd"
                                rules={[{ required: true, message: '请输入原密码' }]}>
                                <Input.Password iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                            </Form.Item>

                            <Form.Item
                                label="新密码"
                                name="newPwd"
                                rules={[{ required: true, message: '请输入新密码' }]}>
                                <Input.Password iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                            </Form.Item>

                            <Form.Item wrapperCol={{offset: 8, span: 16}}>
                                <Button type="primary" htmlType="submit">
                                    保存
                                </Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                    
                    <Menu theme="dark" mode="inline" inlineCollapsed={true}>
                        {
                            menu.map(index=>{
                                if(index.menuType=="m"){
                                    return(
                                        <SubMenu key={index.menuName} icon={<MyIcon icon={index.icon}/>} title={index.menuName}>
                                            {
                                                menu.map(index1=>{
                                                    if(index.id==index1.parentId && index1.menuType=="c"){
                                                        return(
                                                            <Menu.Item key={index1.key} onClick={from}>{index1.menuName}</Menu.Item>
                                                        )
                                                    }
                                                })
                                            }
                                        </SubMenu>
                                    )
                                }
                            })
                        }
                    </Menu>
                </Sider>
                <Layout className="site-layout">
                    <Layout className="header-left">
                        <ul className="u-left">
                            <li style={{color:"#fff",fontSize:25,width:50,textAlign:"center"}}  
                                onClick={toggleCollapsed}>{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
                            </li>
                        </ul>
                        <ul className="u-center">
                        </ul>
                        <ul className="u-right" onMouseLeave={()=>{
                            setShow(false);
                        }}>
                            <li style={{color:"#fff",width:160,height:50,textAlign:"center",paddingLeft:10,paddingRight:10,overflow:'hidden'}} onMouseEnter={()=>{
                                setShow(true);
                            }} className="u-right-user item">
                                <Avatar style={{backgroundColor:"#367fa9",marginRight:10}} size={35}>U</Avatar>{user.name}
                            </li>
                            {/* <li style={{color:"#fff",width:100,textAlign:"center"}} className="item">
                                <QuestionCircleFilled style={{marginRight:10}}/>文档
                            </li> */}
                            <ul className={ show ? 'dropdown-menu' : 'hide' } style={{zIndex:"9999"}}>
                                <li><a onClick={() => {
                                    setEditModalVisible(true);
                                }}><KeyOutlined style={{marginRight:10}} />修改密码</a></li>
                                <li><a onClick={logOut}><LogoutOutlined style={{marginRight:10}} />退出登录</a></li>
                            </ul>
                        </ul>
                    </Layout>

                    <Content style={{margin: '0 16px', padding: '16px 0'}}>

                        <Switch>
                            <Route path="/vendor" component={Vendor} />
                            <Route path="/popomain" component={PoPomain} />
                            <Route path="/user" component={User} />
                            <Route path="/notice" component={Notice} />
                            <Route path="/noticeType" component={NoticeType} />
                            <Route path="/sends" component={Sends} />
                            <Route path="/pdf/:id" component={Pdf} />
                            <Route path="/popodetails" component={Popodetails} />
                            <Route path="/popodetailsSave/:id" component={PopodetailsSave} />
                            <Route path="/sendsPrint/:id" component={SendsPrint} />
                            <Route path="/noticeView" component={NoticeView} />
                            <Route path="/indexs" component={Indexs} />
                            <Route path="/mrpViews" component={MrpViews} />
                            <Route path="/indexm" component={Indexm} />
                            <Route path="/mrpViewsm" component={MrpViewsm} />
                            <Route path="/warePrint" component={WarePrint} />
                            <Route path="/daohuo" component={Daohuo} />
                            <Route path="/printview/:autoId/:dataBase" component={PrintView} />
                            <Route path="/role" component={Role} />
                            <Route path="/" component={Home} />

                        </Switch>
                    </Content>
                    <Footer style={{textAlign: 'right',backgroundColor:'#fff'}}>© 2021 wijay Copyright</Footer>
                </Layout>
            </Layout>
        </div>
    );
}
export default Main;