import React, { useEffect, useState } from 'react';
import PaginationBar from "../../components/paginationbar";
import {Button, Form, Input, Modal, Table, Breadcrumb, Pagination,Row, Col,Switch, Space, Select} from "antd";
import common from "../../common";
import {
    DeleteFilled,LockFilled,UnlockOutlined,
    EditFilled, PlusOutlined, SearchOutlined, ReloadOutlined
} from '@ant-design/icons';
function Vendor(props) {
    let initSearch = {}
    let [dataList, setDataList] = useState([])
    let [search, setSearch] = useState(initSearch)       // 搜索内容
    const [searchForm] = Form.useForm();
    let [query, setQuery] = useState(initSearch);
    let [pagination, setPagination] = useState({
        total: 0,
        page: 1,
        limit: 10,
    })
    const {Option} = Select;
    //----- 弹框
    let [createModalVisible, setCreateModalVisible] = useState(false)
    let [editModalVisible, setEditModalVisible] = useState(false)
    let [createForm] = Form.useForm();
    let [editForm] = Form.useForm();
    const columns = [
        {
            title: '合格提供方',
            align: "center",
            width: 110,
            fixed: "right",
            render: (text, record) => {
                var label;
                if(record.isPass==2){
                    label=<Switch checkedChildren="ON" unCheckedChildren="OFF" onChange={()=>{
                        update({cvenmnemcode:record.cvenmnemcode,isPass:1})
                    }}/>
                }else if(record.isPass==1){
                    label=<Switch checkedChildren="ON" unCheckedChildren="OFF" defaultChecked onChange={()=>{
                        update({cvenmnemcode:record.cvenmnemcode,isPass:2})
                    }}/>
                }
                return (
                    <>
                        {label}
                    </>
                )
            }
        },
        {
            title: '简称',
            dataIndex: 'cvenabbname',
        },
        {
            title: 'ERP编号',
            dataIndex: 'cvencode',
        },
        {
            title: '登录账号',
            dataIndex: 'cvenmnemcode',
        },
        {
            title: '密码',
            dataIndex: 'pd',
        },
        {
            title: '邮箱',
            dataIndex: 'email',
        },
        // {
        //     title: '基本信息',
        //     align: "center",
        //     width: 100,
        //     fixed: "right",
        //     render: (text, record) => {
        //         return (
        //             <>
        //                 <div style={{width:70,height:22,backgroundColor:"#1ab394",borderRadius:"2px",color:"#ffffff"}}>基本信息</div>
        //             </>
        //         )
        //     }
        // },
        // {
        //     title: '附件信息',
        //     align: "center",
        //     width: 100,
        //     fixed: "right",
        //     render: (text, record) => {
        //         return (
        //             <>
        //                 <div style={{width:70,height:22,backgroundColor:"#1ab394",borderRadius:"2px",color:"#ffffff"}}>附件信息</div>
        //             </>
        //         )
        //     }
        // },
        // {
        //     title: '联系人信息',
        //     align: "center",
        //     width: 100,
        //     fixed: "right",
        //     render: (text, record) => {
        //         return (
        //             <>
        //                 <div style={{width:80,height:22,backgroundColor:"#1ab394",borderRadius:"2px",color:"#ffffff"}}>联系人信息</div>
        //             </>
        //         )
        //     }
        // },
        {
            title: '操作',
            align: "center",
            width: 200,
            fixed: "right",
            render: (text, record) => {
                var k;
                if(record.isShow==1){
                    k= <Button className="shanchu" onClick={() => {
                        common.confirm("确定要禁用吗?", () => {
                            stop(record.cvenmnemcode)
                        })
                    }}><LockFilled />禁用</Button>
                }else if(record.isShow==2){
                    k= <Button className="qiyon" onClick={() => {
                        common.confirm("确定要启用吗?", () => {
                            start(record.cvenmnemcode)
                        })
                    }}><UnlockOutlined />启用</Button>
                }
                return (
                    <>
                        <Button className="bianji" onClick={() => {
                            setEditModalVisible(true)
                            editForm.setFieldsValue(record)
                        }} ><EditFilled  />编辑</Button>
                        {k}
                    </>
                )
            }
        }
    ];
    // 获取供应商列表
    const getVendors = () => {
        common.loadingStart()
        let params = {
            ...search,
            page: pagination.page,
            limit: pagination.limit,
        }
        common.ajax("GET", "/passport/vendor/list", params).then(data => {
            setDataList(data.list)
            setPagination(data.pagination)
        }).catch((e) => {
            if(window.localStorage.getItem("user")){
                common.redirect()
            }else{
                if( window.location.href.indexOf("heyu.srm.wijay.cn") != -1){
                    // window.location = '/#/UserLogin'
                    props.history.push('/UserLogin')
                }else{
                    // window.location = '/#/VendorLogin'
                    props.history.push('/VendorLogin')
                }
            }
            common.loadingStop()
        }).finally(common.loadingStop)
    }
    const create = (data) => {
        common.loadingStart()
        common.ajax("POST", "/passport/vendor/create", data).then(() => {
            common.toast("操作成功")
            setCreateModalVisible(false)
            getVendors()
        }).finally(common.loadingStop)
    }
    const update = (data) => {
        common.loadingStart()
        common.ajax("POST", "/passport/vendor/update", data).then(() => {
            common.toast("操作成功")
            setEditModalVisible(false)
            getVendors()
        }).finally(common.loadingStop)
    }
    const stop = (cvenmnemcode) => {
        common.loadingStart()
        common.ajax("POST", "/passport/vendor/stop", {cvenmnemcode}).then(() => {
            common.toast("操作成功")
            getVendors()
        }).finally(common.loadingStop)
    }
    const start = (cvenmnemcode) => {
        common.loadingStart()
        common.ajax("POST", "/passport/vendor/start", {cvenmnemcode}).then(() => {
            common.toast("操作成功")
            getVendors()
        }).finally(common.loadingStop)
    }
    useEffect(() => {
        getVendors()
    }, [pagination.page, pagination.limit,search])
    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>供应商管理</Breadcrumb.Item>
                <Breadcrumb.Item>供应商列表</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{minHeight: 360}}>
                
                <Form form={searchForm} style={{marginTop: 15}} className='searchForm'>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item name="cvenmnemcode">
                                <Input placeholder="输入名称或账号"  onChange={(e) => {
                                    let value = common.replacezifu(e.target.value)
                                    setQuery({...query,cvenmnemcode: value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item>
                                <Space>
                                    <Button onClick={() => {
                                        setSearch(query)
                                    }} className='search-btn mybtn'><SearchOutlined />搜索</Button>
                                    <Button onClick={() => {
                                        let emptySearch = {
                                            cvenmnemcode: '',
                                        }
                                        searchForm.setFieldsValue(emptySearch)
                                        emptySearch = {}
                                        setSearch(emptySearch)
                                        setQuery(emptySearch)
                                    }} className='clean-btn mybtn'><ReloadOutlined />清空</Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div className='table-box'>
                    <Button type="primary" onClick={() => {
                        setCreateModalVisible(true)
                        createForm.setFieldsValue({title: ""})
                    }} className='add-btn mybtn'><PlusOutlined />新增</Button>
                    <Table rowKey={columns => columns.cvenmnemcode+new Date()} 
                        dataSource={dataList} columns={columns} pagination={false}
                        rowClassName={(record, idx) => {
                            if(idx % 2 === 1)
                                return 'bg-row';
                        }}/>
                </div>
                <Modal title="新增" destroyOnClose={true} footer={false} maskClosable={false} visible={createModalVisible} onCancel={() => {
                    setCreateModalVisible(false)
                }}
                >
                    <Form preserve={false}
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    form={createForm}
                    onFinish={(values) => {
                        create(values)
                    }}
                >
                    <Form.Item label="名称" name="cvenname" rules={[{required: true, message: '请填写名称'}]}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item label="ERP编号" name="cvencode" rules={[{required: true, message: '请填写ERP编号'}]}>
                         <Input disabled />
                    </Form.Item>
                    <Form.Item label="登录账号" name="cvenmnemcode" rules={[{required: true, message: '请填写登录账号'}]}>
                         <Input disabled />
                    </Form.Item>
                    <Form.Item label="密码" name="pd" rules={[{required: true, message: '请填写密码'}]}>
                         <Input/>
                    </Form.Item>
                    <Form.Item 
                        label="主邮箱" 
                        name="email" 
                        rules={[
                            {
                              type: 'email',
                              message: 'The input is not valid E-mail!',
                            },
                            {
                              required: true,
                              message: 'Please input your E-mail!',
                            },
                          ]}>
                         <Input/>
                    </Form.Item>
                    <Form.Item label="附邮箱" name="email2" rules={[{required: false, message: '请填主邮箱'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="备用邮箱" name="email3" rules={[{required: false, message: '请填主邮箱'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="备用邮箱" name="email4" rules={[{required: false, message: '请填主邮箱'}]}>
                         <Input/>
                    </Form.Item>
                    <Form.Item wrapperCol={{offset: 8, span: 16}}>
                        <Button type="primary" htmlType="submit">
                            保存
                        </Button>
                    </Form.Item>
                </Form>
                </Modal>
                <Modal title="编辑供应商信息" footer={false} maskClosable={false} destroyOnClose={true}  visible={editModalVisible} onCancel={() => {
                    setEditModalVisible(false)
                }}
                >
                    <Form
                        labelCol={{span: 5}}
                        wrapperCol={{span: 18}}
                        form={editForm}
                        onFinish={(values) => {
                            update(values)
                        }}
                    >
                        <Form.Item label="名称" name="cvenname" rules={[{required: true, message: '请填写名称'}]}>
                            <Input disabled />
                        </Form.Item>
                        <Form.Item label="ERP编号" name="cvencode" rules={[{required: true, message: '请填写ERP编号'}]}>
                            <Input disabled />
                        </Form.Item>
                        <Form.Item label="登录账号" name="cvenmnemcode" rules={[{required: true, message: '请填写登录账号'}]}>
                            <Input disabled />
                        </Form.Item>
                        <Form.Item label="密码" name="pd" rules={[{required: true, message: '请填写密码'}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="主邮箱" name="email" 
                            rules={[
                                {
                                  type: 'email',
                                  message: '请填写正确的邮箱格式!',
                                },
                                {
                                  required: true,
                                  message: '请填主邮箱!',
                                }]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="附邮箱" name="email2" 
                            rules={[
                                {
                                  type: 'email',
                                  message: '请填写正确的邮箱格式!',
                                }]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="备用邮箱" name="email3" 
                            rules={[
                                {
                                  type: 'email',
                                  message: '请填写正确的邮箱格式!',
                                }]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="备用邮箱" name="email4" 
                            rules={[
                                {
                                type: 'email',
                                message: '请填写正确的邮箱格式!',
                                }]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item wrapperCol={{offset: 10, span: 14}}>
                            <Button type="primary" htmlType="submit">
                                保存
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
                {pagination.total < 5 ? '' :
                    <PaginationBar>
                        <Pagination
                            style={{marginTop: 25}}
                            pageSizeOptions={['10', '20', '50', '100']}
                            onChange={(page, limit) => {
                                setPagination({...pagination, page, limit})
                            }}
                            onShowSizeChange={(page, limit) => {
                                setPagination({...pagination, page, limit})
                            }}
                            showTotal={total => `共${total}条`}
                            total={pagination.total}
                            defaultCurrent={pagination.page}
                            current={pagination.page}
                            defaultPageSize={pagination.limit}
                            showSizeChanger
                            showQuickJumper
                        /></PaginationBar>}
            </div>
        </div>
    );
}
export default Vendor;