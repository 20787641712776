import React, { Component } from 'react'
import {Button} from "antd";
import common from "../../common";
import {ExportOutlined} from '@ant-design/icons';
/*props:{
	method:请求类型
	api:请求地址
	query:请求参数条件
	dataList:导出的数据(写了这个上三个可以不要写)
	columns:表头
	name:文件名(最好与功能模块挂钩)
}*/
function Exportfile(props) {
		return(
			<div style={{display:"inline-block"}}>
				<Button onClick={()=>{
					var dataList=props.dataList
					//debugger
					// if(Object.getOwnPropertyNames(props.query).length>0){
						let params = {
							...props.query,
							// page: 0,
							// limit: 0,
						}
						//debugger
						common.ajax(props.method, props.api, params).then(data => {
							dataList=data.list
							common.downloadExl(dataList,"xlsx",props.columns,props.name)
						})
					    //debugger
					// }
					// common.downloadExl(dataList,"xlsx",props.columns,props.name)
				}} className='add-btn mybtn'>
					<ExportOutlined />导出</Button>
				<a href="" id={"hf"+props.name}></a>
			</div>
		)
}
export default Exportfile
