import React, { useEffect, useState } from 'react';
import {Button,Radio, Form, Input, Modal, Table, Breadcrumb, Pagination, Row, Col, Space, Select, DatePicker} from "antd";
import common from "../../common";
import moment from "moment"
import {
    ExclamationCircleFilled
} from '@ant-design/icons';
import './index.less'


function Update(props) {
    const today = new Date(),
    now_day = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    let [choice, setChoise] = useState(now_day)
    let [num, setNum] = useState('')
    const [editForm] = Form.useForm();
    let [pagination, setPagination] = useState({
        total: 0,
        page: 1,
        limit: 10,
    })
    
    const getPoPodetails = (a) => {
        common.loadingStart()
        var cvencode=JSON.parse(window.localStorage.getItem("user")).cvencode;
        let params = {
            cvencode:cvencode,
            id: props.match.params.id
        }
        common.ajax("GET", "/passport/poPodetails/byId",params).then(data => {
            console.log(data)
            editForm.setFieldsValue({...data});
            setNum(data.num)
        }).finally(common.loadingStop)
    }
    const save = (data) => {
        if(data.squantity <= 0){
            common.toast("请输入正确的发货数量")
            return;
        }
        data.cvenmnemcode = JSON.parse(window.localStorage.getItem("user")).cvenmnemcode;
        data.cvencode = JSON.parse(window.localStorage.getItem("user")).cvencode;
        data.pdate = choice;
        console.log(data);
        common.loadingStart()
        common.ajax("POST", "/passport/poPodetails/save", data).then(res => {
            common.toast("操作成功")
            props.history.push('/sendsPrint/' + res)
        }).finally(common.loadingStop)
    }
    useEffect(() => {
        getPoPodetails();
    }, [pagination.page, pagination.limit])

    return (
        <div>
            
            <Breadcrumb>
                <Breadcrumb.Item>订单管理</Breadcrumb.Item>
                <Breadcrumb.Item><a href="/#/popodetails">发货管理</a></Breadcrumb.Item>
                <Breadcrumb.Item>发货</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background updateModel" style={{minHeight: 360}} >
                <Form form={editForm} style={{marginTop: 15,backgroundColor:'#fff',padding: 24,borderRadius:10}}
                      labelCol={{ span: 5 }}
                      wrapperCol={{ span: 20 }}
                      onFinish={(values) => {
                        save(values)
                    }}
                >
                <div style={{width:"40%",marginLeft:"8%",paddingLeft:"150px",display:"inline-block"}}>
                        <Form.Item name="ufdsys" hidden>
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item name="sid" hidden>
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item name="poid" hidden>
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            label="产品图号"
                            name="cinvcode"
                        >
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            label="产品规格"
                            name="cinvstd"
                        >
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            label="订单号"
                            name="cpoid"
                        >
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            label="生产日期"
                            name="pdate"
                        >
                            <DatePicker defaultValue={moment(now_day)} onChange={(value,dateString)=>{
                                setChoise(dateString)
                            }} placeholder="生产日期" style={{width:'100%'}}/>
                        </Form.Item>
                        <Form.Item
                            label="追溯批号"
                            name="cdefine25"
                            rules={[{ 
                                required: false,
                                message: '请填写追溯批号' }]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="物流信息"
                            name="wl"
                        >
                            <Input placeholder='物流信息'/>
                        </Form.Item>
                        <Form.Item
                            label="版本号"
                            name="cinvdefine1"
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item style={{textAlign:'center',marginTop:20}}>
                            <Button type="primary" htmlType="submit" onClick={()=>{}}>
                                保存
                            </Button>
                        </Form.Item>
                </div>
                <div style={{width:"40%",marginRight:"8%",paddingRight:"150px",float:"right"}}>
                        <Form.Item
                            label="产品名称"
                            name="cinvname"
                        >
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            label="单位"
                            name="ccomunitname"
                        >
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            label="送货数量"
                            name="squantity" style={{marginBottom:0}}>
                            <Form.Item
                                noStyle
                                label="送货数量"
                                name="squantity"
                                rules={[{ 
                                    required: true,
                                    message: '请输入送货数量' }]}>
                                <Input type={"number"} max={num} placeholder='请输入送货数量'/>
                            </Form.Item>
                            <span className='squantity-tips' style={{color:'#737373'}}><ExclamationCircleFilled style={{marginRight : 10}}/>数量大于0,不超过可送数量{num}</span>
                        </Form.Item>
                        <Form.Item
                            label="周码"
                            name="week"
                        >
                            <Input placeholder='周码'/>
                        </Form.Item>
                        <Form.Item
                            label="送货编号"
                            name="sorder"
                        >
                            <Input placeholder='送货编号'/>
                        </Form.Item>
                        <Form.Item
                            label="司机信息"
                            name="sj"
                        >
                            <Input placeholder='司机信息'/>
                        </Form.Item>
                        <Form.Item
                            label="变更首次"
                            name="first"
                        >
                            <Radio.Group>
                                <Radio value="1">是</Radio>
                                <Radio value="2">否</Radio>
                            </Radio.Group>
                        </Form.Item>
                </div>
                </Form>
            </div>
        </div>
    );
}
export default Update;