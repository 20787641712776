import React, {useEffect, useState} from 'react'
import {Breadcrumb,DatePicker, Space, Button, Pagination ,Select , Form, Modal , Upload , Row, Col , Input , Table} from 'antd'
import {BarChartOutlined , UploadOutlined , ReloadOutlined , SearchOutlined } from "@ant-design/icons";
import moment from 'moment';
import common from '../../common';
import PaginationBar from "../../components/paginationbar";
import * as XLSX from 'xlsx';
import axios from 'axios';

import Views from "./views";

var name="公告管理"
function Indexm(props) {
    const { RangePicker } = DatePicker;
    let [pagination, setPagination] = useState({
        total: 0,
        page: 1,
        limit: 10,
    })
    const {Option} = Select;
    let [createModalVisible, setCreateModalVisible] = useState(false)
    let [createForm] = Form.useForm();
    
    let [fileList, setFileList] = useState([]);
    let [fileData,setFileData] = useState([]);
    
    let [createData,setCreateData] = useState({
        one:[],
        two:[],
        three:[],
        four:[],
        after:'',
        table:"UFDATA_777_2018",
    });

    let [details,setDetails] = useState([]);

    const compute = () => {
        common.loadingStart();
        let params = {
            ...createData,
            fileData : fileData
        }
        let urlData = {
            token : common.getToken(),
            type : common.getType()
        }
        setCreateModalVisible(false)
        
        axios({
            headers: {'Content-Type': 'application/json'},
            method: 'POST',
            url: common.getApiUrl("/passport/mrp/indexm", urlData),
            data: params,
            contentType: "application/json;charset=UTF-8",
            timeout: 20000
        }).then((response) => {
            if (response.data.code === 'SUCCESS') {
                window.localStorage.setItem("indexm", JSON.stringify(createData))
                common.toast("计算完成")
                setCreateModalVisible(false)
                props.history.push('/mrpViewsm')
            }
            common.loadingStop()
        }).catch(()=>{
            common.loadingStop()
        })
    }

    const dowloads = () => {
        window.location.href='/uploads/bzmb.xlsx'
    }


    const handleChange = (info) => {
        let fileList = [...info.fileList];
        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        // fileList = fileList.slice(-2);
    
        // 2. Read from response and show file link
        fileList = fileList.map(file => {
        //   if (file.response) {
        //     let data = file.response.data
        //     file.url = data.savepath + data.savename;
        //     file.id = data.id;
        //   }
            file.status = 'done'
          return file;
        });
    
        setFileList(fileList);

    };
    const beforeUpload = (file) => {
        let data = [];// 存储获取到的数据

        // 通过FileReader对象读取文件

        const fileReader =new FileReader();

        fileReader.readAsBinaryString(file);//二进制

        fileReader.onload = event => {

            try {

                const {result } = event.target;

                // 以二进制流方式读取得到整份excel表格对象

                const workbook = XLSX.read(result, {type:'binary' });

                // 遍历每张工作表进行读取（这里默认只读取第一张表）
                const Sheet2JSONOpts = {
                    range: 1,
                    header :['account','so_code','cinvcode','neetqty','dpredate'],
                    raw:false
                };
                
                for (const sheet in workbook.Sheets) {

                    if (workbook.Sheets.hasOwnProperty(sheet)) {

                        // 利用 sheet_to_json 方法将 excel 转成 json 数据
                        data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet],Sheet2JSONOpts));

                        break; // 如果只取第一张表，就取消注释这行

                    }

                }
                console.log(data);
                setFileData(data);

            }catch (e) {

                // 这里可以抛出文件类型错误不正确的相关提示

                console.log('文件类型不正确');

                return;

            }

        };

    }

    return (
        <>
        <div className="Workshop">
            <Breadcrumb>
                <Breadcrumb.Item>MRP计算器</Breadcrumb.Item>
                <Breadcrumb.Item>工单缺料</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{minHeight: 600}}>
                
                <div className='table-box' style={{marginTop: 15,height:600,textAlign:'center',lineHeight:'50px'}}>
                    <Button type="primary" onClick={() => {
                        setCreateModalVisible(true)
                    }} className='chakan' style={{fontSize:24,height:'50px',position: 'relative',top:'40%'}}>
                        <BarChartOutlined />MRP计算器工单缺料
                    </Button>
                </div>
            </div>
            <Modal title="MRP计算" destroyOnClose={true} footer={false} maskClosable={false} visible={createModalVisible}
                   onCancel={() => {
                        setCreateModalVisible(false)
                   }}>
                <Form preserve={false}
                      labelCol={{span: 4}}
                      wrapperCol={{span: 20}}
                      form={createForm}
                      onFinish={(values) => {
                        compute()
                      }}
                    >
                    <Form.Item
                        label="账套"
                        name="table"
                        rules={[{required: true, message: '账套选择'}]}
                        initialValue="UFDATA_777_2018"
                    >
                        <Select placeholder="账套" onChange={value => {
                            setCreateData({...createData,table:value})
                        }}>
                            <Option key='1' value='UFDATA_777_2018'>UFDATA_777_2018</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="工单号"
                        name="mocode"
                        rules={[{required: true, message: '请输入工单号'}]}
                    >
                        <Input onChange={(e)=>{setCreateData({...createData,mocode:e.target.value})}}/>
                    </Form.Item>
                    <Form.Item
                        label="日期"
                        name="one"
                        rules={[{required: true, message: '日期选择'}]}
                    >
                        <RangePicker onChange={(value)=>{
                                    if(value != null){
                                        setCreateData({...createData,
                                            one:[moment(value[0]).format('YYYY-MM-DD'),moment(value[1]).format('YYYY-MM-DD')]})
                                    }else{
                                        setCreateData({...createData,one:[]})
                                    }
                                }
                            }/>
                    </Form.Item>
                            
                    
                    <Form.Item wrapperCol={{offset: 4, span: 20}}>
                        <Button type="primary" htmlType="submit" >
                            计算
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
           
        </div>
        </>
        
    );
}
export default Indexm;
