import React, {useEffect, useState} from 'react';
import { Card, Avatar } from 'antd';
import './index.less';
import common from "../../common"
import { Link } from 'react-router-dom'

function Home(props) {
    let [user, setUser] = useState({name: ""})
    let [dataList, setDataList] = useState([]) 

    let [pagination, setPagination] = useState({
        total: 0,
        page: 1,
        limit: 5,
    })

    const getDataList = () => {
        let params = {
            isRed : 2,
            isTop : 2,
            page: pagination.page,
            limit: pagination.limit,
        }
        common.ajax("GET", "/passport/wjNotice/list", params).then(data => {
            setDataList(data.list)
            setPagination(data.pagination)
        })
    }

    useEffect(() => {
        getDataList()
    }, [pagination.page, pagination.limit])


    return (
        <div>
            <Card style={{width:'100%',display:'inline-block'}} headStyle={{color:'red',fontSize:'24px',fontWeight:'bolder'}} title="集团红头文件" 
                extra={<Link to="noticeView">查看其它公告</Link>}>
                    <table style={{width:'100%'}} className='card-content'>
                        <tbody>
                        {
                            dataList.map((item,key) => {
                                return (
                                    <tr className='card-tr' key={key}>
                                        <td className='card-td'>
                                            <Link style={{color:'#000'}} target = "_blank" to={{pathname:`/pdf/${item.id}`}}>
                                                {item.title}
                                            </Link>
                                        </td>
                                        <td className='card-td'>{item.username}</td>
                                        <td className='card-td'>{item.cTime}</td>
                                        <td className='card-td notice-type'>红头文件</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                    
            </Card>
            {/* <Card style={{width:'48%',display:'inline-block'}} title="卡片标题">卡片内容</Card> */}
        </div>
    );
}
export default Home;