import React, { useEffect, useState } from 'react';
import { Pagination, Breadcrumb, Button, Select, Input, Form, Row, Col, Modal, Checkbox, Table, InputNumber } from 'antd';
import common from "../../common"
import "./index.css"
import PaginationBar from "../../components/paginationbar";
import Dendrogram from "../../components/button/Dendrogram"
function Role(props) {
const [newsList, setNewsList] = useState([])
const [searchForm] = Form.useForm();
let [search, setSearch] = useState({})       //搜索内容
let [pagination, setPagination] = useState({
    total: 0,
    page: 1,
    limit: 10,
})
const [createModalVisible, setCreateModalVisible] = useState(false)
const [editModalVisible, setEditModalVisible] = useState(false)
const [particularsVisible, setparticularsVisible] = useState(false)
const [roleDataList, setRoleDataList] = useState([
    { label: 'Apple', value: 1 },
    { label: 'Pear', value: 2 },
    { label: 'xxx', value: 3 },
    { label: 'Orange', value: 4 }])
const [createForm] = Form.useForm();
const [editForm] = Form.useForm();
const [userList, setUserList] = useState([])
const {Option} = Select;
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: '角色名称',
            dataIndex: 'name',
        },
        {
            title: '操作',
            align: "center",
            width: 270,
            fixed: "right",
            render: (text, record, index) => {
                return (
                    <>
                        <Button size={'small'} type={'link'} onClick={() => {
                            let ulist = record.userList
                            let userIds = []
                            if (ulist != null){
                                ulist.map((item,key)=>{
                                    userIds.push(item.id)
                                    console.log(item);
                                })
                            }
                            record.userIds = userIds
                            setEditModalVisible(true)
                            editForm.setFieldsValue(record)
                        }}>编辑</Button>
                        <Button size={'small'} type={'link'} onClick={() => {
                            common.confirm("确定要删除吗?", () => {
                                del(record.id)
                            })
                        }}>删除</Button>
                    </>
                )
            }
        }
    ];
    const init = () => {
        let params = {
            page: pagination.page,
            limit: pagination.limit,
            ...search
        }
        common.ajax("GET", "/passport/role/list", params).then(data => {
            setNewsList(data.list)
            setPagination(data.pagination)
        })
    }

    const getUserList = () => {
        let params = {
            page: 1,
            limit: 1000,
        }
        common.ajax("GET", "/passport/user/list", params).then(data => {
            setUserList(data.list)
        })
    }

    const create = (data) => {
        if(data.userIds != null){
            data.userIds = data.userIds.join(',') 
        }
        common.ajax("POST", "/passport/role/create", data).then(res => {
            common.toast("操作成功")
            setCreateModalVisible(false)
            init()
        })
    }
    const update = (data) => {
        data.userIds = data.userIds.join(',') 
        console.log(data);
        common.ajax("POST", "/passport/role/update", data).then(res => {
            common.toast("操作成功")
            setEditModalVisible(false)
            init()
        })
    }
    const del = (id) => {
        common.loadingStart()
        common.ajax("POST", "/passport/role/delete", { id }).then(res => {
            common.toast("操作成功")
            init()
        }).finally(common.loadingStop)
    }
    useEffect(() => {
        init()
    }, [pagination.page, pagination.limit, search])

    useEffect(() => {
        getUserList()
    }, [])

    return (
        <div className="UserRole">
            <Breadcrumb>
                <Breadcrumb.Item>用户管理</Breadcrumb.Item>
                <Breadcrumb.Item>角色管理</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{ padding: 24, minHeight: 600 }}>
                <div style={{ marginBottom: 20}}>
                    <Button type="primary" onClick={() => {
                        setCreateModalVisible(true)
                        createForm.setFieldsValue({ title: "" })
                    }}>新增</Button>
                </div>
                <Table rowKey={columns => columns.id} dataSource={newsList} columns={columns} pagination={false} />
                {pagination.total < 5 ? '' :
                    <PaginationBar>
                        <Pagination
                            style={{ marginTop: 25 }}
                            pageSizeOptions={['10', '20', '50', '100']}
                            onChange={(page, limit) => {
                                setPagination({ ...pagination, page, limit })
                            }}
                            onShowSizeChange={(page, limit) => {
                                setPagination({ ...pagination, page, limit })
                            }}
                            showTotal={total => `共${total}条`}
                            total={pagination.total}
                            defaultCurrent={pagination.page}
                            current={pagination.page}
                            defaultPageSize={pagination.limit}
                            showSizeChanger
                            showQuickJumper
                        /></PaginationBar>}
            </div>
            <Modal title="新增" destroyOnClose={true} footer={false} maskClosable={false} visible={createModalVisible} onCancel={() => {
                setCreateModalVisible(false)
            }}
            >
                <Form preserve={false}
                    form={createForm}
                    onFinish={(values) => {
                        create(values)
                    }}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                >
                    <Form.Item
                        label="角色名称"
                        name="name"
                        rules={[{ required: true, message: '请输入标题' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="员工" name="userIds">
                        <Select placeholder="请选择员工" allowClear showSearch optionFilterProp="children" mode="multiple" 
                            // onChange={(event)=>onChange(item.eventId,event)}
                            >
                            {userList.map(t => {
                                return (<Option key={t.id} value={t.id}> {t.realName}</Option>)
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            保存
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal title="编辑" footer={false} maskClosable={false} visible={editModalVisible} onCancel={() => {
                setEditModalVisible(false)
            }}
            >
                <Form
                    form={editForm}
                    onFinish={(values) => {
                        console.log(values)
                        update(values)
                    }}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                >
                    <Form.Item
                        hidden={true}
                        name="id"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item

                        label="角色名称"
                        name="name"
                        rules={[{ required: true, message: '请输入标题' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="员工" name="userIds">
                        <Select placeholder="请选择员工" allowClear showSearch optionFilterProp="children" mode="multiple" 
                            // onChange={(event)=>onChange(item.eventId,event)}
                            >
                            {userList.map(t => {
                                return (<Option key={t.id} value={t.id}> {t.realName}</Option>)
                            })}
                        </Select>
                    </Form.Item>
                    {/* <Form.Item
                        label="状态"
                        name="status"
                    >
                        <Input type="number" />
                    </Form.Item> */}
                    {/* <Form.Item
                        label="菜单权限"
                        name="id"
                    >
                        <Dendrogram name={editForm} />
                    </Form.Item> */}
                    <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
                        <Button type="primary" htmlType="submit">
                            保存
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}
export default Role;
