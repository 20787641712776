import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {Button,DatePicker, Form, Input, Checkbox , Table, InputNumber, Pagination,Row, Col,Switch, Space, Select} from "antd";
import common from "../../common";
import './index.less'
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
function PrintView(props) {

    const today = new Date(),
    now_day = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    let [dataList,setDataList] = useState([])
    let [user,setUser] = useState([])
    const [searchForm] = Form.useForm();
    
    const {Option} = Select;

    const getData = () => {
        var user=JSON.parse(window.localStorage.getItem("user"));
        setUser(user)
        let params = {
            ...props.match.params
        }
        common.loadingStart()
        common.ajax("GET", "/passport/puArrivalvouchs/list", params).then(data => {
            let dataList = data.list[0];
            console.log(data);
            dataList.ddate = moment(dataList.ddate)
            dataList.checkdate = now_day
            dataList.remark = "无公害"
            dataList.cinvdefine2 = '1'

            if(dataList.cdefine22 == null || dataList.cdefine22 == '' || dataList.cdefine22 == undefined){
                dataList.cdefine22 = getCinvdefine2();
            }
            setDataList(dataList)
            common.loadingStop()
            searchForm.setFieldsValue(dataList)
        })
    }
    
    //创建验收单编号 P年份 + 年月日时分秒
    const getCinvdefine2 = () => {
        let date = new Date();
        let cin = moment(date).format('YYMMDDHHmmss')
        return "P" + cin
    }


    const download = () => {

        let data = {...dataList};
        console.log(data);
        if(data.cinvdefine2 == '1'){
            var element = document.getElementById("a4Demo");    // 这个dom元素是要导出pdf的div容器
        }else{
            var element = document.getElementById("weiwai");    // 这个dom元素是要导出pdf的div容器
        }

        if(data.cdefine22 == undefined || data.cdefine22 == ''){
            common.toast(data.cinvdefine2 == '1' ? '请填写验收单号' : '请填写加工单号')
            return
        }
        
        let params = {
            pid : props.match.params.autoId,
            cdefine22 : data.cdefine22,
            cordercode : data.cordercode,
            ufdata : props.match.params.dataBase,
            isFinal : data.isFinal == true ? 1 : 0 ,
            remark : data.remark
        }
        // console.log(params);
        common.ajax("Post", "/passport/accprintload/create", params).then(data => {
            var canvas = document.createElement("canvas");
            var context = canvas.getContext("2d");

            html2canvas(element,{
                width: 1000,
                height: 600,
                allowTaint: true,
                scale: 3 // 提升画面质量，但是会增加文件大小
            }).then(function (canvas) {
                var pageData = canvas.toDataURL('image/jpeg', 1.0);
                var pdf = new jsPDF('l', 'px',[440,280]);
                pdf.addImage(pageData, 'JPEG', 0, 0, 440,280);
                pdf.output('dataurlnewwindow');
            });
        })
        

        
    };

    useEffect(() => {
        getData();
    }, [])

    let none = {margin:"0",border:"0",padding:"0",height:"0",width:"0",overflow:'hidden'}
    let vis = {width:'1000px',margin:'auto'}
    let [yanshou,setYanshou] = useState({...vis})
    let [wai,setWai] = useState({...none})

    return (
        <div style={{backgroundColor:'#fff',height:'100%'}}>
        <div style={{width:'80%',margin:'auto'}}>
            <div className="site-layout-background" style={{minHeight: 80}}>
                <h3 style={{paddingTop:'20px'}}>验收单打印</h3>
                <Row gutter={24}>
                    <Col span={5}>
                        <Form.Item name="cinvdefine2">
                            <Select placeholder="账套" defaultValue='1' showSearch onChange={(value)=>{
                                if(value == 1){
                                    setYanshou({...vis});
                                    setWai({...none})
                                }else{
                                    setYanshou({...none});
                                    setWai({...vis})
                                }
                                setDataList({...dataList,cinvdefine2: value})
                            }}>
                                <Option value="1">验收单</Option>
                                <Option value="2">委外验收单</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={15} style={{textAlign:'end'}}>
                        <Button style={{backgroundColor:"#1ab394",color:'#fff'}} onClick={() => {
                            download();
                        }} >打印验收单</Button>
                    </Col>
                </Row>
            </div>
            {/* style={{margin:"0",border:"0",padding:"0",height:"0",width:"0",overflow:'hidden'}} */}
            <div id='a4Demo' style={{...yanshou}}>
                <Form form={searchForm} style={{marginTop: 15}} className='searchForm'>
                <table style={{width:'100%'}} >
                    <thead>
                        <tr >
                            <th colSpan="2" style={{fontSize:'25px'}}>验  收  单</th>
                        </tr>
                    </thead>
                    <tbody style={{textAlign:'left',fontSize:'17px'}}>
                        <tr>
                            <th><span className="line13">厂商名称:</span> 
                                <Form.Item name="cvenname" style={{width:'70%'}} className='table-input' getValueFromEvent={common.getValueFromEvent}>
                                    <Input  onChange={(e) => {
                                            let value = common.replacezifu(e.target.value)
                                            setDataList({...dataList,cvenname: value})
                                            // setQuery({...query,cvenname: value})
                                    }}/>
                                </Form.Item>
                                
                            </th>
                            <th style={{textAlign:"right"}}><span className="line13">验收单编号:</span> 
                                <Form.Item name="cdefine24" className='table-input' style={{width:"50%"}} getValueFromEvent={common.getValueFromEvent}>
                                    <Input  onChange={(e) => {
                                            let value = common.replacezifu(e.target.value)
                                            setDataList({...dataList,cdefine24: value})
                                            // setQuery({...query,cdefine22: value})
                                    }}/>
                                </Form.Item>
                            </th>
                        </tr>
                    </tbody>
                </table>
                <table style={{width:'100%',fontSize:'15px'}} id="table-main">
                    <tbody style={{textAlign:'center'}}>
                        <tr className="line13">
                            <th>品 名</th>
                            <th><Form.Item name="cinvname" className='table-input' getValueFromEvent={common.getValueFromEvent}>
                                    <Input  onChange={(e) => {
                                            let value = common.replacezifu(e.target.value)
                                            setDataList({...dataList,cinvname: value})
                                            // setQuery({...query,cinvname: value})
                                    }}/>
                                </Form.Item></th>
                            <th >图 号</th>
                            <th style={{width:'22%'}}><Form.Item name="cinvcode" className='table-input' getValueFromEvent={common.getValueFromEvent}>
                                    <Input  onChange={(e) => {
                                            let value = common.replacezifu(e.target.value)
                                            setDataList({...dataList,cinvcode: value})
                                            // setQuery({...query,cinvcode: value})
                                    }}/>
                                </Form.Item></th>
                            <th >交货日期</th>
                            <th colSpan='2'>
                                <Form.Item
                                    name="ddate"
                                    className='table-input'
                                >
                                <DatePicker onChange={(value,dateString)=>{
                                    // setChoise(dateString)
                                    setDataList({...dataList,ddate: value})

                                }} placeholder="交货日期"/>
                            </Form.Item></th>
                        </tr>
                        <tr>
                            <th>交货数量</th>
                            <th><Form.Item style={{width:'100%',fontSize:20}} name="iquantity" className='table-input' >
                                    <InputNumber style={{width:'100%'}} min='0.01' step="0.01" placeholder="交货数量" className='table-input'  onChange={(e) => {
                                        setDataList({...dataList,iquantity: e})
                                        // setQuery({...query,cinvcode: value})
                                    }}/>
                                </Form.Item>
                                </th>
                            <th>采购编号</th>
                            <th><Form.Item name="cordercode" className='table-input' >
                                    <Input className='table-input'  onChange={(e) => {
                                            setDataList({...dataList,cordercode: e})
                                            // setQuery({...query,cinvcode: value})
                                    }}/>
                                </Form.Item>
                                </th>
                            <th>是否按期</th>
                            <th colSpan='2'>√</th>
                        </tr>
                        <tr>
                            <th rowSpan='2'>检 验 判 定</th>
                            <th>合格</th>
                            <th>√</th>
                            <th rowSpan='2'>不 良 情 况</th>
                            <th rowSpan='2'>
                                <Form.Item name="bad_remark" className='table-input' >
                                    <Input className='table-input'  onChange={(e) => {
                                            let value = common.replacezifu(e.target.value)
                                            setDataList({...dataList,bad_remark: value})
                                            // setQuery({...query,cinvcode: value})
                                    }}/>
                                </Form.Item></th>
                            <th rowSpan='2' style={{width:'5%'}}>处 理</th>
                            <th rowSpan='2'>
                                <Form.Item name="todo" className='table-input' >
                                    <Input className='table-input'  onChange={(e) => {
                                            let value = common.replacezifu(e.target.value)
                                            setDataList({...dataList,todo: value})
                                            // setQuery({...query,cinvcode: value})
                                    }}/>
                                </Form.Item></th>
                        </tr>
                        <tr>
                            <th>不合格</th>
                            <th></th>
                        </tr>
                        <tr>
                            <th>入库数</th>
                            <th>单价（￥）</th>
                            <th>总价（￥）</th>
                            <th>单价（$）</th>
                            <th>总价（￥）</th>
                            <th></th>
                            <th>税率</th>
                        </tr>
                        <tr>
                            <th><Form.Item style={{width:'100%'}} name="iqt" className='table-input' >
                                    <InputNumber style={{width:'100%'}} min='0.01' step="0.01" className='table-input'  onChange={(e) => {
                                        setDataList({...dataList,iqt: e})
                                        // setQuery({...query,cinvcode: value})
                                    }}/>
                                </Form.Item></th>
                            <th><Form.Item style={{width:'100%'}} name="cimoney" className='table-input' >
                                    <InputNumber style={{width:'100%'}} min='0.01' step="0.01" className='table-input'  onChange={(e) => {
                                        setDataList({...dataList,cimoney: e})
                                        // setQuery({...query,cinvcode: value})
                                    }}/>
                                </Form.Item></th>
                            <th><Form.Item style={{width:'100%'}} name="ctmoney" className='table-input' >
                                    <InputNumber style={{width:'100%'}} min='0.01' step="0.01" className='table-input'  onChange={(e) => {
                                        setDataList({...dataList,ctmoney: e})
                                        // setQuery({...query,cinvcode: value})
                                    }}/>
                                </Form.Item></th>
                            <th><Form.Item style={{width:'100%'}} name="mimoney" className='table-input' >
                                    <InputNumber style={{width:'100%'}} min='0.01' step="0.01" className='table-input'  onChange={(e) => {
                                        setDataList({...dataList,mimoney: e})
                                        // setQuery({...query,cinvcode: value})
                                    }}/>
                                </Form.Item></th>
                            <th><Form.Item style={{width:'100%'}} name="mtmoney" className='table-input' >
                                    <InputNumber style={{width:'100%'}} min='0.01' step="0.01" className='table-input'  onChange={(e) => {
                                        setDataList({...dataList,mtmoney: e})
                                        // setQuery({...query,cinvcode: value})
                                    }}/>
                                </Form.Item></th>
                            <th></th>
                            <th><Form.Item style={{width:'100%'}} name="itaxrate" className='table-input' >
                                    <InputNumber style={{width:'100%'}} min='0.01' step="0.01" className='table-input'  onChange={(e) => {
                                        setDataList({...dataList,itaxrate: e})
                                        // setQuery({...query,cinvcode: value})
                                    }}/>
                                </Form.Item></th>
                        </tr>
                        <tr>
                            <th rowSpan='2'>备注</th>
                            <th colSpan='2'>
                                <Form.Item name="remark" className='table-input' >
                                    <Input className='table-input'  onChange={(e) => {
                                            let value = common.replacezifu(e.target.value)
                                            setDataList({...dataList,remark: value})
                                            // setQuery({...query,cinvcode: value})
                                    }}/>
                                </Form.Item></th>
                            <th rowSpan='2'>仓库</th>
                            <th rowSpan='2'>
                                <Form.Item name="cwhname" className='table-input' >
                                    <Input className='table-input'  onChange={(e) => {
                                            let value = common.replacezifu(e.target.value)
                                            setDataList({...dataList,cwhname: value})
                                            // setQuery({...query,cinvcode: value})
                                    }}/>
                                </Form.Item></th>
                            <th rowSpan='2'>进料<br />检验</th>
                            <th rowSpan='2'>{now_day}</th>
                        </tr>
                        <tr>
                            <th colSpan='2'>变更首次单价在方框内打√:
                                <Form.Item name="isFinal" className='table-input' >
                                    <Checkbox className='table-input'  onChange={(e) => {
                                        setDataList({...dataList,isFinal: e.target.checked})
                                }}/>
                            </Form.Item></th>
                        </tr>
                        
                    </tbody>
                </table>
                <table style={{width:'100%'}}>
                    <tr>
                        <th style={{width:'65%',textAlign:'left'}}>保存期限（ISO）3年</th>
                        <th style={{width:'20%'}}>制单 : {user.realName}</th>
                        <th className='table-foot-1'>FM-SP06-06</th>
                        <th className='table-foot-2'>2.0</th>
                    </tr>       
                </table>
                </Form>
            </div>
            
            <div id='weiwai' style={{...wai}}>
                <Form form={searchForm} style={{marginTop: 15}} className='searchForm'>
                <table style={{width:'100%'}} >
                    <thead>
                        <tr >
                            <th colSpan="3" style={{fontSize:'25px'}}>委 外 加 工 验 收 单</th>
                        </tr>
                    </thead>
                    <tbody style={{textAlign:'left',fontSize:'17px'}}>
                        <tr>
                            <th></th>
                            <th><span className='line13'>发票号数:</span>
                                <Form.Item name="fapiaohao" className='table-input' getValueFromEvent={common.getValueFromEvent}>
                                    <Input  onChange={(e) => {
                                            let value = common.replacezifu(e.target.value)
                                            setDataList({...dataList,fapiaohao: value})
                                            // setQuery({...query,cvenname: value})
                                    }}/>
                                </Form.Item>
                            </th>
                            <th><span className='line13'>加工单号:</span> 
                                <Form.Item name="cdefine22" className='table-input' getValueFromEvent={common.getValueFromEvent}>
                                    <Input  onChange={(e) => {
                                            let value = common.replacezifu(e.target.value)
                                            setDataList({...dataList,cdefine22: value})
                                            // setQuery({...query,cdefine22: value})
                                    }}/>
                                </Form.Item>
                            </th>
                        </tr>
                        <tr>
                            <th><span className='line13'>外加工单位:</span> 
                                <Form.Item name="cvenname" style={{width:'70%'}} className='table-input' getValueFromEvent={common.getValueFromEvent}>
                                    <Input  onChange={(e) => {
                                            let value = common.replacezifu(e.target.value)
                                            setDataList({...dataList,cvenname: value})
                                            // setQuery({...query,cvenname: value})
                                    }}/>
                                </Form.Item>
                                
                            </th>
                            <th><span className='line13'>提单号数:</span>  
                                <Form.Item name="tidanhao" className='table-input' getValueFromEvent={common.getValueFromEvent}>
                                    <Input  onChange={(e) => {
                                            let value = common.replacezifu(e.target.value)
                                            setDataList({...dataList,tidanhao: value})
                                            // setQuery({...query,cdefine22: value})
                                    }}/>
                                </Form.Item>
                            </th>
                            <th>
                                {now_day}
                            </th>
                        </tr>
                    </tbody>
                </table>
                <table style={{width:'100%',fontSize:'15px'}} border="1">
                    <tbody style={{textAlign:'center'}}>
                        <tr>
                            <td colSpan="3" style={{width:'35%'}}>材料</td>
                            <td rowSpan="2" style={{width:'5%'}}>单位</td>
                            <td colSpan="2" >数量</td>
                            <td colSpan="2" >加工费</td>
                            <td rowSpan="2" >原发料金额</td>
                            <td rowSpan="2" >合计</td>
                        </tr>
                        <tr>
                            <td style={{width:'15%'}}>零件图号</td>
                            <td style={{width:'10%'}}>名称</td>
                            <td style={{width:'15%'}}>订单编号</td>
                            <td >发出</td>
                            <td >实收</td>
                            <td >单价</td>
                            <td >金额</td>
                        </tr>
                        <tr>
                            <td style={{width:'15%'}}><Form.Item name="cinvcode" className='table-input' >
                                <Input className='table-input'  onChange={(e) => {
                                        let value = common.replacezifu(e.target.value)
                                        setDataList({...dataList,cinvcode: value})
                                        // setQuery({...query,cinvcode: value})
                                }}/></Form.Item></td>
                            <td style={{width:'10%'}}><Form.Item name="cinvname" className='table-input' >
                                <Input className='table-input'  onChange={(e) => {
                                        let value = common.replacezifu(e.target.value)
                                        setDataList({...dataList,cinvname: value})
                                        // setQuery({...query,cinvcode: value})
                                }}/></Form.Item></td>
                            <td style={{width:'15%'}}><Form.Item name="cordercode" className='table-input' >
                                <Input className='table-input'  onChange={(e) => {
                                        let value = common.replacezifu(e.target.value)
                                        setDataList({...dataList,cordercode: value})
                                        // setQuery({...query,cinvcode: value})
                                }}/></Form.Item></td>
                            <td><Form.Item name="ccomunitname" className='table-input' >
                                <Input className='table-input'  onChange={(e) => {
                                        let value = common.replacezifu(e.target.value)
                                        setDataList({...dataList,ccomunitname: value})
                                        // setQuery({...query,cinvcode: value})
                                }}/></Form.Item></td>
                            <td><Form.Item name="total" className='table-input' >
                                    <InputNumber min='0.01' step="0.01" className='table-input'  onChange={(e) => {
                                        setDataList({...dataList,total: e})
                                        // setQuery({...query,cinvcode: value})
                                    }}/>
                                </Form.Item></td>
                            <td><Form.Item name="iquantitys" className='table-input' >
                                    <InputNumber min='0.01' step="0.01" className='table-input'  onChange={(e) => {
                                        setDataList({...dataList,iquantitys: e})
                                        // setQuery({...query,cinvcode: value})
                                    }}/>
                                </Form.Item></td>
                            <td><Form.Item name="danjia" className='table-input' >
                                    <InputNumber min='0.01' step="0.01" className='table-input'  onChange={(e) => {
                                        setDataList({...dataList,danjia: e})
                                        // setQuery({...query,cinvcode: value})
                                    }}/>
                                </Form.Item></td>
                            <td><Form.Item name="jine" className='table-input' >
                                    <InputNumber min='0.01' step="0.01" className='table-input'  onChange={(e) => {
                                        setDataList({...dataList,jine: e})
                                        // setQuery({...query,cinvcode: value})
                                    }}/>
                                </Form.Item></td>
                            <td><Form.Item name="yuanjine" className='table-input' >
                                    <InputNumber min='0.01' step="0.01" className='table-input'  onChange={(e) => {
                                        setDataList({...dataList,yuanjine: e})
                                        // setQuery({...query,cinvcode: value})
                                    }}/>
                                </Form.Item></td>
                            <td><Form.Item name="heji" className='table-input' >
                                    <InputNumber min='0.01' step="0.01" className='table-input'  onChange={(e) => {
                                        setDataList({...dataList,heji: e})
                                        // setQuery({...query,cinvcode: value})
                                    }}/>
                                </Form.Item></td>
                        </tr>
                        <tr>
                            <td style={{height:'20px',lineHeight:'20px'}}></td>
                            <td style={{height:'20px',lineHeight:'20px'}}></td>
                            <td style={{height:'20px',lineHeight:'20px'}}></td>
                            <td style={{height:'20px',lineHeight:'20px'}}></td>
                            <td style={{height:'20px',lineHeight:'20px'}}></td>
                            <td style={{height:'20px',lineHeight:'20px'}}></td>
                            <td style={{height:'20px',lineHeight:'20px'}}></td>
                            <td style={{height:'20px',lineHeight:'20px'}}></td>
                            <td style={{height:'20px',lineHeight:'20px'}}></td>
                            <td style={{height:'20px',lineHeight:'20px'}}></td>
                        </tr>
                        <tr>
                            <td style={{height:'20px',lineHeight:'20px'}}></td>
                            <td style={{height:'20px',lineHeight:'20px'}}></td>
                            <td style={{height:'20px',lineHeight:'20px'}}></td>
                            <td style={{height:'20px',lineHeight:'20px'}}></td>
                            <td style={{height:'20px',lineHeight:'20px'}}></td>
                            <td style={{height:'20px',lineHeight:'20px'}}></td>
                            <td style={{height:'20px',lineHeight:'20px'}}></td>
                            <td style={{height:'20px',lineHeight:'20px'}}></td>
                            <td style={{height:'20px',lineHeight:'20px'}}></td>
                            <td style={{height:'20px',lineHeight:'20px'}}></td>
                        </tr>
                        <tr>
                            <td>检验及其他说明事项</td>
                            <td colSpan = '4'><Form.Item name="remark" className='table-input' >
                                <Input className='table-input'  onChange={(e) => {
                                        let value = common.replacezifu(e.target.value)
                                        setDataList({...dataList,remark: value})
                                        // setQuery({...query,cinvcode: value})
                                }}/></Form.Item></td>
                            <td colSpan='2'>变更首次单价在方框内打√:
                                <Form.Item name="isFinal" className='table-input' >
                                    <Checkbox className='table-input' onChange={(e) => {
                                        setDataList({...dataList,isFinal: e.target.value})
                                }}/>
                            </Form.Item></td>
                        </tr>
                    </tbody>
                </table>
                <table style={{width:'100%'}}>
                    <tr >
                        <td style={{height:'33px',lineHeight:'33px'}}>记账: 
                            <Form.Item name="jizhang" className='table-input' >
                                <Input className='table-input'  onChange={(e) => {
                                        let value = common.replacezifu(e.target.value)
                                        setDataList({...dataList,jizhang: value})
                                        // setQuery({...query,cinvcode: value})
                                }}/></Form.Item></td>
                        <td style={{height:'33px',lineHeight:'33px'}}>点收: 
                            <Form.Item name="dianshou" className='table-input' >
                                <Input className='table-input'  onChange={(e) => {
                                        let value = common.replacezifu(e.target.value)
                                        setDataList({...dataList,dianshou: value})
                                        // setQuery({...query,cinvcode: value})
                                }}/></Form.Item></td>
                        <td style={{height:'33px',lineHeight:'33px'}}>检验: 
                            <Form.Item name="jianyan" className='table-input' >
                                <Input className='table-input'  onChange={(e) => {
                                        let value = common.replacezifu(e.target.value)
                                        setDataList({...dataList,jianyan: value})
                                        // setQuery({...query,cinvcode: value})
                                }}/></Form.Item></td>
                        <td style={{height:'33px',lineHeight:'33px'}}>经办: 
                            <Form.Item name="jingban" className='table-input' >
                                <Input className='table-input'  onChange={(e) => {
                                        let value = common.replacezifu(e.target.value)
                                        setDataList({...dataList,jingban: value})
                                        // setQuery({...query,cinvcode: value})
                                }}/></Form.Item></td>
                    </tr>  
                    <tr>保存期限（ISO）1年;保存期限（IATF）3年</tr>     
                </table>
                </Form>
            </div>
           
            
        </div>    
        </div>
        
    );
}
export default PrintView;